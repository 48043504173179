import { Box } from 'entities/Box';

export interface PickerBox extends Box {
  isHidden: boolean;
}

export interface WarehouseMapConstructorSchema {
  selectedFloorId: Nullable<string>;
  activeTool: WarehouseMapTool;
  shapes: Shape[];
  pickedBoxParams: Nullable<PickedBoxParams>;
  pickerBoxes: PickerBox[];
  gridCellSize: number;
}

export interface PickedBoxParams {
  id: string;
  label: string;
  width: number;
  length: number;
  onDelete: () => void;
}

export interface UpdateShapeParamsPayload {
  x: number;
  y: number;
  width: number;
  height: number;
  scaleX: number;
  scaleY: number;
  rotation: number;
  points: number[];
}

export enum ShapeType {
  RECTANGLE = 'RECTANGLE',
  CIRCLE = 'CIRCLE',
  LINE = 'LINE',
  TEXT = 'TEXT',
  POLYGON = 'POLYGON',
  POLYGON_BOX = 'POLYGON_BOX',
}

export interface ShapePlacement {
  x: number;
  y: number;
}

export interface ShapeSize {
  width: number;
  height: number;
}

export interface ShapeScale {
  scaleX: number;
  scaleY: number;
}

export interface ShapeRadius {
  radiusX: number;
  radiusY: number;
}

export interface CommonShape extends ShapePlacement {
  id: string;
  floorId: string;
  type: ShapeType;
  cornerRadius?: number;
  ignoreStroke?: boolean;
  selected?: boolean;
  rotation?: number;
  onDelete?: () => void;
  locked?: boolean;
  sizeCodeId?: string;
}

export interface CommonShapeStyle {
  fill: string;
  stroke: string;
  strokeWidth: number;
}

export interface RectangleShape extends CommonShape, ShapeSize, ShapeScale, CommonShapeStyle {
  type: ShapeType.RECTANGLE;
  label: string;
}

export interface CircleShape extends CommonShape, ShapeRadius, ShapeScale, CommonShapeStyle {
  type: ShapeType.CIRCLE;
  label: string;
}

export interface LineShape extends CommonShape, ShapeScale, CommonShapeStyle {
  type: ShapeType.LINE;
  points: number[];
}

export interface TextShape extends CommonShape, ShapeScale {
  type: ShapeType.TEXT;
  label: string;
  fontSize?: number;
}

export interface PolygonShape extends CommonShape, ShapeScale, CommonShapeStyle {
  type: ShapeType.POLYGON;
  points: number[];
}

export interface PolygonBoxShape extends CommonShape, ShapeScale, CommonShapeStyle {
  type: ShapeType.POLYGON_BOX;
  points: number[];
  label: string;
}

export type Shape = RectangleShape | CircleShape | LineShape | TextShape | PolygonShape | PolygonBoxShape;

export enum WarehouseMapTool {
  POINTER = 'POINTER',
  GRAB = 'GRAB',
  RECTANGLE = 'RECTANGLE',
  CIRCLE = 'CIRCLE',
  PENCIL = 'PENCIL',
  LINE = 'LINE',
  TEXT = 'TEXT',
  POLYGON = 'POLYGON',
  POLYGON_BOX = 'POLYGON_BOX',
}

export type SelectionBox = ShapePlacement & ShapeSize;
