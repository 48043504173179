import { ShapePlacement } from '../../model/types';

interface ReturnType {
  width: number;
  height: number;
  x: number;
  y: number;
}

export const getNewSelectedAreaSize = (start: ShapePlacement, end: ShapePlacement): ReturnType => {
  const width = Math.abs(start.x - end.x);
  const height = Math.abs(start.y - end.y);
  const x = (start.x + end.x) / 2;
  const y = (start.y + end.y) / 2;

  return {
    width,
    height,
    x,
    y,
  };
};
