import { ShapePlacement, ShapeSize } from '../../model/types';

export const shapeSizing = {
  getRectSize: ({ width, height }: ShapeSize, { x, y }: ShapePlacement, gridCellSize: number) => {
    return {
      width: Math.round(width / gridCellSize) * gridCellSize,
      height: Math.round(height / gridCellSize) * gridCellSize,
      x: Math.round((x - width / 2) / gridCellSize) * gridCellSize,
      y: Math.round((y - height / 2) / gridCellSize) * gridCellSize,
    };
  },
  getEllipseSize: ({ width, height }: ShapeSize, { x, y }: ShapePlacement, gridCellSize: number) => {
    return {
      radiusX: Math.round(width / 2 / gridCellSize) * gridCellSize,
      radiusY: Math.round(height / 2 / gridCellSize) * gridCellSize,
      x: Math.round(x / gridCellSize) * gridCellSize,
      y: Math.round(y / gridCellSize) * gridCellSize,
    };
  },
};
