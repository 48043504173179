import { api } from 'app/config/apiConfig';
import { ChangeUserDebtDetailsParams } from '../model/types';
import { USERS_PATH } from '../utils/constants';

export const changeUserDebtDetailsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    changeUserDebtDetails: builder.mutation<void, { userId: string; params: ChangeUserDebtDetailsParams }>({
      query: ({ userId, params }) => ({
        url: `${USERS_PATH}/${userId}/debt-details`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['Users'],
    }),
  }),
});

export const { useChangeUserDebtDetailsMutation } = changeUserDebtDetailsApi;
