import dayjs, { Dayjs } from 'dayjs';
import { Deposit } from 'entities/Deposit/model/types';
import { roundNumber } from 'shared/utils/helpers/roundNumber';

export const calculateCurrentDepositAmount = (deposit: Deposit, date?: Nullable<Dayjs> | string | undefined): number => {
  const today = dayjs().startOf('day');
  const refDate = dayjs(date || today).startOf('day');

  const { charges, initialDepositAmount } = deposit;

  if (!charges.length) {
    return initialDepositAmount;
  }

  const chargedAmount = charges?.reduce((acc, charge) => {
    const createdAt = dayjs(charge.createdAt).startOf('day');

    if (createdAt.isBefore(refDate) || createdAt.isSame(refDate)) {
      return acc + charge.amount;
    }

    return acc;
  }, 0);

  const currentAmount = roundNumber(initialDepositAmount - chargedAmount);

  return currentAmount;
};
