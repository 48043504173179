import React, { FC, memo, useCallback } from 'react';
import { Trans } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { AppRoutes } from 'app/config/routerConfig/types';
import { useGetWarehouseByIdQuery } from 'entities/Warehouse';
import { SettingsCard } from './SettingsCard';
import { WarehouseCard } from './WarehouseCard';
import WarehouseSettingsBackground from 'shared/assets/images/WarehouseSettingsBackground.png';

export const WarehousePage: FC = memo(() => {
  const { t } = useAppTranslation('warehouses');
  const navigate = useNavigate();

  const { warehouseId } = useParams();

  const { data } = useGetWarehouseByIdQuery({ warehouseId, params: { city: true } }, { skip: !warehouseId });

  const goToWarehouseConstructor = useCallback(() => {
    navigate(`${AppRoutes.WAREHOUSES}/${warehouseId}/constructor`);
  }, [navigate, warehouseId]);

  return (
    <div className="pt-7">
      {data ? (
        <>
          <Trans
            t={t}
            i18nKey="<0>Warehouse</0> <1>{{name}}</1>"
            components={[
              <span key="0" className="text-2xl font-semibold" />,
              <span key="1" className="text-2xl font-semibold text-accent" />,
            ]}
            values={{ name: data.name }}
          />
          <div className="flex justify-between bg-secondaryLight p-7 mt-7 rounded-xl space-x-8">
            <WarehouseCard warehouse={data} />
            <div className="flex-1 bg-white rounded-xl p-7">Customer statistic</div>
          </div>
          <div className="flex flex-wrap gap-7 mt-7">
            <SettingsCard
              imageUrl={WarehouseSettingsBackground}
              label="Warehouse management"
              description="A combination of essential categories such as floors, boxes, size codes, and maps to optimize the warehouse. This allows for effective space management"
              onClick={goToWarehouseConstructor}
            />
            <SettingsCard
              imageUrl={WarehouseSettingsBackground}
              label="Financial transactions"
              description="Essential elements of financial management include payment processes, the calculation of VAT, discounts, and the management of insurance."
            />
            <SettingsCard imageUrl={WarehouseSettingsBackground} label="Something else" description="Some text" />
            <SettingsCard
              imageUrl={WarehouseSettingsBackground}
              label="Settings"
              description="Includes all parameters for effective business management. Here you can find information about the warehouse, working hours, bank details, SMTP settings and Wi-Fi connection parameters."
            />
          </div>
        </>
      ) : (
        <div>{t('Warehouse not exists')}</div>
      )}
    </div>
  );
});
