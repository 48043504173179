import React, { FC, memo, useCallback } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { TableFilter, useTableFilterContext } from 'features/TableFilter';
import { BoxesOccupancyFilterKeys } from '../../model/types';
import { CLIENT_DATE_FORMAT } from 'shared/utils/constants';
import { DatePicker } from 'shared/ui/DatePicker';
import { ApplicabilitySelect } from './ApplicabilitySelect';
import { WarehouseSelect } from './WarehouseSelect';
import { Checkbox } from 'shared/ui/Checkbox';

export const BoxesOccupancyFilter: FC = memo(() => {
  const { t } = useAppTranslation(['contracts', 'common']);

  const { filters, changeFilters, clearCurrentFilters } = useTableFilterContext<BoxesOccupancyFilterKeys>();

  const selectWarehouse = useCallback(
    (value: string[]) => {
      changeFilters({
        key: BoxesOccupancyFilterKeys.selectedWarehouses,
        label: `${t('Selected')}: ${value.length}`,
        value,
        title: t('Warehouse'),
      });
    },
    [changeFilters, t],
  );

  const changeStatusRefDate = useCallback(
    (value: Nullable<Dayjs>) => {
      const clientFormatStatusRefDate = dayjs(value).startOf('day').format(CLIENT_DATE_FORMAT);

      changeFilters({
        key: BoxesOccupancyFilterKeys.statusRefDate,
        label: clientFormatStatusRefDate,
        value,
        title: t('statusRefDate'),
      });
    },
    [changeFilters, t],
  );

  const changeContractApplicability = useCallback(
    (value: string, label: string) => {
      changeFilters({ key: BoxesOccupancyFilterKeys.isApplicable, label, value, title: t('Applicability') });
    },
    [changeFilters, t],
  );

  const changeIgnoreUnstartedContracts = useCallback(
    (value: boolean) => {
      value
        ? changeFilters({
            key: BoxesOccupancyFilterKeys.ignoreUnstartedContracts,
            label: t('True'),
            value,
            title: t('Ignore unstarted'),
          })
        : clearCurrentFilters('ignoreUnstartedContracts');
    },
    [changeFilters, clearCurrentFilters, t],
  );

  const changeIgnoreClosedContracts = useCallback(
    (value: boolean) => {
      value
        ? changeFilters({
            key: BoxesOccupancyFilterKeys.ignoreClosedContracts,
            label: t('True'),
            value,
            title: t('Ignore closed'),
          })
        : clearCurrentFilters('ignoreClosedContracts');
    },
    [changeFilters, clearCurrentFilters, t],
  );

  return (
    <TableFilter>
      <div className="space-y-2">
        <WarehouseSelect values={filters.selectedWarehouses?.value} onChange={selectWarehouse} onClear={clearCurrentFilters} />

        <div className="flex space-x-3 items-center">
          <div className="flex-1">
            <div className="mb-2">{t('Status date')}</div>
            <DatePicker
              className="h-[52px] w-full"
              value={filters.statusRefDate?.value && dayjs(filters.statusRefDate?.value)}
              format={CLIENT_DATE_FORMAT}
              placeholder="statusRefDate"
              onChange={changeStatusRefDate}
            />
          </div>
        </div>

        <div className="flex space-x-3 items-center">
          <ApplicabilitySelect value={filters.isApplicable?.value} onChange={changeContractApplicability} onClear={clearCurrentFilters} />
        </div>

        <div>
          <div className="mt-6">
            <Checkbox
              checked={filters.ignoreUnstartedContracts?.value || false}
              onChange={changeIgnoreUnstartedContracts}
              name="ignoreUnstartedContracts"
            >
              {t('Ignore unstarted contracts')}
            </Checkbox>
          </div>
          <div className="mt-2">
            <Checkbox
              checked={filters.ignoreClosedContracts?.value || false}
              onChange={changeIgnoreClosedContracts}
              name="ignoreClosedContracts"
            >
              {t('Ignore closed contracts')}
            </Checkbox>
          </div>
        </div>
      </div>
    </TableFilter>
  );
});
