import { DefaultOptionType } from 'antd/es/select';
import React, { FC, memo, useCallback, useMemo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Select } from 'shared/ui/Select';
import { ContractsListFilterKeys, PaymentStatusFilter } from '../../model/types';

interface PaymentStatusSelectProps {
  value: string;
  onChange: (value: string, label: string) => void;
  onClear: (filterKey: string) => void;
}

export const PaymentStatusSelect: FC<PaymentStatusSelectProps> = memo((props) => {
  const { value, onChange, onClear } = props;

  const { t } = useAppTranslation('contracts');

  const paymentStatusOptions = useMemo<Array<{ label: string; value: PaymentStatusFilter }>>(() => {
    return [
      { label: t('Paid'), value: PaymentStatusFilter.PAID },
      { label: t('Unpaid'), value: PaymentStatusFilter.UNPAID },
    ];
  }, [t]);

  const handleChange = useCallback(
    (value: string, option?: DefaultOptionType | DefaultOptionType[]): void => {
      if (!Array.isArray(option) && typeof option?.label === 'string') {
        onChange(value, option.label);
      }
    },
    [onChange],
  );

  const clearFilter = useCallback(() => {
    onClear(ContractsListFilterKeys.isPaid);
  }, [onClear]);

  return (
    <Select
      containerClassName="flex-1"
      className="w-full"
      label={t('Payment status')}
      placeholder={t('All')}
      bordered
      allowClear
      options={paymentStatusOptions}
      value={value}
      onChange={handleChange}
      onClear={clearFilter}
    />
  );
});
