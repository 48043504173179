import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { MutableRefObject, useCallback, useRef } from 'react';
import { useAppSelector } from 'app/config/storeConfig/hooks';
import { getGridCellSize } from '../../model/selectors/getGridCellSize';

interface HookApi {
  gridRef: MutableRefObject<Nullable<Konva.Layer>>;
  onDragMove: (e: KonvaEventObject<MouseEvent>) => void;
}

export const useCanvasGrid = (
  { width, height }: { width: number; height: number },
  canvasScale: number,
  stageRef: MutableRefObject<Nullable<Konva.Stage>>,
): HookApi => {
  const gridRef = useRef<Konva.Layer>(null);

  const gridCellSize = useAppSelector(getGridCellSize);

  const onDragMove = useCallback(
    (e: KonvaEventObject<MouseEvent>): void => {
      e.target.position({
        x: Math.round(e.target.x() / gridCellSize) * gridCellSize,
        y: Math.round(e.target.y() / gridCellSize) * gridCellSize,
      });

      stageRef.current?.batchDraw();
    },
    [gridCellSize, stageRef],
  );

  return {
    gridRef,
    onDragMove,
  };
};
