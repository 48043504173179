import { Collapse, Divider } from 'antd';
import classNames from 'classnames';
import React, { FC, memo, useCallback, useMemo } from 'react';
import { useGetCurrencySymbol } from 'app/appState';
import { User, getUserName, useRole } from 'entities/User';
import { ReactComponent as CollapseArrow } from 'shared/assets/icons/CollapseArrow.svg';
import { BulletsTable } from 'shared/ui/BulletsTable';
import { Button } from 'shared/ui/Button';
import { getClientDateFormat } from 'shared/utils/helpers/getDateFormat';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { roundNumber } from 'shared/utils/helpers/roundNumber';
import { Payment, PaymentStatus, generatePaymentPdfLink } from 'entities/Payment';
import { MarkAsRefundedPayment } from 'features/MarkAsRefundedPayment';
import { CreditAndRefundPayment } from 'features/CreditAndRefundPayment';
import { CreditPayment } from 'features/CreditPayment';
import s from './PaymentCard.module.scss';
import { Deposit } from 'entities/Deposit';

interface PaymentCardProps {
  user: User;
  payment: Payment;
  deposit?: Deposit;
  isClosedContract: boolean;
  isDepositPayment?: boolean;
}

export const PaymentCard: FC<PaymentCardProps> = memo((props) => {
  const { payment, user, isDepositPayment, deposit, isClosedContract } = props;

  const { documentNumber: invoiceNumber, invoiceEmail } = payment.invoice;

  const showIsDepositPayment = useMemo(() => Boolean(isDepositPayment && deposit && !deposit.isRefunded), [deposit, isDepositPayment]);

  const { t } = useAppTranslation(['contracts', 'common']);

  const { isManagement } = useRole();

  const currencySymbol = useGetCurrencySymbol();

  const isPaymentCompleted = payment.paymentStatus === PaymentStatus.COMPLETED;

  const creditedAmount = payment.creditNote?.creditedAmount;
  const refundedAmount = payment.refund?.amount;

  const activePaymentAmount = useMemo(() => {
    const amount = payment.creditNote ? payment.amount - payment.creditNote.creditedAmount : payment.amount;

    return roundNumber(amount);
  }, [payment.amount, payment.creditNote]);

  const PaymentContent = useCallback((): JSX.Element => {
    return (
      <div>
        <div>
          {t('From')}: {getUserName(user)}
        </div>
        <div>
          {t('Mail')}: {invoiceEmail}
        </div>
        <Divider className="border-primary" />

        <BulletsTable
          theme="clear"
          rows={[
            {
              label: t('Payment method'),
              value: t(payment.paymentMethod, { ns: 'common' }),
            },
            {
              label: t('Payment'),
              value: `${roundNumber(payment.amount)} ${currencySymbol}`,
              emptyValueSymbol: `0 ${currencySymbol}`,
            },
            {
              label: t('Description'),
              value: isManagement ? payment.description : null,
              emptyValueSymbol: '-',
            },
          ]}
        />

        {payment.creditNote && (
          <>
            <Divider className="border-primary" />
            <BulletsTable
              theme="clear"
              rows={[
                {
                  label: t('Cancelled amount'),
                  value: `-${roundNumber(payment.creditNote.creditedAmount)} ${currencySymbol}`,
                  emptyValueSymbol: `0 ${currencySymbol}`,
                },
                {
                  label: t('Description'),
                  value: isManagement ? payment.creditNote?.description : null,
                  emptyValueSymbol: '-',
                },
              ]}
            />
          </>
        )}

        {payment.refund && (
          <>
            <Divider className="border-primary" />
            <BulletsTable
              theme="clear"
              rows={[
                {
                  label: t('Refund method'),
                  value: t(payment.refund?.paymentMethod || '-', { ns: 'common' }),
                },
                {
                  label: t('Refund amount'),
                  value: `${roundNumber(payment.refund.amount)} ${currencySymbol}`,
                  emptyValueSymbol: `0 ${currencySymbol}`,
                },
                {
                  label: t('Description'),
                  value: isManagement ? payment.refund?.description : null,
                  emptyValueSymbol: '-',
                },
              ]}
            />
          </>
        )}
        <>
          <Divider className="border-primary" />
          <BulletsTable
            theme="clear"
            rows={[
              {
                label: t('Total'),
                value: `${activePaymentAmount} ${currencySymbol}`,
                emptyValueSymbol: `0 ${currencySymbol}`,
                highlighted: true,
              },
            ]}
          />
        </>

        {!payment.creditNote && (
          <div className="my-3">
            <a
              href={generatePaymentPdfLink(payment.paymentId)}
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm font-medium text-accent"
            >
              {t('Download PDF')}
            </a>
          </div>
        )}

        {isManagement && !payment.refund && (
          <div className="flex flex-col gap-3 mt-6">
            <MarkAsRefundedPayment
              payment={payment}
              showIsDepositPayment={showIsDepositPayment}
              isClosedContract={isClosedContract}
              action={<Button theme="secondary">{t('Mark as Refunded')}</Button>}
            />
            {!payment.creditNote && (
              <div className="flex flex-col gap-3">
                <CreditPayment
                  paymentId={payment.paymentId}
                  amount={payment.amount}
                  action={<Button theme="primary">{t('Cancel Payment')}</Button>}
                />
              </div>
            )}

            <CreditAndRefundPayment
              payment={payment}
              isClosedContract={isClosedContract}
              showIsDepositPayment={showIsDepositPayment}
              action={<Button theme="danger">{t('Refund')}</Button>}
            />
          </div>
        )}
      </div>
    );
  }, [activePaymentAmount, currencySymbol, invoiceEmail, isClosedContract, isManagement, payment, showIsDepositPayment, t, user]);

  return (
    <Collapse
      className={classNames(s.collapse, {
        [s.credited]: creditedAmount && creditedAmount === payment.amount,
        [s.refunded]: refundedAmount && refundedAmount === payment.amount,
        [s.paymentAndCreditNote]: creditedAmount && !refundedAmount && creditedAmount !== payment.amount,
        [s.paymentAndRefund]: refundedAmount && refundedAmount !== payment.amount,
        [s.completed]: isPaymentCompleted && !payment.refund && !payment.creditNote,
      })}
      items={[
        {
          key: payment.paymentId,
          label: (
            <>
              <div className="flex justify-between font-semibold text-lg">
                <div>
                  <span className="font-semibold text-lg">{payment.documentNumber}</span>
                  {payment.paymentStatus !== PaymentStatus.COMPLETED && <span className="text-m">({t(payment.paymentStatus)})</span>}
                </div>
                <div className="text-accent">{invoiceNumber}</div>
              </div>
              <div>{getClientDateFormat(payment.createdAt)}</div>
            </>
          ),
          children: <PaymentContent />,
        },
      ]}
      expandIcon={(panelProps) => (
        <CollapseArrow
          className={classNames('ease-linear duration-200', {
            '-rotate-90 fill-primaryLight': panelProps.isActive,
            'rotate-90 fill-secondaryAccent': !panelProps.isActive,
          })}
        />
      )}
      expandIconPosition="end"
    />
  );
});
