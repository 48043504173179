import React, { FC, memo, useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { Floor, useCreateFloorMutation } from 'entities/Floor';
import { RadioButtonGroup } from 'shared/ui/RadioButtonGroup';
import { getLocalizedString } from 'shared/utils/helpers/JSONLocalization';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Button } from 'shared/ui/Button';
import { Modal } from 'shared/ui/Modal';
import { WarningPing } from 'shared/ui/WarningPing';
import { getSelectedFloorId } from '../model/selectors/getSelectedFloorId';
import { warehouseMapConstructorActions } from '../model/slice/warehouseMapConstructorSlice';

interface FloorSwitchProps {
  floors: Floor[] | undefined;
  isShapesChanged: boolean;
  warehouseId: string | undefined;
}

export const FloorSwitch: FC<FloorSwitchProps> = memo((props) => {
  const { floors, isShapesChanged, warehouseId } = props;

  const { t } = useAppTranslation('warehouse-management');

  const dispatch = useAppDispatch();

  const [isModalOpened, setModalOpened] = useState(false);
  const [floorIdToClose, setFloorIdToClose] = useState<Nullable<string>>(null);

  const [createFloorService] = useCreateFloorMutation();

  const selectedFloorId = useAppSelector(getSelectedFloorId);

  const openModal = useCallback(() => {
    setModalOpened(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalOpened(false);
    setFloorIdToClose(null);
  }, []);

  const changeFloor = useCallback(
    (floorId: string) => {
      if (isShapesChanged) {
        setFloorIdToClose(floorId);
        openModal();
      } else {
        dispatch(warehouseMapConstructorActions.changeFloor(floorId));
      }
    },
    [dispatch, isShapesChanged, openModal],
  );

  const proceedToChangeFloor = useCallback(() => {
    if (floorIdToClose) {
      dispatch(warehouseMapConstructorActions.changeFloor(floorIdToClose));
      closeModal();
    }
  }, [closeModal, dispatch, floorIdToClose]);

  const createFloor = useCallback(
    async (label: string) => {
      if (warehouseId) {
        await createFloorService({ warehouseId, args: { name: label } });
      }
    },
    [createFloorService, warehouseId],
  );

  const options =
    floors?.map(({ floorId, name }) => {
      return {
        value: floorId,
        label: getLocalizedString(name),
      };
    }) || [];

  return (
    <div className="absolute z-10 top-6 left-4 bg-white p-1.5 rounded-lg">
      <RadioButtonGroup
        optionType="button"
        options={options}
        value={selectedFloorId || undefined}
        onChange={changeFloor}
        onAddOption={createFloor}
      />
      <Modal isOpen={isModalOpened} onClose={closeModal}>
        <WarningPing type="warning" />
        <div className="mt-7 text-xl">{t('Are you sure you do not want to save the floor configuration?')}</div>
        <div className="flex justify-end space-x-2 mt-7">
          <Button onClick={proceedToChangeFloor}>{t('Ok')}</Button>
          <Button theme="secondary" onClick={closeModal}>
            {t('Cancel')}
          </Button>
        </div>
      </Modal>
    </div>
  );
});
