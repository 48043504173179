import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { SizeCode } from 'entities/SizeCode';
import Konva from 'konva';
import { Layer } from 'konva/lib/Layer';
import { KonvaEventObject } from 'konva/lib/Node';
import { MutableRefObject, useCallback, useRef, useState } from 'react';
import { getGridCellSize } from '../../model/selectors/getGridCellSize';
import { warehouseMapConstructorActions } from '../../model/slice/warehouseMapConstructorSlice';
import { Shape, ShapeType, WarehouseMapTool } from '../../model/types';
import { getNearestSizeCode } from '../helpers/getNearestSizeCode';
import { getNewSelectedAreaSize } from '../helpers/getNewSelectedAreaSize';
import { getRelativeCursorPosition } from '../helpers/getRelativeCursorPosition';
import { shapeSizing } from '../helpers/shapeSizing';

interface HookArgs {
  activeTool: WarehouseMapTool;
  transformerRef: MutableRefObject<Nullable<Konva.Transformer>>;
  floorId: string;
  availableSizeCodes: SizeCode[] | undefined;
}

interface HookApi {
  previewLayerRef: MutableRefObject<Nullable<Layer>>;
  selectedArea: SelectedArea;
  onMouseDown: (e: KonvaEventObject<MouseEvent>) => void;
  onMouseMove: (e: KonvaEventObject<MouseEvent>) => void;
  onMouseUp: (e: KonvaEventObject<MouseEvent>) => void;
  onMouseLeave: () => void;
}

interface SelectedArea {
  x: number;
  y: number;
  width: number;
  height: number;
  startX: number;
  startY: number;
  visible: boolean;
}

const initialSelectedArea: SelectedArea = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  startX: 0,
  startY: 0,
  visible: false,
};

export const useMouseArea = ({ activeTool, transformerRef, floorId, availableSizeCodes }: HookArgs): HookApi => {
  const [selectedArea, setSelectedArea] = useState<SelectedArea>(initialSelectedArea);

  const [initialPolygonPoint, setInitialPolygonPoint] = useState<number[]>([]);
  const [points, setPoints] = useState<number[]>([]);

  const dispatch = useAppDispatch();

  const gridCellSize = useAppSelector(getGridCellSize);

  const shapePreview = useRef<Nullable<Shape>>(null);
  const previewLayerRef = useRef<Nullable<Konva.Layer>>(null);
  const isMouseDown = useRef<boolean>(false);
  const isShapeDragging = useRef<boolean>(false);

  const onMouseDown = useCallback(
    (e: KonvaEventObject<MouseEvent>) => {
      if (e.evt.button === 1) return; // prevent drawing with mouse wheel click

      isMouseDown.current = true;

      if (activeTool === WarehouseMapTool.GRAB) return;

      const stage = e.target.getStage();
      const cursorPosition = getRelativeCursorPosition(stage);

      if (!cursorPosition) return;

      const isPolygonDrawing = Boolean(initialPolygonPoint.length);

      if (e.target !== stage && !isPolygonDrawing && activeTool === WarehouseMapTool.POINTER && !(e.target instanceof Konva.Image)) {
        const shapeId = e.target.attrs.id;

        const isMultipleElementsInSelection = transformerRef.current ? transformerRef.current.nodes().length > 1 : false;
        isShapeDragging.current = true;

        if (shapeId && !isMultipleElementsInSelection) {
          dispatch(warehouseMapConstructorActions.selectShape(shapeId));
        }

        return;
      } else {
        dispatch(warehouseMapConstructorActions.unselectShapes());
      }

      if (activeTool === WarehouseMapTool.TEXT) {
        const shape: Shape = {
          id: Date.now().toString(),
          floorId,
          type: ShapeType.TEXT,
          label: 'Text',
          fontSize: 16,
          scaleX: 1,
          scaleY: 1,
          onDelete: () => {
            dispatch(warehouseMapConstructorActions.deleteShape());
          },
          ...cursorPosition,
        };
        dispatch(warehouseMapConstructorActions.appendShape(shape));

        return;
      }

      const selectedArea = {
        width: 0,
        height: 0,
        startX: cursorPosition.x,
        startY: cursorPosition.y,
        visible: activeTool === WarehouseMapTool.POINTER,
        ...cursorPosition,
      };

      setSelectedArea(selectedArea);

      let shape: Nullable<Shape> = null;

      if (activeTool === WarehouseMapTool.RECTANGLE) {
        shape = {
          id: Date.now().toString(),
          floorId,
          type: ShapeType.RECTANGLE,
          fill: '#F3F6F9',
          stroke: '#788593',
          strokeWidth: 1,
          cornerRadius: 5,
          width: 0,
          height: 0,
          scaleX: 1,
          scaleY: 1,
          label: 'Label',
          onDelete: () => {
            dispatch(warehouseMapConstructorActions.deleteShape());
          },
          ...cursorPosition,
        };
      }

      if (activeTool === WarehouseMapTool.CIRCLE) {
        shape = {
          id: Date.now().toString(),
          floorId,
          type: ShapeType.CIRCLE,
          fill: '#F3F6F9',
          stroke: '#788593',
          strokeWidth: 1,
          radiusX: 0,
          radiusY: 0,
          scaleX: 1,
          scaleY: 1,
          label: 'Label',
          onDelete: () => {
            dispatch(warehouseMapConstructorActions.deleteShape());
          },
          ...cursorPosition,
        };
      }

      if (activeTool === WarehouseMapTool.PENCIL) {
        shape = {
          id: Date.now().toString(),
          floorId,
          type: ShapeType.LINE,
          fill: 'transparent',
          stroke: '#788593',
          strokeWidth: 3,
          scaleX: 1,
          scaleY: 1,
          points: [cursorPosition.x, cursorPosition.y],
          onDelete: () => {
            dispatch(warehouseMapConstructorActions.deleteShape());
          },
          ...cursorPosition,
        };
      }

      if (activeTool === WarehouseMapTool.LINE) {
        shape = {
          id: Date.now().toString(),
          floorId,
          type: ShapeType.LINE,
          fill: 'transparent',
          stroke: '#788593',
          strokeWidth: 4,
          points: [cursorPosition.x, cursorPosition.y],
          scaleX: 1,
          scaleY: 1,
          onDelete: () => {
            dispatch(warehouseMapConstructorActions.deleteShape());
          },
          ...cursorPosition,
        };
      }

      if (activeTool === WarehouseMapTool.POLYGON) {
        setPoints((prevState) => [...prevState, cursorPosition.x, cursorPosition.y]);

        if (!shapePreview.current) {
          setInitialPolygonPoint([cursorPosition.x, cursorPosition.y]);
        }

        shape = {
          id: Date.now().toString(),
          floorId,
          type: ShapeType.POLYGON,
          fill: 'transparent',
          stroke: '#788593',
          strokeWidth: 4,
          points: [cursorPosition.x, cursorPosition.y],
          scaleX: 1,
          scaleY: 1,
          onDelete: () => {
            dispatch(warehouseMapConstructorActions.deleteShape());
          },
          ...cursorPosition,
        };
      }

      if (activeTool === WarehouseMapTool.POLYGON_BOX) {
        setPoints((prevState) => [...prevState, cursorPosition.x, cursorPosition.y]);

        if (!shapePreview.current) {
          setInitialPolygonPoint([cursorPosition.x, cursorPosition.y]);
        }

        shape = {
          id: Date.now().toString(),
          floorId,
          type: ShapeType.POLYGON_BOX,
          fill: '#F3F6F9',
          stroke: '#788593',
          strokeWidth: 1,
          points: [cursorPosition.x, cursorPosition.y],
          scaleX: 1,
          scaleY: 1,
          label: 'Label',
          onDelete: () => {
            dispatch(warehouseMapConstructorActions.deleteShape());
          },
          ...cursorPosition,
        };
      }

      if (!shape) return;

      shapePreview.current = shape;

      switch (activeTool) {
        case WarehouseMapTool.RECTANGLE:
          previewLayerRef.current?.add(new Konva.Rect(shape));
          break;
        case WarehouseMapTool.CIRCLE:
          previewLayerRef.current?.add(new Konva.Ellipse({ ...shape, radiusX: 0, radiusY: 0, id: shape.id }));
          break;
        case WarehouseMapTool.PENCIL:
          previewLayerRef.current?.add(new Konva.Line({ ...shape, x: 0, y: 0, points: [shape.x, shape.y] }));
          break;
        case WarehouseMapTool.LINE:
          previewLayerRef.current?.add(new Konva.Line({ ...shape, x: 0, y: 0, points: [shape.x, shape.y] }));
          break;
        case WarehouseMapTool.POLYGON:
          previewLayerRef.current?.add(new Konva.Line({ ...shape, x: 0, y: 0 }));
          break;
        case WarehouseMapTool.POLYGON_BOX:
          previewLayerRef.current?.add(new Konva.Line({ ...shape, x: 0, y: 0 }));
          break;
        default:
          break;
      }
    },
    [activeTool, dispatch, floorId, initialPolygonPoint.length, transformerRef],
  );

  const onMouseMove = useCallback(
    (e: KonvaEventObject<MouseEvent>) => {
      if (!isMouseDown.current || isShapeDragging.current) return;

      const stage = e.target.getStage();
      const cursorPosition = getRelativeCursorPosition(stage);

      if (!cursorPosition) return;

      const { width, height, x, y } = getNewSelectedAreaSize(cursorPosition, { x: selectedArea.startX, y: selectedArea.startY });

      const rectSelection = shapeSizing.getRectSize({ width, height }, { x, y }, gridCellSize);

      if (activeTool === WarehouseMapTool.POINTER) {
        setSelectedArea({ ...selectedArea, ...rectSelection });
        dispatch(warehouseMapConstructorActions.selectShapesInArea(rectSelection));

        return;
      }

      const shape = shapePreview.current;
      const shapeToEdit = previewLayerRef.current?.findOne(`#${shape?.id}`);

      if (!shapeToEdit || !shape) return;

      if (activeTool === WarehouseMapTool.RECTANGLE) {
        shapeToEdit.setAttrs(rectSelection);
        shapePreview.current = { ...shape, ...rectSelection };
      }

      if (activeTool === WarehouseMapTool.CIRCLE) {
        const circleSelection = shapeSizing.getEllipseSize({ width, height }, { x, y }, gridCellSize);

        shapeToEdit.setAttrs(circleSelection);
        shapePreview.current = { ...shape, ...circleSelection };
      }

      if (activeTool === WarehouseMapTool.PENCIL && shape.type === ShapeType.LINE) {
        const points = shape.points.concat([cursorPosition.x, cursorPosition.y]);

        shape.points = points;
        shapeToEdit.setAttrs({ points });
      }

      if (activeTool === WarehouseMapTool.LINE && shape.type === ShapeType.LINE) {
        const points = [shape.points[0], shape.points[1], cursorPosition.x, cursorPosition.y];

        shape.points = points;
        shapeToEdit.setAttrs({ points });
      }

      if (activeTool === WarehouseMapTool.POLYGON && shape.type === ShapeType.POLYGON) {
        const points = [shape.points[0], shape.points[1], cursorPosition.x, cursorPosition.y];

        shape.points = points;
        shapeToEdit.setAttrs({ points });
      }

      if (activeTool === WarehouseMapTool.POLYGON_BOX && shape.type === ShapeType.POLYGON_BOX) {
        const points = [shape.points[0], shape.points[1], cursorPosition.x, cursorPosition.y];

        shape.points = points;
        shapeToEdit.setAttrs({ points });
      }

      previewLayerRef.current?.batchDraw();
    },
    [activeTool, dispatch, gridCellSize, selectedArea],
  );

  const onMouseUp = useCallback(
    (e: KonvaEventObject<MouseEvent>) => {
      if (activeTool === WarehouseMapTool.POLYGON || activeTool === WarehouseMapTool.POLYGON_BOX) {
        const stage = e.target.getStage();
        const cursorPosition = getRelativeCursorPosition(stage);

        if (!cursorPosition) return;

        if (Math.abs(initialPolygonPoint[0] - cursorPosition.x) <= 5 && Math.abs(initialPolygonPoint[1] - cursorPosition.y) <= 5) {
          isMouseDown.current = false;
          isShapeDragging.current = false;

          const shape = shapePreview.current;

          if (!shape) return;

          const findPolygonDimensions = (points: number[]): { maxX: number; maxY: number } => {
            let maxX = -Infinity;
            let maxY = -Infinity;

            for (let i = 0; i < points.length; i += 2) {
              const x = points[i];
              const y = points[i + 1];

              if (x > maxX) {
                maxX = x;
              }
              if (y > maxY) {
                maxY = y;
              }
            }

            return { maxX, maxY };
          };

          const { maxX, maxY } = findPolygonDimensions('points' in shape ? shape.points : []);

          const nearestSizeCode = getNearestSizeCode({
            shapeWidth: maxX,
            shapeLength: maxY,
            availableSizeCodes,
            gridCellSize,
          });

          if (nearestSizeCode) {
            previewLayerRef.current?.find('Line').forEach((line) => line.destroy());

            previewLayerRef.current?.batchDraw();
            'points' in shape &&
              dispatch(warehouseMapConstructorActions.appendShape({ ...shape, points, sizeCodeId: nearestSizeCode.sizeCodeId }));
            shapePreview.current = null;
            setInitialPolygonPoint([]);
            setPoints([]);
          }
        }

        return;
      }

      isMouseDown.current = false;
      isShapeDragging.current = false;

      if (activeTool === WarehouseMapTool.RECTANGLE) {
        const shape = shapePreview.current;

        if (!shape) return;

        if ('width' in shape && availableSizeCodes) {
          const nearestSizeCode = getNearestSizeCode({
            shapeWidth: shape.width,
            shapeLength: shape.height,
            availableSizeCodes,
            gridCellSize,
          });

          if (nearestSizeCode) {
            const shapeToRemove = previewLayerRef.current?.findOne(`#${shape.id}`);

            const preventOnePixelShapeDrawing = (): boolean => {
              if (('width' && 'height') in shape) {
                return shape.width === 0 || shape.height === 0;
              }

              return false;
            };

            if (!preventOnePixelShapeDrawing()) {
              shapeToRemove?.destroy();
              previewLayerRef.current?.batchDraw();
              dispatch(warehouseMapConstructorActions.appendShape({ ...shape, sizeCodeId: nearestSizeCode.sizeCodeId }));
              shapePreview.current = null;
            } else {
              shapeToRemove?.destroy();
              shapePreview.current = null;
            }

            return;
          }
        }
      }

      if (activeTool !== WarehouseMapTool.POINTER && activeTool !== WarehouseMapTool.GRAB) {
        const shape = shapePreview.current;

        if (!shape) return;

        const shapeToRemove = previewLayerRef.current?.findOne(`#${shape.id}`);

        const preventOnePixelShapeDrawing = (): boolean => {
          if ('points' in shape) {
            return shape.points.length === 2;
          }

          if (('width' && 'height') in shape) {
            return shape.width === 0 || shape.height === 0;
          }

          if (('radiusX' && 'radiusY') in shape) {
            return shape.radiusX === 0 || shape.radiusY === 0;
          }

          return false;
        };

        if (!preventOnePixelShapeDrawing()) {
          shapeToRemove?.destroy();
          previewLayerRef.current?.batchDraw();
          dispatch(warehouseMapConstructorActions.appendShape(shape));
          shapePreview.current = null;
        } else {
          shapeToRemove?.destroy();
          shapePreview.current = null;
        }
      }

      setSelectedArea(initialSelectedArea);
    },
    [activeTool, availableSizeCodes, dispatch, gridCellSize, initialPolygonPoint, points],
  );

  const onMouseLeave = useCallback(() => {
    const shape = shapePreview.current;

    if (!shape) return;

    const shapeToRemove = previewLayerRef.current?.findOne(`#${shape.id}`);
    shapeToRemove?.destroy();
    shapePreview.current = null;
    setSelectedArea(initialSelectedArea);
    dispatch(warehouseMapConstructorActions.unselectShapes());
    isShapeDragging.current = false;
    isMouseDown.current = false;
  }, [dispatch]);

  return {
    selectedArea,
    previewLayerRef,
    onMouseDown,
    onMouseMove,
    onMouseUp,
    onMouseLeave,
  };
};
