import React, { FC, memo } from 'react';
import { Image as KonvaImage } from 'react-konva';

interface ConstructorBackgroundProps {
  image: CanvasImageSource | undefined;
}

export const ConstructorBackground: FC<ConstructorBackgroundProps> = memo((props) => {
  const { image } = props;

  return <KonvaImage image={image} opacity={0.3} />;
});
