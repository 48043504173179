import React, { FC, memo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { NoResults } from 'shared/ui/NoResults';
import NoResultsImage from 'shared/assets/images/NoResultsImage.svg';
import { useRole } from 'entities/User';
import { useAppSelector } from 'app/config/storeConfig/hooks';
import { getGlobalSettings } from 'app/appState';
import { Button } from 'shared/ui/Button';
import { generateKnowledgeBaseUrl } from 'shared/utils/helpers/generateKnowledgeBaseUrl';

export const NoResultPage: FC = memo(() => {
  const { t } = useAppTranslation('common');

  const globalSettings = useAppSelector(getGlobalSettings);

  const { isPartner } = useRole();

  const redirectToKnowledgeBase = (): void => {
    if (globalSettings?.partnerKnowledgeBaseUrl) {
      window.location.href = generateKnowledgeBaseUrl(globalSettings.partnerKnowledgeBaseUrl);
    }
  };

  return (
    <NoResults
      image={NoResultsImage}
      title={t('Detailed information in this section will become available once at least one self-storage is assigned to your account.')}
      description={
        isPartner
          ? t(
              'In the meantime, you can explore the Knowledge Base, where you`ll find valuable insights about partnering with {{companyName}}.',
              { companyName: globalSettings?.companyName.toUpperCase() },
            )
          : null
      }
      actions={
        isPartner ? (
          <Button containerClassName="w-full desktop:w-auto" size="medium" onClick={redirectToKnowledgeBase}>
            {t('Knowledge base')}
          </Button>
        ) : null
      }
    />
  );
});
