import { Radio, RadioChangeEvent } from 'antd';
import { RadioGroupOptionType } from 'antd/lib/radio';
import classNames from 'classnames';
import { ChangeEvent, FC, KeyboardEvent, memo, useState } from 'react';
import s from './index.module.scss';

interface RadioButtonGroupProps {
  options: Array<{ value: string; label: string }>;
  value?: string;
  direction?: 'horizontal' | 'vertical';
  withBorder?: boolean;
  onChange: (value: CustomAny) => void;
  buttonClassName?: string;
  labelPosition?: 'prev' | 'post';
  isDisabled?: boolean;
  optionType?: RadioGroupOptionType;
  onAddOption?: (label: string) => void;
}

export const RadioButtonGroup: FC<RadioButtonGroupProps> = memo((props) => {
  const {
    options,
    value,
    direction = 'horizontal',
    withBorder = false,
    onChange,
    buttonClassName,
    labelPosition = 'post',
    isDisabled = false,
    optionType,
    onAddOption,
  } = props;

  const [newOptionMode, setNewOptionMode] = useState<'view' | 'edit'>('view');
  const [newOptionLabel, setNewOptionLabel] = useState<string | undefined>();

  const addNewOptionKey = 'addNewOption';

  const newOptionLabelChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setNewOptionLabel(e.target.value);
  };

  const closeEditOptionMode = (): void => {
    setNewOptionMode('view');
    setNewOptionLabel(undefined);
  };

  const applyNewOptionLabel = (): void => {
    if (newOptionLabel) {
      onAddOption?.(newOptionLabel);
      closeEditOptionMode();
    } else {
      closeEditOptionMode();
    }
  };

  const onEnterKeyPress = (e: KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      applyNewOptionLabel();
    }

    if (e.key === 'Escape') {
      closeEditOptionMode();
    }
  };

  const handleChange = (e: RadioChangeEvent): void => {
    e.target.value === addNewOptionKey ? setNewOptionMode('edit') : onChange?.(e.target.value);
  };

  const directionClass: Record<'horizontal' | 'vertical', string> = {
    horizontal: classNames('flex items-center flex-wrap desktop:flex-nowrap gap-1.5 desktop:gap-4', {
      'gap-2.5': optionType === 'button',
    }),
    vertical: classNames('flex flex-col gap-4'),
  };

  const getLabelClass = (isActive: boolean): string => {
    return classNames({ 'font-normal': !isActive });
  };

  const getRadioButtonClass = (isActive: boolean, isAddNewButton: boolean): string => {
    return classNames(
      'cursor-pointer ease-linear duration-200',
      {
        border: withBorder && !isAddNewButton,
        'border-accent': isActive && withBorder,
        'border-secondaryAccent': !isActive && withBorder,
        'p-2 rounded-lg': optionType === 'default',
        'border-l-2 border-l-secondaryAccent': isAddNewButton,
      },
      buttonClassName,
    );
  };

  const resultOptions = onAddOption ? [...options, { value: addNewOptionKey, label: '+' }] : options;

  return (
    <Radio.Group
      optionType={optionType}
      buttonStyle="solid"
      className={directionClass[direction]}
      onChange={handleChange}
      disabled={isDisabled}
      value={value}
    >
      {resultOptions.map((option) => {
        const isActive = option.value === value;
        const isAddNewButton = option.value === addNewOptionKey;

        return (
          <label key={option.value} className={getRadioButtonClass(isActive, isAddNewButton)}>
            <Radio value={option.value} className={classNames(s.radio, { 'flex-row-reverse': labelPosition === 'prev' })}>
              {isAddNewButton && newOptionMode === 'edit' ? (
                <input
                  type="text"
                  value={newOptionLabel}
                  autoFocus
                  onKeyDown={onEnterKeyPress}
                  onBlur={applyNewOptionLabel}
                  onChange={newOptionLabelChange}
                />
              ) : (
                <span className={getLabelClass(isActive)}>{option.label}</span>
              )}
            </Radio>
          </label>
        );
      })}
    </Radio.Group>
  );
});
