import { useCallback, useEffect, useState } from 'react';

interface HookApi {
  image: CanvasImageSource | undefined;
  loadImageSrc: (src: string) => void;
}

export const useFloorSchemaImage = (): HookApi => {
  const [imageSrc, setImageSrc] = useState<Nullable<string>>(null);
  const [image, setImage] = useState<CanvasImageSource | undefined>();

  const loadImageSrc = useCallback((src: string) => {
    setImageSrc(src);
  }, []);

  useEffect(() => {
    if (imageSrc) {
      const img = new window.Image();

      img.src = imageSrc;

      setImage(img);
    }
  }, [imageSrc]);

  return {
    image,
    loadImageSrc,
  };
};
