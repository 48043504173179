import { BoxForListing } from 'entities/Box';

export interface BoxFormDrawerSchema {
  mode: Nullable<DrawerMode>;
  box?: Nullable<BoxForListing>;
}

export enum DrawerMode {
  BOX_EDIT = 'box_edit',
  BOX_CREATE = 'box_create',
}
