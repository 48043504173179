import { ColumnsType } from 'antd/es/table';
import React, { FC, memo, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ServerTableActions } from 'shared/ui/ServerTable/types';
import { ReactComponent as FiltersIcon } from 'shared/assets/icons/FiltersIcon.svg';
import { ServerTable } from 'shared/ui/ServerTable';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { AppRoutes } from 'app/config/routerConfig/types';
import { getClientDateFormat } from 'shared/utils/helpers/getDateFormat';
import { ContractDetailsDrawer, contractDetailsDrawerActions } from 'widgets/ContractDetailsDrawer';
import { useAppDispatch } from 'app/config/storeConfig/hooks';
import { ExportEntity, ExportModal, getDepositColumnKeys, useOpenExportModal } from 'features/ExportModal';
import { useTableFilterContext } from 'features/TableFilter';
import { ReactComponent as ExportIcon } from 'shared/assets/icons/ExportIcon.svg';
import { useGetCurrencySymbol } from 'app/appState';
import { DepositListFilter } from './DepositListFilter';
import { transformDepositsListFilters } from '../utils/transformDepositsListFilters';
import classNames from 'classnames';
import { calculateCurrentDepositAmount, Deposit, getDepositStatus, useGetPaginatedDepositsQuery } from 'entities/Deposit';
import { getUserName } from 'entities/User';
import dayjs from 'dayjs';

export const DepositsTable: FC = memo(() => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const currencySymbol = useGetCurrencySymbol();

  const { openExportModal } = useOpenExportModal();

  const { t } = useAppTranslation(['contracts', 'common']);

  const { setFiltersOpened, appliedFilters, tags, clearAllFilters } = useTableFilterContext();

  const statusRefDate = useMemo(() => {
    const today = dayjs().startOf('day');

    return appliedFilters.statusRefDate?.value ? dayjs(appliedFilters.statusRefDate.value).startOf('day') : today;
  }, [appliedFilters.statusRefDate]);

  const goToUserContracts = useCallback(
    (userId: string): void => {
      navigate(`${AppRoutes.USERS}/${userId}/contracts`);
    },
    [navigate],
  );

  const openContractDetails = useCallback(
    (contractId: string): void => {
      dispatch(contractDetailsDrawerActions.setOpenContractDetails(contractId));
    },
    [dispatch],
  );

  const columns = useMemo<ColumnsType<Deposit>>(
    () => [
      {
        title: t('Customer'),
        width: '20%',
        key: 'user',
        render: (_, record) => (
          <div
            className="text-accent font-semibold underline cursor-pointer"
            onClick={() => {
              goToUserContracts(record.user.userId);
            }}
          >
            {getUserName(record.user)}
          </div>
        ),
      },
      {
        title: t('Invoice number'),
        key: 'documentNumber',
        width: '15%',
        render: (_, record) => (
          <div className="text-primary font-normal">
            {t('Invoice')} #{record.invoiceItem.invoice.documentNumber}
          </div>
        ),
      },
      {
        title: t('Contract number'),
        width: '15%',
        key: 'contractNumber',
        render: (_, record) => {
          return (
            <div
              className="text-accent underline cursor-pointer"
              onClick={() => {
                openContractDetails(record.contract.contractId);
              }}
            >
              #{record.contract.contractNumber}
            </div>
          );
        },
      },
      {
        title: t('Warehouse'),
        key: 'warehouse',
        width: '10%',
        render: (_, record) => {
          return <div className="text-primary font-normal">{record.contract.warehouse.name}</div>;
        },
      },
      {
        title: t('Initial Amount'),
        key: 'initialDepositAmount',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        width: '10%',
        render: (_, record) => {
          return (
            <div className="text-primary font-normal">
              {record.initialDepositAmount} {currencySymbol}
            </div>
          );
        },
      },
      {
        title: t('Amount ({{statusRefDate}})', { statusRefDate: getClientDateFormat(statusRefDate) }),
        key: 'depositAmount',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        width: '10%',
        render: (_, record) => {
          return (
            <div className="text-primary font-normal">
              {calculateCurrentDepositAmount(record, statusRefDate)} {currencySymbol}
            </div>
          );
        },
      },
      {
        title: t('Status ({{statusRefDate}})', { statusRefDate: getClientDateFormat(statusRefDate) }),
        key: 'status',
        render: (_, record) => {
          const status = getDepositStatus(record, statusRefDate);

          return <div className="text-primary font-semibold">{t(status)}</div>;
        },
      },
      {
        title: t('Start date'),
        key: 'contract.startDate',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        width: '10%',
        render: (_, record) => <div className="text-primary font-normal">{getClientDateFormat(record.contract.startDate)}</div>,
      },
      {
        title: t('Due date'),
        key: 'invoice.dueDate',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        width: '10%',
        render: (_, record) => <div className="text-primary font-normal">{getClientDateFormat(record.invoiceItem.invoice.dueDate)}</div>,
      },
      {
        title: t('Created date'),
        key: 'createdAt',
        width: '10%',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (_, record) => <div className="text-primary font-normal">{getClientDateFormat(record.createdAt)}</div>,
      },
    ],
    [t, statusRefDate, goToUserContracts, openContractDetails, currencySymbol],
  );

  const tableActions: Array<ServerTableActions<Deposit>> = useMemo(
    () => [
      {
        name: t('Export', { ns: 'common' }),
        theme: 'secondary',
        icon: <ExportIcon />,
        iconPosition: 'prev',
        onClick: () => {
          openExportModal({
            filters: transformDepositsListFilters(appliedFilters),
            columns: getDepositColumnKeys(t),
            entity: ExportEntity.DEPOSIT,
          });
        },
      },
      {
        name: t('Filters', { ns: 'common' }),
        icon: <FiltersIcon />,
        theme: 'secondary',
        iconPosition: 'prev',
        onClick: () => {
          setFiltersOpened(true);
        },
      },
    ],
    [appliedFilters, openExportModal, setFiltersOpened, t],
  );

  const highlightRow = (record: Deposit): string => {
    return classNames({
      'bg-secondary': !record.contract.isApplicable,
    });
  };

  return (
    <div className="pt-4">
      <div className="font-semibold text-3xl mb-7">{t('Deposits')}</div>
      <ServerTable
        columns={columns}
        fetchData={useGetPaginatedDepositsQuery}
        rowKey="depositId"
        search
        tableActionsPosition="prev"
        searchPlaceholder={t('Enter customer name or invoice | contract number')}
        tableActions={tableActions}
        tags={tags}
        onAllFiltersClear={clearAllFilters}
        defaultFilters={transformDepositsListFilters(appliedFilters)}
        rowClassName={highlightRow}
      />
      <ExportModal />
      <ContractDetailsDrawer />
      <DepositListFilter />
    </div>
  );
});
