import { UploadFile } from 'antd';

export interface UploadModalSchema {
  isOpened: boolean;
  files: Nullable<UploadFile[]>;
  title: Nullable<string>;
  description: Nullable<string>;
  submitArgs: Nullable<AnyObject>;
  allowedFormats?: MimeTypes[];
  multiple?: boolean;
  maxCount?: Nullable<number>;
  draggerDescription: Nullable<string>;
}

export interface UploadModalPayload {
  title: Nullable<string>;
  description: Nullable<string>;
  submitArgs: Nullable<AnyObject>;
  allowedFormats?: MimeTypes[];
  multiple?: boolean;
  maxCount?: Nullable<number>;
  draggerDescription: Nullable<string>;
}

export enum MimeTypes {
  CSV = 'text/csv',
  EXCEL_XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  EXCEL_XLS = 'application/vnd.ms-excel',
  WORD = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  PDF = 'application/pdf',
  IMAGE_JPEG = 'image/jpeg',
  IMAGE_PNG = 'image/png',
  IMAGE_GIF = 'image/gif',
  IMAGE_HEIC = 'image/heic',
  TEXT = 'text/plain',
}

export enum FileExtensions {
  CSV = '.csv',
  EXCEL_XLSX = '.xlsx',
  EXCEL_XLS = '.xls',
  PDF = '.pdf',
  IMAGE_JPEG = '.jpeg',
  IMAGE_PNG = '.png',
  IMAGE_GIF = '.gif',
  IMAGE_HEIC = '.HEIC',
  WORD = '.docx',
}
