import { api } from 'app/config/apiConfig';
import { Box } from '../model/types';

export const updateBoxesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    updateBoxes: builder.mutation<Box[], Array<Partial<Box>>>({
      query: (box) => ({
        url: '/boxes/batch-update',
        method: 'PUT',
        body: box,
      }),
      invalidatesTags: ['Boxes', 'Available boxes'],
    }),
  }),
});

export const { useUpdateBoxesMutation } = updateBoxesApi;
