import React, { FC, memo, useCallback } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { UsersListFilterKeys } from 'pages/UsersPage/model/types';

import { Select } from 'shared/ui/Select';
import { DefaultOptionType } from 'antd/es/select';
import { UserAccountType } from 'entities/User';

interface AccountTypeSelectProps {
  value: string;
  onChange: (value: string, label: string) => void;
  onClear: (filterKey: string) => void;
}

export const AccountTypeSelect: FC<AccountTypeSelectProps> = memo((props) => {
  const { value, onChange, onClear } = props;

  const { t } = useAppTranslation(['contracts', 'user']);

  const accountTypeSelectOptions = Object.values(UserAccountType).map((option) => ({
    label: t(option, { ns: 'user' }),
    value: option,
  }));

  const handleChange = useCallback(
    (value: string, option?: DefaultOptionType | DefaultOptionType[]): void => {
      if (!Array.isArray(option) && typeof option?.label === 'string') {
        onChange(value, option.label);
      }
    },
    [onChange],
  );

  const clearFilter = useCallback(() => {
    onClear(UsersListFilterKeys.accountType);
  }, [onClear]);

  return (
    <Select
      className="w-full"
      label={t('Account type', { ns: 'user' })}
      placeholder={t('All')}
      value={value}
      bordered
      allowClear
      options={accountTypeSelectOptions}
      onChange={handleChange}
      onClear={clearFilter}
    />
  );
});
