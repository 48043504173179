import { ColumnsType } from 'antd/es/table';
import React, { FC, memo, useMemo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { useGetPaginatedUsersQuery, User } from 'entities/User';
import { ServerTable } from 'shared/ui/ServerTable';
import { ServerTableRowSelectionActions } from 'shared/ui/ServerTable/types';
import { Lead, useGetPaginatedLeadsQuery } from 'entities/Lead';
import { chooseUserModalActions } from '../../model/slice/chooseUserModalSlice';
import { getUserType } from '../../model/selectors/getUserType';
import { UserType } from '../../model/types';
import { Paragraph } from 'shared/ui/Paragraph';

export const SelectUserStep: FC = memo(() => {
  const { t } = useAppTranslation('contracts');

  const dispatch = useAppDispatch();

  const userType = useAppSelector(getUserType);

  const columns = useMemo<ColumnsType<User | Lead>>(
    () => [
      {
        title: t('Name'),
        key: 'name',
        render: (_, record) => (
          <div className="text-primary font-semibold">
            {record.firstName} {record.lastName}
          </div>
        ),
      },
      { title: t('Email'), key: 'email', render: (_, record) => record.email },
      { title: t('Phone'), key: 'phone', render: (_, record) => record.phone },
      {
        title: t('Note'),
        key: 'note',
        width: '50%',
        render: (_, record) => {
          const paragraphs = record.note?.split('\n');

          const paragraphElements = paragraphs?.map((paragraph: string, index: number) => (
            <p key={index} className="font-normal !mb-0">
              {paragraph}
            </p>
          ));

          return <Paragraph rows={3}>{paragraphElements}</Paragraph>;
        },
      },
    ],
    [t],
  );

  const rowSelectionActions: Array<ServerTableRowSelectionActions<User | Lead>> = [
    {
      name: t('Next'),
      onClick: (selectedElements) => {
        dispatch(chooseUserModalActions.setSelectedUser(selectedElements[0]));

        dispatch(chooseUserModalActions.setUserType(null));
      },
    },
  ];

  return (
    <ServerTable
      columns={columns}
      fetchData={userType === UserType.USER ? useGetPaginatedUsersQuery : useGetPaginatedLeadsQuery}
      rowKey={userType === UserType.USER ? 'userId' : 'leadId'}
      search
      title={t(userType === UserType.USER ? 'Choose User' : 'Choose Lead')}
      selectionType="radio"
      rowSelectionActions={rowSelectionActions}
      defaultFilters={{ orderBy: 'createdAt:DESC' }}
    />
  );
});
