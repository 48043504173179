import { api } from 'app/config/apiConfig';
import { Pagination } from 'app/types/common';
import { BoxOccupancyObject } from '../model/types';

interface Params {
  warehouseId?: string | string[];
  ignoreUnstartedContracts?: boolean;
  ignoreClosedContracts?: boolean;
  isApplicable?: boolean;
  statusRefDate?: string;
  orderBy?: string;
}

export const getWarehousesBoxesOccupancyApi = api.injectEndpoints({
  endpoints: (build) => ({
    getWarehousesBoxesOccupancy: build.query<Pagination<BoxOccupancyObject>, Params>({
      query: (args) => ({
        url: '/warehouses/statistics/boxes/occupancy',
        params: args,
      }),
    }),
  }),
});

export const { useGetWarehousesBoxesOccupancyQuery } = getWarehousesBoxesOccupancyApi;
