import { FC, memo, useCallback, useState } from 'react';
import { useAppSelector } from 'app/config/storeConfig/hooks';
import { Modal } from 'shared/ui/Modal';
import { useScreenBreakpoints } from 'shared/utils/hooks/useScreenBreakpoints';
import { ReactComponent as InfoIcon } from 'shared/assets/icons/InfoIcon.svg';
import { ReactComponent as CloseIcon } from 'shared/assets/icons/CloseIcon.svg';
import { getSpecialPromotionContent } from '../model/selectors/getSpecialPromotionContent';
import { useSpecialPromotionModal } from '../utils/hooks/useSpecialPromotionModal';
import { Button } from 'shared/ui/Button';

export const SpecialPromotionModal: FC = memo(() => {
  const { isOpened, closeModal } = useSpecialPromotionModal();

  const [isInfoModalOpened, setInfoModalOpened] = useState(false);

  const openInfoModal = useCallback(() => {
    setInfoModalOpened(true);
  }, []);

  const closeInfoModal = useCallback(() => {
    setInfoModalOpened(false);
  }, []);

  const { isDesktop } = useScreenBreakpoints();

  const Content = useAppSelector(getSpecialPromotionContent);

  if (!Content) {
    return null;
  }

  return (
    <Modal
      width={1000}
      isOpen={isOpened}
      paddingLess
      transparent={isDesktop}
      onClose={closeModal}
      headerItems={
        // <div className="absolute top-3.5 right-2"> Default top-right values
        <div className={`absolute ${isDesktop ? 'top-36 right-44' : 'top-3.5 right-2'}`}>
          <div className="flex gap-4 items-center">
            <InfoIcon
              width={isDesktop ? 35 : 25}
              height={isDesktop ? 35 : 25}
              onClick={openInfoModal}
              className="[&>path]:stroke-[#363066] cursor-pointer hover:-translate-y-1 hover:scale-110 duration-300"
            />
            <CloseIcon
              width={isDesktop ? 45 : 35}
              height={isDesktop ? 45 : 35}
              className="[&>path]:stroke-[#363066] cursor-pointer hover:-translate-y-1 hover:scale-105 duration-300"
              onClick={closeModal}
            />
          </div>
        </div>
      }
      closeIcon={false}
    >
      <Content />
      <Modal isOpen={isInfoModalOpened} onClose={closeInfoModal} width={isDesktop ? 800 : 308}>
        <div className="flex flex-col text-center items-center gap-4 max-w-[380px] desktop:max-w-[800px] px-4">
          <div className="text-xl desktop:text-2xl font-bold">Angebotsbedingungen</div>
          <div className="text-lg desktop:text-xl font-semibold">Herzenssache: 14 € für den ersten Monat!</div>
          <div className="flex flex-col text-primaryLight gap-2 [&>span]:font-normal">
            <span>Mindestmietdauer: 2 Monate</span>
            <span>Abrechnung</span>
            <span>1. Monat: 14 € (zzgl. Versicherung)</span>
            <span>2. Monat: Reguläre Miete (zzgl. Versicherung)</span>
            <span>
              Vertragsverlängerung: Nach Ablauf der Mindestmietdauer verlängert sich der <br /> Vertrag automatisch monatlich, bis er unter
              Einhaltung einer Kündigungsfrist <br /> von 14 Tagen gekündigt wird. <br /> Gültig für alle Kunden <br /> Nicht kombinierbar
              mit anderen Rabatten oder Aktionen <br /> Angebot gültig nur im angegebenen Aktionszeitraum und solange der Vorrat <br />{' '}
              reicht <br /> Es gelten die allgemeinen Geschäftsbedingungen des Vertrags.
            </span>
          </div>
          <Button
            containerClassName="min-w-[255px] max-w-[380px]"
            className="!bg-[#DD4742]"
            fontColor="text-white"
            fontSize={isDesktop ? 'large' : 'medium'}
            onClick={closeInfoModal}
          >
            Zurück
          </Button>
        </div>
      </Modal>
    </Modal>
  );
});
