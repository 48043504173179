import { useGetAllWarehousesQuery } from 'entities/Warehouse';
import React, { FC, memo, useCallback, useMemo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { DepositsListFilterKeys } from '../../model/types';
import { MultipleSelect } from 'shared/ui/MultipleSelect';

interface WarehouseSelectProps {
  values: string[];
  onChange: (value: string[]) => void;
  onClear: (filterKey: string) => void;
}

export const WarehouseSelect: FC<WarehouseSelectProps> = memo((props) => {
  const { values, onChange, onClear } = props;

  const { t } = useAppTranslation('common');

  const { data } = useGetAllWarehousesQuery();

  const warehouseOptions = useMemo(() => {
    return (
      data?.map((warehouse) => ({
        label: warehouse.name,
        value: warehouse.warehouseId,
      })) || []
    );
  }, [data]);

  const clearFilter = useCallback(() => {
    onClear(DepositsListFilterKeys.selectedWarehouses);
  }, [onClear]);

  const handleChange = useCallback(
    (value: string[]): void => {
      value.length ? onChange(value) : clearFilter();
    },
    [onChange, clearFilter],
  );

  return (
    <>
      <MultipleSelect
        className="w-full"
        label={t('Warehouse')}
        placeholder={t('All')}
        value={values}
        bordered
        options={warehouseOptions}
        onChange={handleChange}
        onClear={clearFilter}
        allowClear
      />
    </>
  );
});
