import classNames from 'classnames';
import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import { useGetFloorsByWarehouseIdQuery } from 'entities/Floor';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import {
  useCanvasDimensions,
  Shape,
  ShapeType,
  getGridCellSize,
  ConstructorBackground,
  useFloorSchemaImage,
} from 'features/WarehouseMapConstructor';
import { Ellipse, Group, Layer, Line, Rect, Stage, Text } from 'react-konva';
import { getLocalizedString } from 'shared/utils/helpers/JSONLocalization';
import { useAppSelector } from 'app/config/storeConfig/hooks';
import { Box, useGetWarehouseBoxesQuery } from 'entities/Box';
import { getSelectedFilter } from 'features/BoxSizeFilter';
import { roundNumber } from 'shared/utils/helpers/roundNumber';

interface WarehouseMapProps {
  selectedWarehouseId: string | undefined;
  selectedBoxId: string | undefined;
  onSelectBox: (box: Box, sizeCodeId: string) => void;
}

type ColorType = 'free' | 'occupied' | 'selected' | 'different';

export const WarehouseMap: FC<WarehouseMapProps> = memo((props) => {
  const { selectedWarehouseId, selectedBoxId, onSelectBox } = props;

  const { t } = useAppTranslation('booking');

  const [selectedFloor, setSelectedFloor] = useState<Nullable<string>>(null);
  const { stageParentRef, canvasDimensions } = useCanvasDimensions();

  const { image, loadImageSrc } = useFloorSchemaImage();

  const { data: floors } = useGetFloorsByWarehouseIdQuery(selectedWarehouseId, { skip: !selectedWarehouseId });
  const { data: boxes } = useGetWarehouseBoxesQuery({ warehouseId: selectedWarehouseId }, { skip: !selectedWarehouseId });

  const selectedFilter = useAppSelector(getSelectedFilter);
  const gridCellSize = useAppSelector(getGridCellSize);

  const floorSchemaImage = floors?.find((floor) => floor.floorId === selectedFloor)?.floorSchemaBackgroundUrl;

  const selectFloor = (floorId: string): void => {
    setSelectedFloor(floorId);
  };

  const selectBox = (box: Box, sizeCodeId: string): void => {
    onSelectBox(box, sizeCodeId);
  };

  useEffect(() => {
    floors && selectFloor(floors[0].floorId);
  }, [floors]);

  useEffect(() => {
    if (floorSchemaImage) {
      loadImageSrc(floorSchemaImage);
    }
  }, [floorSchemaImage, loadImageSrc]);

  const renderedFloor = useMemo(() => {
    return floors?.find((floor) => floor.floorId === selectedFloor);
  }, [floors, selectedFloor]);

  const shapes: Shape[] = renderedFloor?.shapesConfig ? JSON.parse(renderedFloor.shapesConfig) : [];

  const selectedFill = '#DEE8FF';
  const selectedStroke = '#2058E7';
  const occupiedFill = '#FEE4E2';
  const occupiedStroke = '#EF4444';
  const freeFill = '#E2FEF1';
  const freeStroke = '#2BA865';
  const defaultFill = '#F3F6F9';
  const defaultStroke = '#788593';

  const getLegendClass = (type: ColorType): string => {
    const commonClass = 'w-4 h-4 mr-2.5 rounded-full border';

    const colorMap: Record<ColorType, string> = {
      free: 'border-[#2BA865] bg-[#E2FEF1]',
      occupied: 'border-[#EF4444] bg-[#FEE4E2]',
      selected: 'border-[#2058E7] bg-[#DEE8FF]',
      different: 'border-[#788593] bg-[#F3F6F9]',
    };

    return `${commonClass} ${colorMap[type]}`;
  };

  return (
    <>
      <div className="font-semibold text-2xl mb-4">{t('Choose floor')}</div>
      <div className="bg-white p-7 rounded-lg">
        <div className="flex items-center justify-center space-x-2 z-50">
          {floors?.map((floor) => (
            <div
              key={floor.floorId}
              className={classNames('py-1.5 px-3.5 rounded-lg text-primaryLight cursor-pointer', {
                'bg-accent text-white': selectedFloor === floor.floorId,
              })}
              onClick={() => {
                selectFloor(floor.floorId);
              }}
            >
              {getLocalizedString(floor.name)}
            </div>
          ))}
        </div>
        <div ref={stageParentRef} className="relative">
          <Stage width={canvasDimensions.width} height={500} x={0} y={0} draggable>
            <Layer>
              <ConstructorBackground image={image} />
              {shapes.map((shape) => {
                const boxText =
                  'label' in shape && 'width' in shape
                    ? `${shape.label}\n${roundNumber((shape.width / gridCellSize) * (shape.height / gridCellSize))} m²`
                    : '';
                const fontSize = 12;

                const targetBox = boxes?.find((box) => box.boxId === shape.id);
                const isOccupied = targetBox?.contractNumber || targetBox?.reservationNumber;
                const isMatchedSizeGroup = selectedFilter !== 'All' ? targetBox?.sizeCode.sizeGroup === selectedFilter : true;
                const isSelected = selectedBoxId === shape.id;

                const boxColor = (): { fill: string; stroke: string } => {
                  if (isSelected) {
                    return {
                      fill: selectedFill,
                      stroke: selectedStroke,
                    };
                  }

                  if (isMatchedSizeGroup) {
                    return {
                      fill: isOccupied ? occupiedFill : freeFill,
                      stroke: isOccupied ? occupiedStroke : freeStroke,
                    };
                  }

                  return {
                    fill: defaultFill,
                    stroke: defaultStroke,
                  };
                };

                switch (shape.type) {
                  case ShapeType.RECTANGLE:
                    return (
                      <Group
                        key={shape.id}
                        name="shape"
                        id={shape.id}
                        x={shape.x}
                        y={shape.y}
                        scaleX={shape.scaleX}
                        scaleY={shape.scaleY}
                        width={shape.width}
                        height={shape.height}
                        rotation={shape.rotation}
                        onClick={() => {
                          if (targetBox && !isOccupied) {
                            selectBox(targetBox, targetBox.sizeCode.sizeCodeId);
                          }
                        }}
                      >
                        <Rect
                          id={shape.id}
                          width={shape.width}
                          height={shape.height}
                          fill={boxColor().fill}
                          stroke={boxColor().stroke}
                          strokeWidth={shape.strokeWidth}
                          cornerRadius={shape.cornerRadius}
                          strokeScaleEnabled={false}
                        />
                        <Text
                          text={boxText}
                          x={shape.width / 2}
                          y={shape.height / 2 - fontSize / 2}
                          offsetX={(boxText.length * (fontSize / 4)) / 2}
                          offsetY={fontSize / 2}
                          fontSize={fontSize}
                          align="center"
                          verticalAlign="middle"
                        />
                      </Group>
                    );

                  case ShapeType.LINE:
                    return (
                      <Line
                        key={shape.id}
                        id={shape.id}
                        name="shape"
                        fill={shape.fill}
                        stroke={shape.stroke}
                        strokeWidth={shape.strokeWidth}
                        x={0}
                        y={0}
                        points={shape.points}
                        scaleX={shape.scaleX}
                        scaleY={shape.scaleY}
                        rotation={shape.rotation}
                      />
                    );

                  case ShapeType.CIRCLE:
                    return (
                      <Group
                        key={shape.id}
                        name="shape"
                        id={shape.id}
                        x={shape.x}
                        y={shape.y}
                        scaleX={shape.scaleX}
                        scaleY={shape.scaleY}
                        width={shape.radiusX * 2}
                        height={shape.radiusY * 2}
                        rotation={shape.rotation}
                      >
                        <Ellipse
                          key={shape.id}
                          id={shape.id}
                          fill={shape.fill}
                          stroke={shape.stroke}
                          radiusX={shape.radiusX}
                          radiusY={shape.radiusY}
                          strokeWidth={shape.strokeWidth}
                          cornerRadius={shape.cornerRadius}
                          strokeScaleEnabled={false}
                          width={shape.radiusX * 2}
                          height={shape.radiusY * 2}
                        />
                        <Text
                          text={shape.label}
                          x={shape.radiusX / fontSize / 2}
                          y={shape.radiusY / fontSize / 2}
                          fontSize={fontSize}
                          offsetX={shape.label.length * (fontSize / 4)}
                          offsetY={shape.radiusY / fontSize}
                          align="center"
                          verticalAlign="middle"
                        />
                      </Group>
                    );

                  case ShapeType.TEXT:
                    return <Text key={shape.id} name="shape" {...shape} text={shape.label} strokeWidth={1} />;

                  case ShapeType.POLYGON:
                    return <Line key={shape.id} name="shape" {...shape} x={0} y={0} closed />;

                  default:
                    return undefined;
                }
              })}
            </Layer>
          </Stage>
          <div className="absolute bottom-2 left-1/2 -translate-x-1/2 flex space-x-4">
            <div className="flex items-center text-sm">
              <div className={getLegendClass('free')} />
              {t('Free boxes')}
            </div>
            <div className="flex items-center text-sm">
              <div className={getLegendClass('occupied')} />
              {t('Occupied boxes')}
            </div>
            <div className="flex items-center text-sm">
              <div className={getLegendClass('selected')} />
              {t('Selected boxes')}
            </div>
            <div className="flex items-center text-sm">
              <div className={getLegendClass('different')} />
              {t('Different size box')}
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
