import { ColumnsType } from 'antd/es/table';
import React, { FC, memo, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ServerTableActions, ServerTableRowActions } from 'shared/ui/ServerTable/types';
import { ReactComponent as FiltersIcon } from 'shared/assets/icons/FiltersIcon.svg';
import { ServerTable } from 'shared/ui/ServerTable';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { AppRoutes } from 'app/config/routerConfig/types';
import { getClientDateFormat } from 'shared/utils/helpers/getDateFormat';
import { ContractDetailsDrawer, contractDetailsDrawerActions } from 'widgets/ContractDetailsDrawer';
import { useAppDispatch } from 'app/config/storeConfig/hooks';
import { ExportEntity, ExportModal, getCreditNoteColumnKeys, useOpenExportModal } from 'features/ExportModal';
import { useTableFilterContext } from 'features/TableFilter';
import { ReactComponent as ExportIcon } from 'shared/assets/icons/ExportIcon.svg';
import { useGetCurrencySymbol } from 'app/appState';
import { CreditNote, useGetPaginatedCreditNotesQuery } from 'entities/CreditNote';
import { generatePaymentPdfLink, Payment } from 'entities/Payment';
import { generateInvoiceCreditNotePdfLink } from 'entities/Invoice';
import { CreditNotesListFilter } from './CreditNotesListFilter';
import { transformCreditNotesListFilters } from '../utils/transformCreditNotesListFilters';
import { getUserName } from 'entities/User';
import classNames from 'classnames';

export const CreditNotesTable: FC = memo(() => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const currencySymbol = useGetCurrencySymbol();

  const { openExportModal } = useOpenExportModal();

  const { t } = useAppTranslation(['contracts', 'common']);

  const { setFiltersOpened, appliedFilters, tags, clearAllFilters } = useTableFilterContext();

  const goToUserContracts = useCallback(
    (userId: string): void => {
      navigate(`${AppRoutes.USERS}/${userId}/contracts`);
    },
    [navigate],
  );

  const openContractDetails = useCallback(
    (contractId: string): void => {
      dispatch(contractDetailsDrawerActions.setOpenContractDetails(contractId));
    },
    [dispatch],
  );

  const rowActions: Array<ServerTableRowActions<CreditNote>> = useMemo(
    () => [
      {
        name: 'downloadCreditNote',
        theme: 'primary',
        label: t('Download', { ns: 'common' }),
        onClick: (selectedCreditNote) => {
          const pdfLink = selectedCreditNote.invoice
            ? generateInvoiceCreditNotePdfLink(selectedCreditNote.invoice.invoiceId, selectedCreditNote.creditNoteId)
            : generatePaymentPdfLink((selectedCreditNote.payment as Payment).paymentId);

          window.open(pdfLink);
        },
      },
    ],
    [t],
  );

  const columns = useMemo<ColumnsType<CreditNote>>(
    () => [
      {
        title: t('Customer'),
        width: '20%',
        key: 'user',
        render: (_, record) => (
          <div
            className="text-accent font-semibold underline cursor-pointer"
            onClick={() => {
              goToUserContracts(record.user.userId);
            }}
          >
            {getUserName(record.user)}
          </div>
        ),
      },
      {
        title: t('Credit Note'),
        key: 'documentNumber',
        width: '15%',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (_, record) => (
          <div className="text-primary font-normal">
            {t('Credit Note')} #{record.documentNumber}
          </div>
        ),
      },
      {
        title: t('Credit type'),
        width: '15%',
        key: 'CreditNoteType',
        render: (_, record) => {
          const payment = record.payment;
          const invoice = record.invoice;

          if (payment) {
            return <div className="text-primary font-normal">{t('Payment')}</div>;
          }

          if (invoice) {
            return <div className="text-primary font-normal">{t('Invoice')}</div>;
          }

          return <div className="text-primary font-normal">{t('Other', { ns: 'common' })}</div>;
        },
      },
      {
        title: t('Contract number'),
        width: '10%',
        key: 'contract',
        render: (_, record) => {
          const contract = record.invoice?.contract || record.payment?.invoice.contract;

          return (
            <div
              className="text-accent underline cursor-pointer"
              onClick={() => {
                if (contract?.contractId) {
                  openContractDetails(contract.contractId);
                }
              }}
            >
              #{contract?.contractNumber}
            </div>
          );
        },
      },
      {
        title: t('Warehouse'),
        key: 'warehouse',
        width: '20%',
        render: (_, record) => {
          const warehouse = record.invoice?.warehouse || record.payment?.invoice?.warehouse;

          return <div className="text-primary font-normal">{warehouse?.name}</div>;
        },
      },
      {
        title: t('Credit amount'),
        key: 'creditedAmount',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        width: '20%',
        render: (_, record) => {
          return (
            <div className="text-primary font-normal">
              {record.creditedAmount} {currencySymbol}
            </div>
          );
        },
      },
      {
        title: t('Created date'),
        key: 'createdAt',
        width: '10%',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (_, record) => <div className="text-primary font-normal">{getClientDateFormat(record.createdAt)}</div>,
      },
    ],
    [t, goToUserContracts, openContractDetails, currencySymbol],
  );

  const tableActions: Array<ServerTableActions<CreditNote>> = useMemo(
    () => [
      {
        name: t('Export', { ns: 'common' }),
        theme: 'secondary',
        icon: <ExportIcon />,
        iconPosition: 'prev',
        onClick: () => {
          openExportModal({
            filters: transformCreditNotesListFilters(appliedFilters),
            columns: getCreditNoteColumnKeys(t),
            entity: ExportEntity.CREDIT_NOTE,
          });
        },
      },
      {
        name: t('Filters', { ns: 'common' }),
        icon: <FiltersIcon />,
        theme: 'secondary',
        iconPosition: 'prev',
        onClick: () => {
          setFiltersOpened(true);
        },
      },
    ],
    [appliedFilters, openExportModal, setFiltersOpened, t],
  );

  const highlightRow = (record: CreditNote): string => {
    return classNames({
      'bg-secondary': record.invoice?.contract && !record.invoice.contract.isApplicable,
    });
  };

  return (
    <div className="pt-4">
      <div className="font-semibold text-3xl mb-7">{t('Credit Notes')}</div>
      <ServerTable
        columns={columns}
        rowActions={rowActions}
        fetchData={useGetPaginatedCreditNotesQuery}
        rowKey="creditNoteId"
        search
        tableActionsPosition="prev"
        searchPlaceholder={t('Enter customer name or Credit Note number')}
        tableActions={tableActions}
        tags={tags}
        onAllFiltersClear={clearAllFilters}
        defaultFilters={transformCreditNotesListFilters(appliedFilters)}
        rowClassName={highlightRow}
      />
      <ExportModal />
      <ContractDetailsDrawer />
      <CreditNotesListFilter />
    </div>
  );
});
