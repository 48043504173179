import React, { FC, memo, ReactNode } from 'react';
import { ReactComponent as NoResultsIcon } from 'shared/assets/icons/NoResultsIcon.svg';

interface NoResultsProps {
  image?: string | ReactNode;
  title?: string;
  description?: Nullable<string>;
  actions?: Nullable<ReactNode>;
}

export const NoResults: FC<NoResultsProps> = memo((props) => {
  const { image, title, description, actions } = props;

  const renderImage = (): ReactNode => {
    if (typeof image === 'string') {
      return <img src={image} className="w-[60%]" alt="No results image" />;
    }

    if (!image) {
      return <NoResultsIcon />;
    }

    return image;
  };

  return (
    <div className="flex justify-center">
      <div className="flex flex-col items-center my-9 desktop:flex-row desktop:space-x-10">
        {renderImage()}
        <div className="flex flex-col desktop:justify-start">
          <div className="text-center mb-8 desktop:text-start">
            <div className="text-lg text-accent font-semibold">{title}</div>
            <div className="font-normal text-primaryLight">{description}</div>
          </div>
          {actions}
        </div>
      </div>
    </div>
  );
});
