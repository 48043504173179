import { SizeCode, SizeCodeType } from 'entities/SizeCode';
import { Floor } from 'entities/Floor';
import { Contract, UnitType } from 'entities/Contract';
import { PaymentProcessor } from 'entities/Payment';
import { InvoiceFrequencyType } from 'entities/Invoice';
import { Insurance } from 'entities/Insurance';
import { CachedDiscount } from 'entities/Discount';
import { PriceHistoryDocument } from 'entities/PriceHistory';

export enum LockState {
  OPENED = 'opened',
  CLOSED = 'closed',
  NA = 'N/A',
}

export interface LockStatus {
  lockId: string;
  status: LockState;
}

export interface LockStatusChange {
  lockId: string;
  status: LockState;
  warehouseId: string;
  boxNumber: string;
}

export interface Box {
  warehouseId: string;
  name: string;
  description: Nullable<string>;
  monthRate: Nullable<number>;
  weekRate: Nullable<number>;
  dailyRate: Nullable<number>;
  price: Nullable<number>;
  priceFactor: number;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  boxId: string;
  floor?: Nullable<Floor>;
  priceHistory?: PriceHistoryDocument[];
  width: number;
  height: number;
  length: number;
  sizeCode: SizeCode;
  isSelectedOnBooking: boolean;
  isForOwnNeeds: boolean;
  note: Nullable<string>;
  square: number;
  lockAddress?: string;
  maxOpeningTimeMS?: Nullable<number>;
  timeToOpenLockMS?: Nullable<number>;
  contractNumber: Nullable<string>;
  reservationNumber: Nullable<string>;
}

export interface BoxForListing {
  unitType: UnitType;
  name: string;
  boxId: string;
  warehouseId: string;
  warehouseName: string;
  sizeCodeId: string;
  square: number;
  width: number;
  height?: number;
  length: number;
  lockAddress?: string;
  monthRate?: number;
  weekRate?: number;
  dailyRate?: number;
  priceFactor: number;
  sizeCodeType: SizeCodeType;
  note?: string;
  description?: string;
  isActive: boolean;
  isForOwnNeeds: boolean;
  chargedTo?: string;
  paidTo?: string;
  contractId?: string;
  contractNumber?: string;
  reservationId?: string;
  reservationNumber?: number;
  expirationDate?: string;
  floorId?: string;
  floorName?: string;
  userId?: string;
  userEmail?: string;
  userFirstName?: string;
  userLastName?: string;
  lockStatus: LockState;
  lastOpened: string;
  isContractPaid?: boolean;
  maxOpeningTimeMS?: number;
  timeToOpenLockMS?: Nullable<number>;
}

export interface CachedBox extends Box {
  insurance: Nullable<Insurance>;
  floor?: Nullable<Floor>;
  originalEntityId: string;
  square: number;
  discounts: CachedDiscount[];
  contract: Contract;
}

export interface BookAndPayData {
  processor: PaymentProcessor;
  isAutopayment: boolean;
  boxId: string;
  userId: string;
  warehouseId: string;
  insuranceId?: Nullable<string>;
  rentOptionId: string;
  startDate: string;
  endDate: string;
  invoiceFrequencyType: InvoiceFrequencyType;
  invoiceFrequency: number;
  paymentFrequencyPeriod: string;
  useUserBalance: boolean;
}

export interface CreateBoxParams {
  warehouseId: string;
  name: string;
  sizeCodeId: string;
  length: number;
  width: number;
  height?: number;
  dailyRate?: number;
  weekRate?: number;
  monthRate?: number;
  description?: string;
  isFowOwnNeeds?: boolean;
  maxOpeningTimeMS?: Nullable<number>;
  timeToOpenLockMS?: Nullable<number>;
  lockAddress?: string;
  floorId?: string;
}

export interface UpdateBoxesParams extends Partial<CreateBoxParams> {
  id: string;
}
