import { UserAccountType } from 'entities/User';
import { TableFilterType } from 'features/TableFilter';
import { DebtCollectionFilter } from 'pages/UsersPage/model/types';
import { getFilterServerFormatDate } from 'shared/utils/helpers/getDateFormat';

interface UserListFilters {
  accountType?: UserAccountType;
  creationDateFrom?: string;
  creationDateTo?: string;
  isInDebtCollection?: boolean;
}

export const transformUsersListFilters = ({
  accountType,
  isInDebtCollection,
  creationDateFrom,
  creationDateTo,
}: TableFilterType): UserListFilters => {
  return {
    accountType: accountType?.value || undefined,
    creationDateFrom: getFilterServerFormatDate(creationDateFrom?.value),
    creationDateTo: getFilterServerFormatDate(creationDateTo?.value),
    isInDebtCollection:
      isInDebtCollection?.value === undefined ? undefined : isInDebtCollection?.value === DebtCollectionFilter.IN_PROGRESS,
  };
};
