import { useCallback, useState } from 'react';

type ViewMode = 'list' | 'map';

interface HookApi {
  viewMode: ViewMode;
  setMapMode: () => void;
  setListMode: () => void;
}

export const useViewMode = (): HookApi => {
  const [viewMode, setViewMode] = useState<ViewMode>('list');

  const setMapMode = useCallback(() => {
    setViewMode('map');
  }, []);

  const setListMode = useCallback(() => {
    setViewMode('list');
  }, []);

  return {
    viewMode,
    setMapMode,
    setListMode,
  };
};
