import { DefaultOptionType } from 'antd/es/select';
import React, { FC, memo, useCallback, useMemo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Select } from 'shared/ui/Select';
import { DepositsListFilterKeys } from '../../model/types';
import { DepositPaymentStatus } from 'entities/Deposit';

interface DepositStatusSelectProps {
  value: string;
  onChange: (value: string, label: string) => void;
  onClear: (filterKey: string) => void;
}

export const DepositStatusSelect: FC<DepositStatusSelectProps> = memo((props) => {
  const { value, onChange, onClear } = props;

  const { t } = useAppTranslation('contracts');

  const depositStatusOptions = useMemo<Array<{ label: string; value: DepositPaymentStatus }>>(() => {
    return [
      { label: t('Paid'), value: DepositPaymentStatus.PAID },
      { label: t('Not paid'), value: DepositPaymentStatus.NOT_PAID },
      { label: t('Cancelled'), value: DepositPaymentStatus.CANCELLED },
      { label: t('Refunded'), value: DepositPaymentStatus.REFUNDED },
      { label: t('Waiting for refund'), value: DepositPaymentStatus.WAITING_FOR_REFUND },
      { label: t('Not refunded'), value: DepositPaymentStatus.NOT_REFUNDED },
    ];
  }, [t]);

  const handleChange = useCallback(
    (value: string, option?: DefaultOptionType | DefaultOptionType[]): void => {
      if (!Array.isArray(option) && typeof option?.label === 'string') {
        onChange(value, option.label);
      }
    },
    [onChange],
  );

  const clearFilter = useCallback(() => {
    onClear(DepositsListFilterKeys.status);
  }, [onClear]);

  return (
    <Select
      containerClassName="flex-1"
      className="w-full"
      label={t('Status')}
      placeholder={t('All')}
      bordered
      allowClear
      options={depositStatusOptions}
      value={value}
      onChange={handleChange}
      onClear={clearFilter}
    />
  );
});
