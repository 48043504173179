import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserNoteDrawerSchema } from '../types';

const initialState: UserNoteDrawerSchema = {
  user: null,
  isDebtCollectionNote: false,
  isNote: false,
};

const userNoteDrawerSlice = createSlice({
  name: 'userNoteDrawer',
  initialState,
  reducers: {
    setOpenUserNoteDrawer: (state: UserNoteDrawerSchema, action: PayloadAction<UserNoteDrawerSchema>) => {
      state.user = action.payload.user;
      state.isDebtCollectionNote = action.payload.isDebtCollectionNote;
      state.isNote = action.payload.isNote;
    },
  },
});

export const { actions: userNoteDrawerActions, reducer: userNoteDrawerReducer } = userNoteDrawerSlice;
