import { useAppSelector } from 'app/config/storeConfig/hooks';
import { useEffect, useRef } from 'react';
import { getShapes } from '../../model/selectors/getShapes';
import { Shape } from '../../model/types';
import { checkShapesChanges, ShapeChangesResult } from '../helpers/checkShapesChanges';

export const useWatchShapeChanges = (shapesConfig: Nullable<string> | undefined): ShapeChangesResult => {
  const currentShapes = useAppSelector(getShapes);

  const initialShapes = useRef<Shape[]>([]);

  useEffect(() => {
    const parsedShapes: Shape[] = shapesConfig ? JSON.parse(shapesConfig) : [];

    initialShapes.current = parsedShapes;
  }, [shapesConfig]);

  return checkShapesChanges(initialShapes.current, currentShapes);
};
