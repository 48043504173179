import { api } from 'app/config/apiConfig';
import { Floor } from '../model/types';

interface Args {
  name: string;
}

export const createFloorApi = api.injectEndpoints({
  endpoints: (build) => ({
    createFloor: build.mutation<Floor, { warehouseId: string; args: Args }>({
      query: ({ warehouseId, args }) => ({
        url: `warehouses/${warehouseId}/floors`,
        method: 'POST',
        body: args,
      }),
      invalidatesTags: ['Floors', 'Warehouses'],
    }),
  }),
});

export const { useCreateFloorMutation } = createFloorApi;
