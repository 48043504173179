import Konva from 'konva';
import { Node } from 'konva/lib/Node';
import { Vector2d } from 'konva/lib/types';
import { MutableRefObject, useCallback, useEffect, useRef } from 'react';
import { useAppSelector } from 'app/config/storeConfig/hooks';
import { getGridCellSize } from '../../model/selectors/getGridCellSize';
import { Shape } from '../../model/types';

interface HookApi {
  layerRef: MutableRefObject<Nullable<Konva.Layer>>;
  stageRef: MutableRefObject<Nullable<Konva.Stage>>;
  transformerRef: MutableRefObject<Nullable<Konva.Transformer>>;
  resizeByGrid: (oldPos: Vector2d, newPos: Vector2d) => Vector2d;
}

export const useTransform = ({ shapes }: { shapes: Shape[] }): HookApi => {
  const layerRef = useRef<Konva.Layer>(null);
  const transformerRef = useRef<Konva.Transformer>(null);
  const stageRef = useRef<Konva.Stage>(null);

  const gridCellSize = useAppSelector(getGridCellSize);

  useEffect(() => {
    if (!transformerRef.current) return;

    const nodes = shapes.reduce((acc: Node[], shape: Shape) => {
      if (shape.selected) {
        const node = stageRef.current?.findOne('#' + shape.id);
        if (node) {
          return [...acc, node];
        }
      }
      return acc;
    }, []);

    transformerRef.current.nodes(nodes);
    transformerRef.current.getLayer()?.batchDraw();
  }, [shapes]);

  const resizeByGrid = useCallback(
    (oldPos: Vector2d, newPos: Vector2d): Vector2d => {
      if (transformerRef.current?.getActiveAnchor() === 'rotater') {
        return newPos;
      }

      const closestX = Math.round(newPos.x / gridCellSize) * gridCellSize;
      const diffX = Math.abs(newPos.x - closestX);

      const closestY = Math.round(newPos.y / gridCellSize) * gridCellSize;
      const diffY = Math.abs(newPos.y - closestY);

      const snappedX = diffX < gridCellSize;
      const snappedY = diffY < gridCellSize;

      if (snappedX && !snappedY) {
        return {
          x: closestX,
          y: oldPos.y,
        };
      } else if (snappedY && !snappedX) {
        return {
          x: oldPos.x,
          y: closestY,
        };
      } else if (snappedX && snappedY) {
        return {
          x: closestX,
          y: closestY,
        };
      }

      return newPos;
    },
    [gridCellSize],
  );

  return { layerRef, stageRef, transformerRef, resizeByGrid };
};
