import { Collapse } from 'antd';
import classNames from 'classnames';
import React, { FC, memo } from 'react';
import { useParams } from 'react-router-dom';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useGetSizeCodesByWarehouseIdQuery } from 'entities/SizeCode';
import { ReactComponent as FilledCollapseArrow } from 'shared/assets/icons/FilledCollapseArrow.svg';
import { BoxesList } from './BoxesList';

interface BoxPickerProps {
  floorId: string;
}

export const BoxPicker: FC<BoxPickerProps> = memo((props) => {
  const { floorId } = props;

  const { t } = useAppTranslation('warehouse-management');

  const { warehouseId } = useParams();

  const { data } = useGetSizeCodesByWarehouseIdQuery(warehouseId, { skip: !warehouseId });

  return (
    <div>
      <Collapse
        bordered={false}
        ghost
        items={data?.map(({ sizeCodeId, square }) => {
          return {
            key: sizeCodeId,
            label: <div>{t('Boxes - {{square}} m²', { square })}</div>,
            children: <BoxesList sizeCodeId={sizeCodeId} warehouseId={warehouseId} floorId={floorId} />,
          };
        })}
        expandIcon={(panelProps) => {
          return panelProps.collapsible !== 'disabled' ? (
            <FilledCollapseArrow
              className={classNames('ease-linear duration-200', {
                'rotate-90 fill-accent': panelProps.isActive,
                'fill-secondaryAccent': !panelProps.isActive,
              })}
            />
          ) : undefined;
        }}
      />
    </div>
  );
});
