import React, { FC, memo, ReactElement, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { getUploadModalState, MimeTypes, UploadModal, uploadModalActions, useFileUpload } from 'features/UploadModal';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { showNotification } from 'app/providers/NotificationsProvider';
import { useRequestContractClosureMutation } from 'entities/Contract';

interface ContractClosureRequestProps {
  contractId: string;
  action: ReactElement;
}

export const ContractClosureRequest: FC<ContractClosureRequestProps> = memo((props) => {
  const { contractId, action } = props;

  const dispatch = useAppDispatch();

  const [requestContractClosure, { isLoading }] = useRequestContractClosureMutation();

  const { files, resetFile } = useFileUpload();

  const { t } = useAppTranslation('contracts');

  const { submitArgs } = useAppSelector(getUploadModalState);

  const closeModal = useCallback((): void => {
    dispatch(uploadModalActions.closeModal());

    resetFile();
  }, [dispatch, resetFile]);

  const openModal = useCallback((): void => {
    dispatch(
      uploadModalActions.openModal({
        title: t('Photo`s verification'),
        description: t(
          'To request contract closure, you must pass a photo verification. Provide photos of the empty box to the administrator',
        ),
        submitArgs: { contractId },
        allowedFormats: [MimeTypes.IMAGE_JPEG, MimeTypes.IMAGE_PNG, MimeTypes.IMAGE_HEIC],
        multiple: true,
        maxCount: 4,
        draggerDescription: t('JPEG or PNG, file size no more than 10MB'),
      }),
    );
  }, [dispatch, t, contractId]);

  const handleRequestContractClosure = useCallback(async (): Promise<void> => {
    if (!files) {
      return;
    }

    const formData = new FormData();

    files.forEach((uploadFile) => {
      if (uploadFile.originFileObj) {
        formData.append('files', uploadFile.originFileObj);
      }
    });

    try {
      await requestContractClosure({ contractId: submitArgs?.contractId, formData }).unwrap();

      showNotification('info', t('Success', { ns: 'common' }), t('Photos uploaded successfully', { ns: 'common' }));

      closeModal();
    } catch (error: CustomAny) {
      console.log('error', error);
      const errorMessage = t(error?.data?.message || 'Error while uploading photos', { ns: 'common' });

      showNotification('error', t('Error', { ns: 'common' }), errorMessage);
    }
  }, [files, requestContractClosure, submitArgs?.contractId, t, closeModal]);

  return (
    <>
      {React.cloneElement(action, { onClick: openModal })}
      <UploadModal onOk={handleRequestContractClosure} isLoading={isLoading} />
    </>
  );
});
