import { Collapse } from 'antd';
import classNames from 'classnames';
import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { ReactComponent as CollapseArrow } from 'shared/assets/icons/CollapseArrow.svg';
import { useAppSelector } from 'app/config/storeConfig/hooks';
import { getSelectedFilter } from 'features/BoxSizeFilter';
import { Box } from 'entities/Box';
import { NoResults } from 'shared/ui/NoResults';
import { getSelectedSizeCodeId } from 'pages/BookingPage';
import { SizeCodeForBooking } from 'entities/SizeCode';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { RentOptionDocument } from 'entities/RentOption';
import { BoxesContainer } from './BoxesContainer';
import { CollapseHeading } from './CollapseHeading';
import { ContactUs } from './ContactUs';
import s from './SizeCodeCollapse.module.scss';

interface SizeCodeCollapseProps {
  sizeCodes: SizeCodeForBooking[] | undefined;
  rentOptions: RentOptionDocument[] | undefined;
  selectedBoxId: string | undefined;
  onSelectBox: (box: Box, sizeCodeId: string) => void;
  onBookBox: (box: Box, sizeCodeId: string) => void;
}

export const SizeCodeCollapse: FC<SizeCodeCollapseProps> = memo((props) => {
  const { sizeCodes = [], rentOptions = [], selectedBoxId, onSelectBox, onBookBox } = props;

  const [openedKeys, setOpenedKeys] = useState<Nullable<string | string[]>>(null);

  const { t } = useAppTranslation('booking');

  const boxSizeFilter = useAppSelector(getSelectedFilter);
  const selectedSizeCodeId = useAppSelector(getSelectedSizeCodeId);

  const filterBySizeCode = useMemo<SizeCodeForBooking[]>(() => {
    return sizeCodes.filter((sizeCode) => (boxSizeFilter === 'All' ? true : boxSizeFilter === sizeCode.sizeGroup));
  }, [boxSizeFilter, sizeCodes]);

  const onCollapseOpen = useCallback((key: string | string[]): void => {
    setOpenedKeys(key);
  }, []);

  return (
    <>
      {filterBySizeCode.length ? (
        <Collapse
          className={s.collapse}
          bordered={false}
          ghost={true}
          defaultActiveKey={selectedSizeCodeId || undefined}
          onChange={onCollapseOpen}
          expandIcon={(panelProps) => {
            return panelProps.collapsible !== 'disabled' ? (
              <CollapseArrow
                className={classNames('ease-linear duration-200', {
                  'rotate-90 fill-accent': panelProps.isActive,
                  'fill-secondaryAccent': !panelProps.isActive,
                })}
              />
            ) : undefined;
          }}
          expandIconPosition="end"
          items={filterBySizeCode.map(
            ({ sizeCodeId, square, minBoxPrice, description, imageUrl, availableBoxesCount, sizeGroup, sizeCodeType }) => {
              // no need to filter rent option dateFrom dateTo as we don't know exact contract start date
              const rentDiscounts = rentOptions.filter((option) => option.unitSizes?.includes(square.toString()));
              const generalDiscounts = rentOptions.filter((option) => !option.unitSizes && option.showAsPromotion);

              const hasRentDiscounts = rentDiscounts.length > 0;

              return {
                key: sizeCodeId,
                className: classNames('overflow-hidden', { 'rounded-lg': hasRentDiscounts }),
                collapsible: availableBoxesCount > 0 ? 'header' : 'disabled',
                label: (
                  <CollapseHeading
                    square={square}
                    description={description}
                    imageUrl={imageUrl}
                    sizeGroup={sizeGroup}
                    sizeCodeType={sizeCodeType}
                    availableBoxesCount={availableBoxesCount}
                    rentDiscounts={rentDiscounts}
                    generalDiscounts={generalDiscounts}
                    isOpened={openedKeys?.includes(sizeCodeId) || false}
                    minBoxPrice={minBoxPrice}
                  />
                ),
                children: (
                  <BoxesContainer
                    selectedBoxId={selectedBoxId}
                    sizeCodeId={sizeCodeId}
                    rentDiscounts={rentDiscounts}
                    generalDiscounts={generalDiscounts}
                    onSelectBox={onSelectBox}
                    onBookBox={onBookBox}
                  />
                ),
              };
            },
          )}
        />
      ) : (
        <NoResults
          title={t("Oops, We don't have any boxes available")} // eslint-disable-line quotes
          description={t('Contact us and we will find the best offer for you')}
          actions={<ContactUs className="desktop:self-start" />}
        />
      )}
    </>
  );
});
