import { Dropdown, MenuProps } from 'antd';
import React, { FC, FunctionComponent, memo, ReactNode, SVGProps, useState } from 'react';
import { WarehouseMapTool } from '../../model/types';
import { MenuInfo } from 'rc-menu/lib/interface';

interface ToolProps {
  id: WarehouseMapTool;
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  activeTool: WarehouseMapTool;
  onCLick: (id: WarehouseMapTool) => void;
  multiTools?: Array<{
    id: WarehouseMapTool;
    Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  }>;
}

export const Tool: FC<ToolProps> = memo((props) => {
  const { id, Icon, activeTool, onCLick, multiTools } = props;

  const [activeMultiToolKey, setActiveMultiToolKey] = useState([id]);

  const isMultiTool = Boolean(multiTools?.length);

  const handleClick = (): void => {
    onCLick(id);
  };

  const selectMultiToolItem = ({ key }: MenuInfo): void => {
    onCLick(key as WarehouseMapTool);
    setActiveMultiToolKey([key as WarehouseMapTool]);
  };

  const multiToolItems: MenuProps['items'] = multiTools
    ? [
        {
          key: id,
          label: (
            <Icon
              className={
                activeTool === id ? '[&>path]:stroke-white [&>rect]:stroke-white' : '[&>path]:stroke-primary [&>rect]:stroke-primary'
              }
            />
          ),
          className: activeTool === id ? '!bg-accent' : '!bg-none',
        },
        ...multiTools.map(({ id, Icon }) => ({
          key: id,
          label: (
            <Icon
              className={
                activeTool === id ? '[&>path]:stroke-white [&>rect]:stroke-white' : '[&>path]:stroke-primary [&>rect]:stroke-primary'
              }
            />
          ),
          className: activeTool === id ? '!bg-accent' : '!bg-none',
        })),
      ]
    : [];

  const activeMultiToolItem = multiToolItems.find((tool) => tool?.key === activeMultiToolKey?.[0]);

  const menu = (): ReactNode => {
    return (
      <Dropdown
        menu={{
          items: multiToolItems,
          selectable: true,
          defaultSelectedKeys: activeMultiToolKey,
          onClick: selectMultiToolItem,
        }}
        trigger={['click']}
        placement="bottom"
      >
        <div
          className={`flex items-center space-x-3.5 p-2 ${
            activeTool === activeMultiToolItem?.key ? 'bg-accent' : 'bg-none'
          } rounded-lg cursor-pointer transition`}
        >
          {/* @ts-expect-error label is tool Icon prop */}
          {activeMultiToolItem?.label}
        </div>
      </Dropdown>
    );
  };

  return isMultiTool ? (
    <>{menu()}</>
  ) : (
    <div
      className={`flex items-center space-x-3.5 p-2 ${activeTool === id ? 'bg-accent' : 'bg-none'} rounded-lg cursor-pointer transition`}
      onClick={handleClick}
    >
      <Icon
        className={activeTool === id ? '[&>path]:stroke-white [&>rect]:stroke-white' : '[&>path]:stroke-primary [&>rect]:stroke-primary'}
      />
    </div>
  );
});
