import React, { FC, memo, ReactNode, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { StepperModal, stepperModalActions } from 'features/StepperModal';
import { chooseUserModalActions } from '../model/slice/chooseUserModalSlice';
import { getUserType } from '../model/selectors/getUserType';
import { SelectUserStep } from './steps/SelectUserStep';

export const ChooseUserModal: FC = memo(() => {
  const dispatch = useAppDispatch();

  const userType = useAppSelector(getUserType);

  const closeModal = useCallback(() => {
    dispatch(chooseUserModalActions.resetModalState());
    dispatch(stepperModalActions.changeActiveStepIndex(0));
  }, [dispatch]);

  const stepsContent: ReactNode[] = [<SelectUserStep key="1" />];

  return <StepperModal isOpened={Boolean(userType)} onClose={closeModal} steps={[]} stepsContent={stepsContent} width={1129} />;
});
