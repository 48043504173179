/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, memo, useCallback } from 'react';
import { Box } from 'entities/Box';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Collapse } from 'shared/ui/Collapse';
import { PromotionContent } from './PromotionContent';
import { SelectSizeContent } from './SelectSizeContent';
import { NearestWarehousesList } from './NearestWarehousesList';
import { WarehouseForBooking } from 'entities/Warehouse';
import { useScrollToElement } from 'shared/utils/hooks/useScrollToElement';

interface SelectBoxSizeFormProps {
  selectedBoxId: string | undefined;
  selectedWarehouseId: string | undefined;
  onSelectBox: (box: Box, sizeCodeId: string) => void;
  onCompleteStep: (box: Box, sizeCodeId: string) => void;
  onSelectWarehouse: (warehouse: WarehouseForBooking) => void;
}

export const SelectBoxSizeForm: FC<SelectBoxSizeFormProps> = memo((props) => {
  const { onSelectWarehouse } = props;

  const { t } = useAppTranslation('booking');

  const { scrollToTop } = useScrollToElement();

  const onSelect = useCallback(
    (warehouse: WarehouseForBooking) => {
      onSelectWarehouse(warehouse);
      scrollToTop();
    },
    [onSelectWarehouse, scrollToTop],
  );

  return (
    <div className="space-y-3">
      <Collapse
        title={t('Promotion')}
        content={<PromotionContent selectedWarehouseId={props.selectedWarehouseId} />}
        activeKey="1"
        isOpened
      />
      <Collapse title={t('Select the size of the box')} content={<SelectSizeContent {...props} />} activeKey="2" isOpened />
      <Collapse
        title={t('Warehouses near you')}
        content={<NearestWarehousesList onSelectWarehouse={onSelect} selectedWarehouseId={props.selectedWarehouseId} />}
        activeKey="3"
        isOpened
      />
    </div>
  );
});
