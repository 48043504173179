import React, { FC, memo } from 'react';
import { BoxesOccupancyTable } from './BoxesOccupancyTable';
import { TableFilterContextProvider } from 'features/TableFilter';

export const BoxesOccupancyPage: FC = memo(() => {
  return (
    <TableFilterContextProvider>
      <BoxesOccupancyTable />
    </TableFilterContextProvider>
  );
});
