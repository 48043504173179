import { DefaultOptionType } from 'antd/es/select';
import React, { FC, memo, useCallback, useMemo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Select } from 'shared/ui/Select';
import { ContractApplicability, LeadsListFilterKeys } from '../../model/types';

interface ApplicabilitySelectProps {
  value: string;
  onChange: (value: string, label: string) => void;
  onClear: (filterKey: string) => void;
}

export const ApplicabilitySelect: FC<ApplicabilitySelectProps> = memo((props) => {
  const { value, onChange, onClear } = props;

  const { t } = useAppTranslation(['contracts', 'common']);

  const applicabilityOptions = useMemo<Array<{ label: string; value: ContractApplicability }>>(() => {
    return [
      { label: t('Applicable'), value: ContractApplicability.APPLICABLE },
      { label: t('Inapplicable'), value: ContractApplicability.INAPPLICABLE },
    ];
  }, [t]);

  const handleChange = useCallback(
    (value: string, option?: DefaultOptionType | DefaultOptionType[]): void => {
      if (!Array.isArray(option) && typeof option?.label === 'string') {
        onChange(value, option.label);
      }
    },
    [onChange],
  );

  const clearFilter = useCallback(() => {
    onClear(LeadsListFilterKeys.isApplicable);
  }, [onClear]);

  return (
    <Select
      containerClassName="flex-1"
      className="w-full"
      label={t('Applicability')}
      placeholder={t('All')}
      bordered
      allowClear
      options={applicabilityOptions}
      value={value}
      onChange={handleChange}
      onClear={clearFilter}
    />
  );
});
