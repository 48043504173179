import { roundNumber } from 'shared/utils/helpers/roundNumber';
import { Shape } from '../../model/types';

export interface ShapeChangesResult {
  newElements: Shape[];
  updatedElements: Shape[];
}

export const checkShapesChanges = (initialShapes: Shape[], currentShapes: Shape[]): ShapeChangesResult => {
  const result: ShapeChangesResult = {
    newElements: [],
    updatedElements: [],
  };

  const checkNewShapes = (): void => {
    const newElements =
      currentShapes.filter((currentShape) => !initialShapes.some((initialShape) => initialShape.id === currentShape.id)) ||
      initialShapes.filter((initialShape) => !currentShapes.some((currentShape) => initialShape.id === currentShape.id));

    if (newElements.length) {
      result.newElements = newElements;
    }
  };

  const checkLabelsChanges = (): void => {
    for (let i = 0; i < initialShapes.length; i++) {
      for (let j = 0; j < currentShapes.length; j++) {
        const initialShape = initialShapes[i];
        const currentShape = currentShapes[j];

        if (initialShape.id === currentShape.id) {
          const isLabelChanged = 'label' in initialShape && 'label' in currentShape ? initialShape.label !== currentShape.label : false;

          if (isLabelChanged) {
            const isAlreadyAdded = result.updatedElements.some((element) => element.id === currentShape.id);

            if (isAlreadyAdded) {
              result.updatedElements = result.updatedElements.map((element) =>
                element.id === currentShape.id ? { ...element, ...currentShape } : element,
              );
            } else {
              result.updatedElements.push(currentShape);
            }
          }
        }
      }
    }
  };

  const checkDimensionsChanges = (): void => {
    for (let i = 0; i < initialShapes.length; i++) {
      for (let j = 0; j < currentShapes.length; j++) {
        const initialShape = initialShapes[i];
        const currentShape = currentShapes[j];

        if (
          initialShape.id === currentShape.id &&
          'width' in currentShape &&
          'height' in currentShape &&
          'width' in initialShape &&
          'height' in currentShape
        ) {
          const isScaleChanged =
            roundNumber(initialShape.width, 1) !== roundNumber(currentShape.width, 1) ||
            roundNumber(initialShape.height, 1) !== roundNumber(currentShape.height, 1);

          if (isScaleChanged) {
            const isAlreadyAdded = result.updatedElements.some((element) => element.id === currentShape.id);

            if (isAlreadyAdded) {
              result.updatedElements = result.updatedElements.map((element) =>
                element.id === currentShape.id ? { ...element, ...currentShape } : element,
              );
            } else {
              result.updatedElements.push(currentShape);
            }
          }
        }
      }
    }
  };

  const checkPositionChanges = (): void => {
    const initialMap = initialShapes.reduce((acc: Record<string, number>, obj) => {
      acc[obj.id] = obj.x;
      return acc;
    }, {});

    const changes = currentShapes.map((obj) => {
      const initialValue = initialMap[obj.id];
      return {
        id: obj.id,
        hasChanged: initialValue !== undefined && initialValue !== obj.x,
      };
    });

    for (let i = 0; i < changes.length; i++) {
      if (changes[i].hasChanged) {
        const target = currentShapes.find((shape) => shape.id === changes[i].id);

        if (target) {
          const isAlreadyAdded = result.updatedElements.some((element) => element.id === target.id);

          if (isAlreadyAdded) {
            result.updatedElements = result.updatedElements.map((element) =>
              element.id === target.id ? { ...element, ...target } : element,
            );
          } else {
            result.updatedElements.push(target);
          }
        }
      }
    }
  };

  checkNewShapes();
  checkDimensionsChanges();
  checkPositionChanges();
  checkLabelsChanges();

  return result;
};
