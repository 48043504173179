import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { getActiveTool } from '../../model/selectors/getActiveTool';
import { warehouseMapConstructorActions } from '../../model/slice/warehouseMapConstructorSlice';
import { WarehouseMapTool } from '../../model/types';

interface HookApi {
  activeTool: WarehouseMapTool;
  onChangeTool: (tool: WarehouseMapTool) => void;
}

export const useTool = (): HookApi => {
  const dispatch = useAppDispatch();

  const activeTool = useAppSelector(getActiveTool);

  const onChangeTool = useCallback(
    (tool: WarehouseMapTool) => {
      dispatch(warehouseMapConstructorActions.changeTool(tool));
    },
    [dispatch],
  );

  return {
    activeTool,
    onChangeTool,
  };
};
