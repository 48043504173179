import dayjs, { Dayjs } from 'dayjs';
import { Deposit, DepositPaymentStatus } from 'entities/Deposit/model/types';
import { Payment } from 'entities/Payment';

export const getDepositStatus = (deposit: Deposit, date?: Nullable<Dayjs> | string | undefined): DepositPaymentStatus => {
  const today = dayjs().startOf('day');
  const refDate = dayjs(date || today).startOf('day');

  const { invoiceItem, depositAmount, contract, isRefunded } = deposit;
  const { invoice } = invoiceItem;

  const payments = invoice.payments.filter((payment) => {
    const paymentDate = dayjs(payment.createdAt).startOf('day');

    return (paymentDate.isSame(refDate) || paymentDate.isBefore(refDate)) && payment.amount >= depositAmount;
  });

  const hasCreditNoteBeforeRef = (payment: Payment): boolean =>
    payment.creditNote
      ? dayjs(payment.creditNote.createdAt).startOf('day').isBefore(refDate) ||
        dayjs(payment.creditNote.createdAt).startOf('day').isSame(refDate)
      : false;

  const paidDeposit = payments.find((payment) => {
    return !payment.creditNote || !hasCreditNoteBeforeRef(payment);
  });

  // const refundedDeposit = payments.find((payment) => {
  //   return hasCreditNoteBeforeRef(payment) && payment.refund && isRefunded;
  // });

  const waitingRefundDeposit = payments.find((payment) => {
    const leaveDate = dayjs(contract.leaveDate).startOf('day');

    return (
      payment.creditNote &&
      hasCreditNoteBeforeRef(payment) &&
      !payment.refund &&
      !isRefunded &&
      contract.leaveDate &&
      !leaveDate.isAfter(refDate) &&
      payment.creditNote.creditedAmount >= depositAmount
    );
  });

  const cancelledDeposit = payments.find((payment) => {
    const leaveDate = dayjs(contract.leaveDate).startOf('day');

    return hasCreditNoteBeforeRef(payment) && !payment.refund && !isRefunded && (!contract.leaveDate || leaveDate.isAfter(refDate));
  });

  if (paidDeposit) return DepositPaymentStatus.PAID;
  if (!paidDeposit && isRefunded) return DepositPaymentStatus.REFUNDED;
  if (!paidDeposit && waitingRefundDeposit) return DepositPaymentStatus.WAITING_FOR_REFUND;
  if (!paidDeposit && cancelledDeposit) return DepositPaymentStatus.CANCELLED;

  return DepositPaymentStatus.NOT_PAID;
};
