import { TableFilterType } from 'features/TableFilter';
import { getFilterServerFormatDate } from 'shared/utils/helpers/getDateFormat';

interface BoxesOccupancyFilters {
  warehouseId?: string;
  isApplicable?: boolean;
  ignoreUnstartedContracts?: boolean;
  ignoreClosedContracts?: boolean;
  statusRefDate?: string;
}

export const transformBoxesOccupancyFilters = ({
  selectedWarehouses,
  isApplicable,
  statusRefDate,
  ignoreUnstartedContracts,
  ignoreClosedContracts,
}: TableFilterType): BoxesOccupancyFilters => {
  return {
    warehouseId: selectedWarehouses?.value || undefined,
    isApplicable: isApplicable?.value === undefined ? undefined : isApplicable?.value === 'applicable',
    statusRefDate: getFilterServerFormatDate(statusRefDate?.value),
    ignoreUnstartedContracts: ignoreUnstartedContracts?.value || undefined,
    ignoreClosedContracts: ignoreClosedContracts?.value || undefined,
  };
};
