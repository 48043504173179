import { SelectionBox, Shape, ShapeType } from '../../model/types';

export const isShapeInSelection = (shape: Shape, selectionBox: SelectionBox): boolean => {
  if (shape.type === ShapeType.POLYGON || shape.type === ShapeType.LINE) {
    let isPolygonInSelection = false;

    for (let i = 0; i < shape.points.length; i++) {
      if (
        shape.points[i] >= selectionBox.x &&
        shape.points[i] <= selectionBox.x + selectionBox.width &&
        shape.points[i + 1] >= selectionBox.y &&
        shape.points[i + 1] <= selectionBox.y + selectionBox.height
      ) {
        isPolygonInSelection = true;
      }
    }

    return isPolygonInSelection;
  }

  return (
    shape.x >= selectionBox.x &&
    shape.x <= selectionBox.x + selectionBox.width &&
    shape.y >= selectionBox.y &&
    shape.y <= selectionBox.y + selectionBox.height
  );
};
