/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, memo, ReactNode, useCallback, useEffect, useState } from 'react';
import { GeolocationCoords } from 'app/types/common';
import { WarehouseForBooking } from 'entities/Warehouse';
import { ClippedWarehouseCard } from './ClippedWarehouseCard';
import { ExpandedWarehouseCard } from './ExpandedWarehouseCard';

interface WarehouseCardProps {
  warehouse: WarehouseForBooking;
  distance?: Nullable<number>;
  isOutlined: boolean;
  onSelectCard: (coords: GeolocationCoords) => void;
  onGetRoute: (coords: GeolocationCoords, warehouseId: string) => void;
  onClearRoute: () => void;
  onViewBoxes: () => void;
}

export const WarehouseCard: FC<WarehouseCardProps> = memo((props) => {
  const [cardMode, setCardMode] = useState<'clipped' | 'expanded'>('clipped');

  const expandCard = useCallback(() => {
    setCardMode('expanded');
  }, []);

  const clipCard = useCallback(() => {
    setCardMode('clipped');
  }, []);

  useEffect(() => {
    props.isOutlined ? expandCard() : clipCard();
  }, [clipCard, expandCard, props.isOutlined]);

  const renderCard = (): ReactNode => {
    switch (cardMode) {
      case 'clipped':
        return <ClippedWarehouseCard {...props} />;
      case 'expanded':
        return <ExpandedWarehouseCard clipCard={clipCard} {...props} />;
      default:
        return <ClippedWarehouseCard {...props} />;
    }
  };

  return <>{renderCard()}</>;
});
