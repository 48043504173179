import { api } from 'app/config/apiConfig';
import { Pagination } from 'app/types/common';
import { Contract } from '../model/types';

interface Params {
  page?: number;
  limit?: number;
  orderBy?: string;
  warehouseId?: string | string[];
  userId?: string;
  userName?: string;
  unitName?: string;
  contractNumber?: string;
  isActive?: boolean;
  isPaid?: boolean;
  isNotified?: boolean;
  creationDateFrom?: string;
  creationDateTo?: string;
  startDateFrom?: string;
  startDateTo?: string;
  closingDateFrom?: string;
  closingDateTo?: string;
  noticeLeaveDateFrom?: string;
  noticeLeaveDateTo?: string;
  overdueMonthsFrom?: number;
  overdueMonthsTo?: number;
}

export const getPaginatedContractsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPaginatedContracts: builder.query<Pagination<Contract>, Params>({
      query: (args) => ({
        url: '/contracts',
        params: args,
      }),
      providesTags: (result) => ['Contracts'],
    }),
  }),
});

export const { useGetPaginatedContractsQuery } = getPaginatedContractsApi;
