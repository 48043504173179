export enum InvoiceStatusFilter {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum ContractApplicability {
  APPLICABLE = 'applicable',
  INAPPLICABLE = 'inapplicable',
}

export enum LeadsListFilterKeys {
  selectedWarehouseId = 'selectedWarehouseId',
  isActive = 'isActive',
  leadStatuses = 'leadStatuses',
  squareFrom = 'squareFrom',
  squareTo = 'squareTo',
  creationDateFrom = 'creationDateFrom',
  creationDateTo = 'creationDateTo',
  firstContact = 'firstContact',
  isApplicable = 'isApplicable',
}

export enum UsersListFilterKeys {
  accountType = 'accountType',
  isInDebtCollection = 'isInDebtCollection',
  leadStatuses = 'leadStatuses',
  creationDateFrom = 'creationDateFrom',
  creationDateTo = 'creationDateTo',
}

export enum DebtCollectionFilter {
  IN_PROGRESS = 'in progress',
  NOT_STARTED = 'not started',
}
