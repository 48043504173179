import { api } from 'app/config/apiConfig';
import { Contract } from '../model/types';

export const changeContractApplicabilityApi = api.injectEndpoints({
  endpoints: (builder) => ({
    changeContractApplicability: builder.mutation<Contract, { contractId: string }>({
      query: (args) => ({
        url: `/contracts/${args.contractId}/change-applicability`,
        method: 'PUT',
      }),
      invalidatesTags: ['Contract details', 'Contracts'],
    }),
  }),
});

export const { useChangeContractApplicabilityMutation } = changeContractApplicabilityApi;
