import dayjs, { Dayjs } from 'dayjs';
import { Invoice, InvoiceStatus } from 'entities/Invoice';
import { roundNumber } from 'shared/utils/helpers/roundNumber';

interface InvoiceStatusResult {
  creditedAmount: number;
  paidAmount: number;
  status: InvoiceStatus;
}

export const calculateInvoiceStatus = (invoice: Invoice, date?: Nullable<Dayjs> | string | undefined): InvoiceStatusResult => {
  const result = {
    creditedAmount: 0,
    paidAmount: 0,
    status: InvoiceStatus.ACTIVE,
  };

  const today = dayjs().startOf('day');
  const dueDate = dayjs(invoice.dueDate).startOf('day');

  const refDate = date || today;

  const creditedAmount = roundNumber(
    invoice.creditNotes.reduce((acc, creditNote) => {
      const createdAt = dayjs(creditNote.createdAt).startOf('day');

      if (createdAt.isSame(refDate) || createdAt.isBefore(refDate)) {
        return acc + creditNote.creditedAmount;
      }

      return acc;
    }, 0),
  );

  const paidAmount = roundNumber(
    invoice.payments.reduce((total, payment) => {
      const paymentDate = dayjs(payment.createdAt).startOf('day');

      if (paymentDate.isAfter(refDate)) {
        return total;
      }

      if (payment.creditNote) {
        const creditNoteDate = dayjs(payment.creditNote.createdAt).startOf('day');

        return creditNoteDate.isAfter(refDate)
          ? total + payment.amount
          : total + roundNumber(payment.amount - payment.creditNote.creditedAmount);
      }

      return total + payment.amount;
    }, 0),
  );

  result.creditedAmount = creditedAmount;
  result.paidAmount = paidAmount;

  if (invoice.amount === roundNumber(creditedAmount + paidAmount)) {
    result.status = InvoiceStatus.INACTIVE;
  } else {
    result.status = dueDate.isAfter(refDate) ? InvoiceStatus.ACTIVE : InvoiceStatus.OVERDUE;
  }

  return result;
};
