import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DrawerMode, NoteDrawerSchema } from '../types';
import { Note } from 'entities/Note';
import { Lead } from 'entities/Lead';
import { User } from 'entities/User';

const initialState: NoteDrawerSchema = {
  note: null,
  lead: null,
  mode: null,
  user: null,
};

const noteDrawerSlice = createSlice({
  name: 'noteDrawer',
  initialState,
  reducers: {
    setOpenNoteDrawer: (
      state: NoteDrawerSchema,
      action: PayloadAction<{ mode: Nullable<DrawerMode>; note?: Nullable<Note>; lead?: Nullable<Lead>; user?: Nullable<User> }>,
    ) => {
      state.mode = action.payload.mode;
      state.note = action.payload.note;
      state.lead = action.payload.lead;
      state.user = action.payload.user;
    },
  },
});

export const { actions: noteDrawerActions, reducer: noteDrawerReducer } = noteDrawerSlice;
