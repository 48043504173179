import { Form } from 'antd';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useRole } from 'entities/User';
import { ChangeUserDebtDetails } from 'features/ChangeUserDebtDetails';
import { FC, memo, useEffect } from 'react';
import { Button } from 'shared/ui/Button';
import { Checkbox } from 'shared/ui/Checkbox';
import { Input } from 'shared/ui/Input';
import { TextArea } from 'shared/ui/TextArea';

interface DebtDetailsFormProps {
  userId: string;
  userDebt?: Nullable<number>;
  isInDebtCollection?: boolean;
  debtCollectionNote?: Nullable<string>;
  currencySymbol?: Nullable<string>;
}

export const DebtDetailsForm: FC<DebtDetailsFormProps> = memo((props) => {
  const { userId, isInDebtCollection, debtCollectionNote, userDebt, currencySymbol } = props;

  const { t } = useAppTranslation(['profile', 'common']);

  const { isManagement, isPartner } = useRole();

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      userDebt: `${userDebt} ${currencySymbol}`,
      isInDebtCollection,
      debtCollectionNote,
    });
  }, [form, userDebt, isInDebtCollection, debtCollectionNote, currencySymbol]);

  return (
    <div className="mt-10">
      <div className="mb-7 font-semibold text-2xl">{t('Debt details')}</div>
      <Form className="space-y-2" name="customerDetailsForm" form={form} layout="vertical" disabled={isPartner}>
        <div className="flex items-center space-x-7">
          <Form.Item className="w-full" name="userDebt">
            <Input bordered label={t('Debt')} disabled className="!text-error" />
          </Form.Item>
          <Form.Item name="isInDebtCollection" valuePropName="checked" className="w-full mt-7">
            <Checkbox defaultChecked name="isInDebtCollection">
              <span className="text-lg">{t('Is debt collection in progress?')}</span>
            </Checkbox>
          </Form.Item>
        </div>
        {isManagement && (
          <div className="flex items-center space-x-7">
            <Form.Item className="w-1/2" name="debtCollectionNote" label={t('Debt collection note')}>
              <TextArea bordered placeholder={t('Add your note...')} isDisabled rows={3} />
            </Form.Item>
            <ChangeUserDebtDetails
              userId={userId}
              formInstance={form}
              action={
                <Button containerClassName="basis-1/2" size="large" isDisabled={isPartner}>
                  {t('Change debt details')}
                </Button>
              }
            />
          </div>
        )}
      </Form>
    </div>
  );
});
