import React, { FC, memo, useMemo } from 'react';
import { useGetSizeCodesByWarehouseIdQuery } from 'entities/SizeCode';
import { Select } from 'shared/ui/Select';

interface SizeCodeSelectProps {
  warehouseId: string | undefined;
  value: Nullable<string> | undefined;
  onChange: (value: string) => void;
}

export const SizeCodeSelect: FC<SizeCodeSelectProps> = memo((props) => {
  const { warehouseId, value, onChange } = props;

  const { data } = useGetSizeCodesByWarehouseIdQuery(warehouseId, { skip: !warehouseId });

  const options = useMemo(() => {
    return data?.map(({ sizeCodeId, square }) => ({ label: `${square} m²`, value: sizeCodeId })) || [];
  }, [data]);

  return <Select className="w-full" size="extraSmall" bordered value={value} options={options} onChange={onChange} />;
});
