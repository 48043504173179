import React, { FC, ReactElement, memo, useCallback, useState } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { showNotification } from 'app/providers/NotificationsProvider';
import { UserPermissions } from 'entities/User';
import { useCheckPermission } from 'shared/utils/hooks/useCheckPermission';
import { Contract, useChangeContractApplicabilityMutation } from 'entities/Contract';
import { ConfirmModal } from 'shared/ui/ConfirmModal';

interface ChangeContractApplicableProps {
  contract: Contract;
  action: ReactElement;
}

export const ChangeContractApplicable: FC<ChangeContractApplicableProps> = memo((props) => {
  const { contract, action } = props;

  const [isOpenedModal, setOpenedModal] = useState(false);

  const isForbidden = useCheckPermission(UserPermissions.EDIT_UNITS);

  const { t } = useAppTranslation(['contracts', 'common']);

  const [changeApplicability, { isLoading }] = useChangeContractApplicabilityMutation();

  const openModal = useCallback((): void => {
    setOpenedModal(true);
  }, []);

  const closeModal = useCallback((): void => {
    setOpenedModal(false);
  }, []);

  const changeContractApplicability = useCallback(async (): Promise<void> => {
    try {
      if (contract.leaveDate) {
        await changeApplicability({ contractId: contract.contractId }).unwrap();
        showNotification('info', t('Success', { ns: 'common' }), t('Contract has been successfully changed'));
      }
    } catch (error: CustomAny) {
      showNotification('error', t('Error', { ns: 'common' }), t('Error when changing contract'));
    } finally {
      closeModal();
    }
  }, [contract.leaveDate, contract.contractId, changeApplicability, t, closeModal]);

  return (
    <>
      {React.cloneElement(action, { onClick: openModal })}

      <ConfirmModal
        isOpened={isOpenedModal}
        title={t('Change Contract Applicability')}
        description={t(
          `Are you sure you want to change contract applicability to ${contract.isApplicable ? 'inapplicable' : 'applicable'}?`,
        )}
        isLoading={isLoading}
        onOk={changeContractApplicability}
        onCancel={closeModal}
        isConfirmDisabled={isForbidden || !contract.leaveDate}
      />
    </>
  );
});
