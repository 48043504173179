import { useGetCurrencySymbol } from 'app/appState';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { WarehouseDiscount } from 'entities/Warehouse';
import { roundNumber } from 'shared/utils/helpers/roundNumber';
import { Box, CachedBox } from '../../model/types';
import { getBoxDiscountedPrice } from '../helpers/getBoxDiscountedPrice';

export const useGetBoxDiscountedPrice = ({
  warehouseDiscounts,
  boxInfo,
}: {
  warehouseDiscounts: Nullable<WarehouseDiscount[]> | undefined;
  boxInfo: Nullable<Box> | Nullable<CachedBox>;
}): string | undefined => {
  const { t } = useAppTranslation('booking');

  const currencySymbol = useGetCurrencySymbol();

  const isFirstMonthDiscount = warehouseDiscounts?.[0]?.discounts.includes('1-');

  if (!isFirstMonthDiscount) {
    return undefined;
  }

  const getRatePrice = (): number | undefined => {
    if (boxInfo?.monthRate) {
      return boxInfo.monthRate;
    }
    if (boxInfo?.weekRate) {
      return boxInfo.weekRate;
    }
    if (boxInfo?.dailyRate) {
      return boxInfo.dailyRate;
    }
  };

  const discountedPrice = getBoxDiscountedPrice({
    warehouseDiscounts,
    boxPrice: getRatePrice(),
    boxSquare: String(boxInfo?.sizeCode.square),
  });

  if (!discountedPrice) {
    return undefined;
  }

  if (boxInfo?.monthRate) {
    return `${roundNumber(discountedPrice)} ${currencySymbol}/${t('month')}`;
  }

  if (boxInfo?.weekRate) {
    return `${roundNumber(discountedPrice)} ${currencySymbol}/${t('week')}`;
  }

  if (boxInfo?.dailyRate) {
    return `${roundNumber(discountedPrice)} ${currencySymbol}/${t('day')}`;
  }
};
