import { Lead } from 'entities/Lead';
import { User } from 'entities/User';

export interface ChooseUserModalSchema {
  userType: Nullable<UserType>;
  selectedUser: Nullable<User | Lead>;
}

export enum UserType {
  LEAD = 'lead',
  USER = 'user',
}
