import React, { FC, memo, useLayoutEffect } from 'react';
import { useGetRentOptionsQuery } from 'entities/RentOption';
import { Slider } from 'features/Slider';
import { useScrollToElement } from 'shared/utils/hooks/useScrollToElement';
import { useScreenBreakpoints } from 'shared/utils/hooks/useScreenBreakpoints';
import { swipeArrayItems } from 'shared/utils/helpers/swipeArrayItems';
import { PromotionSlide } from './PromotionSlide';

interface PromotionContentProps {
  selectedWarehouseId: string | undefined;
}

export const PromotionContent: FC<PromotionContentProps> = memo((props) => {
  const { selectedWarehouseId } = props;

  const { scrollToElement, scrollRef } = useScrollToElement('center', 500);

  const { isDesktop } = useScreenBreakpoints();

  const { data } = useGetRentOptionsQuery({ warehouseId: selectedWarehouseId }, { skip: !selectedWarehouseId });

  const promoItemsToRender = data?.filter(({ showAsPromotion }) => showAsPromotion);

  useLayoutEffect(() => {
    scrollToElement();
  }, [scrollToElement]);

  const indexOfSpecialPromotion = promoItemsToRender?.findIndex(({ isSpecialPromotion }) => isSpecialPromotion);
  const specialPromotionOrderedArray = indexOfSpecialPromotion
    ? swipeArrayItems(promoItemsToRender, indexOfSpecialPromotion, 0)
    : promoItemsToRender;

  return (
    <div ref={scrollRef}>
      <Slider
        slidesPerView={isDesktop ? 2 : 1}
        spaceBetween={20}
        autoplay={{ delay: 4000, pauseOnMouseEnter: true }}
        slides={specialPromotionOrderedArray?.map(({ rentOptionId, label, description, accountTypeSetting, isSpecialPromotion }) => (
          <PromotionSlide
            key={rentOptionId}
            label={label}
            description={description}
            accountTypeSetting={accountTypeSetting}
            eventTheme={isSpecialPromotion ? 'valentinesDay' : undefined}
          />
        ))}
      />
    </div>
  );
});
