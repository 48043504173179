import classNames from 'classnames';
import React, { FC, memo } from 'react';

interface SettingsCardProps {
  imageUrl: string;
  label: string;
  description: string;
  onClick?: () => void;
}

export const SettingsCard: FC<SettingsCardProps> = memo((props) => {
  const { imageUrl, label, description, onClick } = props;

  return (
    <div className={classNames('max-w-[548px] p-7 bg-secondaryLight rounded-xl', { 'cursor-pointer': onClick })} onClick={onClick}>
      <img className="mb-7" src={imageUrl} alt={label} />
      <div className="mb-4 font-semibold text-2xl">{label}</div>
      <div className="font-normal">{description}</div>
    </div>
  );
});
