import React, { FC, memo, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { Drawer } from 'shared/ui/Drawer';
import { noteDrawerActions } from '../model/slice/noteDrawerSlice';
import { getNoteDrawerState } from '../model/selectors/getNoteDrawerState';
import { NoteForm } from './NoteForm';
import { chooseUserModalActions } from 'widgets/ChooseUserModal';

export const NoteDrawer: FC = memo(() => {
  const dispatch = useAppDispatch();

  const noteDrawerState = useAppSelector(getNoteDrawerState);

  const closeNoteDrawer = useCallback((): void => {
    dispatch(noteDrawerActions.setOpenNoteDrawer({ mode: null, note: null, lead: null, user: null }));
    dispatch(chooseUserModalActions.resetModalState());
  }, [dispatch]);

  return (
    <Drawer width={500} open={Boolean(noteDrawerState.mode)} onClose={closeNoteDrawer}>
      <NoteForm
        note={noteDrawerState?.note}
        lead={noteDrawerState?.lead}
        user={noteDrawerState?.user}
        mode={noteDrawerState.mode}
        onClose={closeNoteDrawer}
      />
    </Drawer>
  );
});
