export enum InvoiceTypeFilter {
  CONTRACT = 'contract',
  PERSONAL = 'personal',
}

export enum ContractApplicabilityFilter {
  APPLICABLE = 'applicable',
  INAPPLICABLE = 'inapplicable',
}

export enum InvoicesListFilterKeys {
  selectedWarehouses = 'selectedWarehouses',
  isActive = 'isActive',
  invoiceType = 'invoiceType',
  invoiceAmount = 'invoiceAmount',
  invoicingDateFrom = 'invoicingDateFrom',
  invoicingDateTo = 'invoicingDateTo',
  creationDateFrom = 'creationDateFrom',
  creationDateTo = 'creationDateTo',
  dueDateFrom = 'dueDateFrom',
  dueDateTo = 'dueDateTo',
  status = 'status',
  invoiceNumberFrom = 'invoiceNumberFrom',
  invoiceNumberTo = 'invoiceNumberTo',
  isApplicable = 'isApplicable',
  statusRefDate = 'statusRefDate',
}
