import React, { FC, memo, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { InputNumber } from 'shared/ui/InputNumber';
import { getGridCellSize } from '../../model/selectors/getGridCellSize';
import { warehouseMapConstructorActions } from '../../model/slice/warehouseMapConstructorSlice';
import { WarehouseMapTool } from '../../model/types';
import { ReactComponent as ArrowIcon } from 'shared/assets/icons/ArrowIcon.svg';
import { ReactComponent as PencilIcon } from 'shared/assets/icons/PencilIcon.svg';
import { ReactComponent as RectangleIcon } from 'shared/assets/icons/RectangleIcon.svg';
import { ReactComponent as LineIcon } from 'shared/assets/icons/LineIcon.svg';
import { ReactComponent as CircleIcon } from 'shared/assets/icons/CircleIcon.svg';
import { ReactComponent as GrabIcon } from 'shared/assets/icons/GrabIcon.svg';
import { ReactComponent as TextIcon } from 'shared/assets/icons/TextIcon.svg';
import { ReactComponent as PolygonIcon } from 'shared/assets/icons/PolygonIcon.svg';
import { Tool } from './Tool';

interface ToolBarProps {
  activeTool: WarehouseMapTool;
  onChange: (tool: WarehouseMapTool) => void;
}

export const ToolBar: FC<ToolBarProps> = memo((props) => {
  const { activeTool, onChange } = props;

  const dispatch = useAppDispatch();

  const gridCellSize = useAppSelector(getGridCellSize);

  const tools = [
    { id: WarehouseMapTool.POINTER, Icon: ArrowIcon },
    { id: WarehouseMapTool.GRAB, Icon: GrabIcon },
    {
      id: WarehouseMapTool.RECTANGLE,
      Icon: RectangleIcon,
      multiTools: [
        { id: WarehouseMapTool.LINE, Icon: LineIcon },
        { id: WarehouseMapTool.CIRCLE, Icon: CircleIcon },
        { id: WarehouseMapTool.POLYGON, Icon: PolygonIcon },
        { id: WarehouseMapTool.POLYGON_BOX, Icon: PolygonIcon },
      ],
    },
    { id: WarehouseMapTool.PENCIL, Icon: PencilIcon },
    { id: WarehouseMapTool.TEXT, Icon: TextIcon },
  ];

  const changeGridCellSize = useCallback(
    (value: Nullable<number>) => {
      value && dispatch(warehouseMapConstructorActions.changeGridCellSize(value));
    },
    [dispatch],
  );

  return (
    <div className="absolute z-10 bottom-4 left-1/2 -translate-x-1/2 flex space-x-3.5 items-center p-3 w-fit rounded-lg bg-white">
      {tools.map(({ id, Icon, multiTools }) => (
        <Tool key={id} id={id} Icon={Icon} activeTool={activeTool} multiTools={multiTools} onCLick={onChange} />
      ))}
      <InputNumber className="!w-14" bordered size="small" value={gridCellSize} onChange={changeGridCellSize} />
    </div>
  );
});
