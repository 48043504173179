import { Box } from 'entities/Box';
import { Warehouse } from 'entities/Warehouse';

export enum SizeGroup {
  XS = 'XS',
  S = 'S',
  M = 'M',
  L = 'L',
  XL = 'XL',
}

export enum SizeCodeType {
  STANDART_BOX = 'M2',
  CUBIC_BOX = 'M3',
}

export interface SizeCode {
  sizeCodeId: string;
  warehouseId: string;
  description?: string;
  extendedDescription?: string;
  square: number;
  monthRate?: number;
  weekRate?: number;
  dailyRate?: number;
  sizeGroup: SizeGroup;
  sizeCodeType: SizeCodeType;
  warehouse: Warehouse;
  imageUrl?: string;
  createdAt: string;
  updatedAt: string;
}

export interface SizeCodeForListing extends SizeCode {
  totalBoxesAmount: number;
  occupiedBoxesAmount: number;
  unavailableBoxesAmount: number;
  availableBoxesAmount: number;
  warehouseName: string;
  rentOptions: Nullable<string[]>;
}

export interface SizeCodeForBooking extends SizeCode {
  availableBoxesCount: number;
  minBoxPrice: number;
}

export interface GroupedSizeCode extends SizeCodeForBooking {
  minBoxSquare: Nullable<number>;
  maxBoxSquare: Nullable<number>;
}
