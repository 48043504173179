import { api } from 'app/config/apiConfig';
import { Pagination } from 'app/types/common';
import { UserAccountType, UserForListing } from '../model/types';

interface Params {
  page?: number;
  limit?: number;
  email?: string;
  search?: string;
  orderBy?: string;
  debt?: boolean;
  accountType?: UserAccountType;
  creationDateFrom?: string;
  creationDateTo?: string;
}

export const getPaginatedUsersListingApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPaginatedUsersListing: builder.query<Pagination<UserForListing>, Params | void>({
      query: (args) => ({
        url: '/users/listing',
        params: args ? { ...args } : undefined,
      }),
      providesTags: ['Users'],
    }),
  }),
});

export const { useGetPaginatedUsersListingQuery } = getPaginatedUsersListingApi;
