import { api } from 'app/config/apiConfig';
import { Pagination } from 'app/types/common';
import { User, UserRoles } from '../model/types';

interface Params {
  page?: number;
  limit?: number;
  email?: string;
  search?: string;
  extendWarehouses?: boolean;
  extendReservations?: boolean;
  extendContracts?: boolean;
  role?: UserRoles | UserRoles[];
  orderBy?: string;
}

export const getPaginatedUsersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPaginatedUsers: builder.query<Pagination<User>, Params | void>({
      query: (args) => ({
        url: '/users',
        params: args ? { ...args } : undefined,
      }),
    }),
  }),
});

export const { useGetPaginatedUsersQuery } = getPaginatedUsersApi;
