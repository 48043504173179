import { api } from 'app/config/apiConfig';
import { Box, CreateBoxParams } from '../model/types';

export const createBoxesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createBoxes: builder.mutation<Box[], CreateBoxParams[]>({
      query: (data) => ({
        url: '/boxes/batch',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Boxes', 'Warehouses for booking'],
    }),
  }),
});

export const { useCreateBoxesMutation } = createBoxesApi;
