import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { WarehouseMapConstructor, getSelectedFloorId, warehouseMapConstructorActions } from 'features/WarehouseMapConstructor';
import { Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetWarehouseByIdQuery } from 'entities/Warehouse';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { ReactComponent as BoxIcon } from 'shared/assets/icons/BoxTabIcon.svg';
import { ReactComponent as SizeCodeIcon } from 'shared/assets/icons/SizeCodeTabIcon.svg';
import { TabSwitcher } from 'shared/ui/TabSwitcher';
import { getLocalizedString } from 'shared/utils/helpers/JSONLocalization';

export const WarehouseConstructorPage: FC = memo(() => {
  const { warehouseId } = useParams();

  const { t } = useAppTranslation('warehouse-management');

  const dispatch = useAppDispatch();

  const [selectedTabKey, setSelectedTabKey] = useState('Map');

  const { data, refetch } = useGetWarehouseByIdQuery({ warehouseId, params: { floors: true } });

  const selectedFloorId = useAppSelector(getSelectedFloorId);

  const sortedByNameFloors = useMemo(
    () => data?.floors?.slice().sort((a, b) => (getLocalizedString(a.name) < getLocalizedString(b.name) ? -1 : 1)),
    [data?.floors],
  );

  const switchTab = useCallback((tabKey: string): void => {
    setSelectedTabKey(tabKey);
  }, []);

  useEffect(() => {
    if (sortedByNameFloors?.length) {
      dispatch(warehouseMapConstructorActions.changeFloor(sortedByNameFloors[0].floorId));
    }
  }, [dispatch, sortedByNameFloors]);

  const tabs = useMemo(
    () => [
      { key: 'Boxes', label: t('Boxes'), icon: <BoxIcon /> },
      { key: 'Map', label: t('Map'), icon: <BoxIcon /> },
      { key: 'Size codes', label: t('Size codes'), icon: <SizeCodeIcon /> },
      { key: 'Locks controller', label: t('Locks controller'), icon: <SizeCodeIcon /> },
    ],
    [t],
  );

  const renderTabContent = useCallback(() => {
    switch (selectedTabKey) {
      case 'Boxes':
        return null;
      case 'Map':
        return (
          <>
            {sortedByNameFloors?.map((floor) =>
              floor.floorId === selectedFloorId ? (
                <WarehouseMapConstructor
                  key={floor.floorId}
                  floors={sortedByNameFloors}
                  selectedFloor={floor}
                  warehouseId={warehouseId}
                  shapesConfig={floor.shapesConfig}
                  refetchFloors={refetch}
                />
              ) : null,
            )}
          </>
        );
      case 'Size codes':
        return null;
      case 'Locks controller':
        return null;
      default:
        return null;
    }
  }, [refetch, selectedFloorId, selectedTabKey, sortedByNameFloors, warehouseId]);

  return (
    <div className="mt-7">
      <TabSwitcher tabs={tabs} selectedTabKey={selectedTabKey} onChangeTab={switchTab}>
        <div className="mb-7">
          <Trans
            t={t}
            i18nKey="<0>Warehouse management</0> <1>Frasnacht</1>"
            components={[
              <span key="0" className="text-3xl font-semibold" />,
              <span key="1" className="text-3xl font-semibold text-accent" />,
            ]}
            values={{ name: data?.name }}
          />
        </div>
        {renderTabContent()}
      </TabSwitcher>
    </div>
  );
});
