import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'entities/User';
import { ChooseUserModalSchema, UserType } from '../types';
import { Lead } from 'entities/Lead';

const initialState: ChooseUserModalSchema = {
  userType: null,
  selectedUser: null,
};

const chooseUserModalSlice = createSlice({
  name: 'chooseUserModal',
  initialState,
  reducers: {
    setUserType: (state: ChooseUserModalSchema, action: PayloadAction<Nullable<UserType>>) => {
      state.userType = action.payload;
    },
    setSelectedUser: (state: ChooseUserModalSchema, action: PayloadAction<Nullable<User | Lead>>) => {
      state.selectedUser = action.payload;
    },

    resetModalState: (state: ChooseUserModalSchema) => {
      state.selectedUser = null;
      state.userType = null;
    },
  },
});

export const { actions: chooseUserModalActions, reducer: chooseUserModalReducer } = chooseUserModalSlice;
