import classNames from 'classnames';
import React, { FC, memo } from 'react';
import { Outlet } from 'react-router-dom';
import { BookingHeader } from 'widgets/BookingHeader';
import { SpecialPromotionOpener, useSpecialPromotionModal } from 'features/SpecialPromotion';
import { useScreenBreakpoints } from 'shared/utils/hooks/useScreenBreakpoints';

export const BookingLayout: FC = memo(() => {
  const { showSpecialPromotion } = useSpecialPromotionModal();

  const { isDesktop } = useScreenBreakpoints();
  const marginAmount = isDesktop ? 'mt-16' : 'mt-24';

  return (
    <div className={classNames({ [marginAmount]: showSpecialPromotion })}>
      <div className="wrapper">
        <SpecialPromotionOpener eventTheme="valentinesDay" />
        <BookingHeader />
        <main className="mb-16">
          <Outlet />
        </main>
      </div>
    </div>
  );
});
