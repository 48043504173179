import { api } from 'app/config/apiConfig';
import { Pagination } from 'app/types/common';
import { Lead, LeadStatus } from '../model/types';

interface Params {
  page?: number;
  limit?: number;
  orderBy?: string;
  warehouseId?: string;
  isContractPaid?: boolean;
  search?: string;
  isLeft?: boolean;
  userId?: string;
  contractId?: string;
  reservationId?: string;
  squareFrom?: number;
  squareTo?: number;
  leadStatuses: LeadStatus;
  creationDateFrom?: string;
  creationDateTo?: string;
  isApplicable?: boolean;
}

export const getPaginatedLeadsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPaginatedLeads: build.query<Pagination<Lead>, Params | void>({
      query: (args) => ({
        url: '/leads',
        params: args ? { ...args } : undefined,
      }),
      providesTags: ['Leads'],
    }),
  }),
});

export const { useGetPaginatedLeadsQuery } = getPaginatedLeadsApi;
