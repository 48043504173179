import jwt_decode from 'jwt-decode';
import { JWTUser, User } from '../../model/types';

export const ACCESS_TOKEN_KEY = 'buss-user';
export const REFRESH_TOKEN_KEY = 'buss-refresh';

export const setAccessToken = (token: string, remember = false): void => {
  remember ? localStorage.setItem(ACCESS_TOKEN_KEY, token) : sessionStorage.setItem(ACCESS_TOKEN_KEY, token);
};

export const setRefreshToken = (data: string, remember = false): void => {
  remember && localStorage.setItem(REFRESH_TOKEN_KEY, data);
};

export const updateAccessToken = (data: string): void => {
  localStorage.getItem(ACCESS_TOKEN_KEY) ? localStorage.setItem(ACCESS_TOKEN_KEY, data) : sessionStorage.setItem(ACCESS_TOKEN_KEY, data);
};

export const clearAccessToken = (): void => {
  sessionStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(ACCESS_TOKEN_KEY);
};

export const clearRefreshToken = (): void => {
  localStorage.removeItem(REFRESH_TOKEN_KEY);
};

export const getAccessToken = (): Nullable<string> => {
  return localStorage.getItem(ACCESS_TOKEN_KEY) || sessionStorage.getItem(ACCESS_TOKEN_KEY);
};

export const getRefreshToken = (): Nullable<string> => {
  return localStorage.getItem(REFRESH_TOKEN_KEY);
};

export const getEmailFromToken = (token?: string): Nullable<string> => {
  const authToken = token || getAccessToken();

  return authToken ? jwt_decode<AnyObject>(authToken).email : null;
};

export const getInfoFromToken = (token?: string): Nullable<JWTUser> => {
  const authToken = token || getAccessToken();

  return authToken ? jwt_decode<JWTUser>(authToken) : null;
};

export const isAuthKeyEmpty = (): boolean => !getAccessToken();

export const getRememberMeState = (): boolean => {
  return Boolean(localStorage.getItem(ACCESS_TOKEN_KEY));
};
