import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { appActions, useGetGlobalSettingsQuery } from 'app/appState';
import { isAuthKeyEmpty, useGetMeQuery, userActions, getCredentials } from 'entities/User';
import { useSocketConnect } from 'app/socketConnection';
import { useSpecialPromotionModal } from 'features/SpecialPromotion';
import { useInitGoogleClickId } from 'features/GoogleClickId';
import { ValentinesDayPromotionContent } from 'widgets/ValentinesDayPromotion';

export const useInitializeApp = (): void => {
  const dispatch = useAppDispatch();

  const userHasToken = !isAuthKeyEmpty();

  const { data: userData, refetch: refetchMe } = useGetMeQuery(undefined, { skip: !userHasToken });
  const { data: globalSettings } = useGetGlobalSettingsQuery();

  const credentials = useAppSelector(getCredentials);

  useSocketConnect();

  useInitGoogleClickId();

  const { initSpecialPromotionModal } = useSpecialPromotionModal();

  // refetch user data when tokens have been updated
  useEffect(() => {
    void (credentials && refetchMe());
  }, [credentials, refetchMe]);

  useEffect(() => {
    userData && dispatch(userActions.setUserData(userData));
    globalSettings && dispatch(appActions.setGlobalSettings(globalSettings));
  }, [userData, dispatch, globalSettings]);

  useEffect(() => {
    initSpecialPromotionModal(ValentinesDayPromotionContent);
  }, [initSpecialPromotionModal]);
};
