import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import classNames from 'classnames';
import React, { FC, memo, useCallback } from 'react';
import { getGridCellSize } from '../../../model/selectors/getGridCellSize';
import { getShapes } from '../../../model/selectors/getShapes';
import { warehouseMapConstructorActions } from '../../../model/slice/warehouseMapConstructorSlice';
import { PickedBoxParams, ShapeType } from '../../../model/types';
import { usePickerBoxes } from '../../../utils/hooks/usePickerBoxes';

interface BoxesListProps {
  sizeCodeId: string;
  warehouseId: string | undefined;
  floorId: string;
}

export const BoxesList: FC<BoxesListProps> = memo((props) => {
  const { sizeCodeId, warehouseId, floorId } = props;

  const dispatch = useAppDispatch();

  const { sizeCodeBoxes, hideBoxFromPicker, returnBoxToPicker } = usePickerBoxes({ warehouseId, sizeCodeId });

  const shapes = useAppSelector(getShapes);
  const gridCellSize = useAppSelector(getGridCellSize);

  const unpickedBoxes = sizeCodeBoxes.filter((box) => !shapes.some((shape) => shape.id === box.boxId));

  const pickBox = useCallback(
    ({ id, width, length, label, onDelete }: PickedBoxParams) => {
      dispatch(
        warehouseMapConstructorActions.appendShape({
          id,
          floorId,
          type: ShapeType.RECTANGLE,
          fill: '#F3F6F9',
          stroke: '#788593',
          width: width * gridCellSize,
          height: length * gridCellSize,
          x: 100,
          y: 100,
          strokeWidth: 1,
          cornerRadius: 5,
          scaleX: 1,
          scaleY: 1,
          label,
          onDelete,
        }),
      );
      hideBoxFromPicker(id);
    },
    [dispatch, floorId, gridCellSize, hideBoxFromPicker],
  );

  return (
    <div className="flex flex-wrap gap-3 p-3.5 border border-secondaryAccent rounded-lg">
      {unpickedBoxes?.map(({ boxId, width, length, name, isHidden }) => (
        <div
          key={boxId}
          style={{ width: gridCellSize * width, height: gridCellSize * length }}
          className={classNames(
            'flex flex-col justify-center items-center bg-secondaryAccent border border-primaryLight rounded-lg [&>div]:text-xs cursor-pointer',
            { hidden: isHidden },
          )}
          onClick={() => {
            pickBox({
              width,
              length,
              label: name,
              id: boxId,
              onDelete: () => {
                dispatch(warehouseMapConstructorActions.deleteShape());
                returnBoxToPicker(boxId);
              },
            });
          }}
        >
          <div>{name}</div>
          <div>
            {width}x{length}
          </div>
        </div>
      ))}
    </div>
  );
});
