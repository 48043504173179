import { api } from 'app/config/apiConfig';
import { Pagination } from 'app/types/common';
import { Deposit, DepositPaymentStatus } from '../model/types';

interface Params {
  warehouseId?: string;
  contractId?: string;
  search?: string;
  userId?: string;
  depositAmount?: number;
  orderBy?: string;
  creationDateFrom?: string;
  creationDateTo?: string;
  statusRefDate?: string;
  status?: DepositPaymentStatus;
  startDateFrom?: string;
  startDateTo?: string;
}

export const getPaginatedDepositsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPaginatedDeposits: build.query<Pagination<Deposit>, Params | void>({
      query: (args) => ({
        url: '/billing-documents/deposits',
        params: args ? { ...args } : undefined,
      }),
    }),
  }),
});

export const { useGetPaginatedDepositsQuery } = getPaginatedDepositsApi;
