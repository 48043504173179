import { WarehouseDiscount } from 'entities/Warehouse';

export const getBoxDiscountedPrice = ({
  warehouseDiscounts,
  boxPrice,
  boxSquare,
}: {
  warehouseDiscounts: Nullable<WarehouseDiscount[]> | undefined;
  boxPrice: Nullable<number> | undefined;
  boxSquare?: string;
}): Nullable<number> => {
  let actualDiscount = warehouseDiscounts?.[0];

  if (boxSquare) {
    actualDiscount = warehouseDiscounts?.find((discount) => {
      const unitSizes = discount.unitSizes?.split(',');

      return unitSizes?.includes(boxSquare) || discount.unitSizes === null;
    });
  }

  const firstMonthDiscountValue = actualDiscount?.discounts.split(',')[0].split('-')[1]; // get discount value from array ["1-12%,2-12%", "1-10%,2-20%"]

  const isPercentageDiscount = firstMonthDiscountValue?.includes('%');

  if (firstMonthDiscountValue && boxPrice) {
    const discountValue = isPercentageDiscount
      ? Number(firstMonthDiscountValue.slice(0, firstMonthDiscountValue.indexOf('%')))
      : Number(firstMonthDiscountValue);

    return isPercentageDiscount ? boxPrice - (boxPrice * discountValue) / 100 : discountValue;
  }

  return null;
};
