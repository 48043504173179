import React, { FC, memo, useCallback } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { TableFilter, useTableFilterContext } from 'features/TableFilter';
import { DebtCollectionSelect } from './DebtCollectionSelect';
import { RangeDatePicker } from 'shared/ui/RangeDatePicker/RangeDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { CLIENT_DATE_FORMAT } from 'shared/utils/constants';
import { UsersListFilterKeys } from '../../model/types';
import { AccountTypeSelect } from './AccountTypeSelect';

export const UsersListFilter: FC = memo(() => {
  const { t } = useAppTranslation(['contracts', 'user']);

  const { filters, changeFilters, clearCurrentFilters } = useTableFilterContext<UsersListFilterKeys>();

  const changeCreationDateFrom = useCallback(
    (value: Nullable<Dayjs>) => {
      const clientFormatDateFrom = dayjs(value).startOf('day').format(CLIENT_DATE_FORMAT);

      changeFilters({
        key: UsersListFilterKeys.creationDateFrom,
        label: t('From {{creationDateFrom}}', { creationDateFrom: clientFormatDateFrom }),
        value,
        title: t('Creation date'),
      });
    },
    [changeFilters, t],
  );

  const changeCreationDateTo = useCallback(
    (value: Nullable<Dayjs>) => {
      const clientFormatDateTo = dayjs(value).startOf('day').format(CLIENT_DATE_FORMAT);

      changeFilters({
        key: UsersListFilterKeys.creationDateTo,
        label: t('To {{creationDateTo}}', { creationDateTo: clientFormatDateTo }),
        value,
        title: t('Creation date'),
      });
    },
    [changeFilters, t],
  );

  const changeDebtCollectionStatus = useCallback(
    (value: string, label: string) => {
      changeFilters({ key: UsersListFilterKeys.isInDebtCollection, label, value, title: t('Debt collection', { ns: 'user' }) });
    },
    [changeFilters, t],
  );

  const changeAccountType = useCallback(
    (value: string) => {
      changeFilters({
        key: UsersListFilterKeys.accountType,
        label: t(value),
        value,
        title: t('Account type', { ns: 'user' }),
      });
    },
    [changeFilters, t],
  );

  return (
    <TableFilter>
      <div className="space-y-2">
        <AccountTypeSelect value={filters.accountType?.value} onChange={changeAccountType} onClear={clearCurrentFilters} />
        <RangeDatePicker
          placeholder={[t('From'), t('To')]}
          label={t('Creation range')}
          onChangeFrom={changeCreationDateFrom}
          onChangeTo={changeCreationDateTo}
          values={[
            filters.creationDateFrom?.value && dayjs(filters.creationDateFrom?.value),
            filters.creationDateTo?.value && dayjs(filters.creationDateTo?.value),
          ]}
        />
        <DebtCollectionSelect
          value={filters.isInDebtCollection?.value}
          onChange={changeDebtCollectionStatus}
          onClear={clearCurrentFilters}
        />
      </div>
    </TableFilter>
  );
});
