import { useCallback, useEffect, useState } from 'react';
import { Box, useGetWarehouseBoxesQuery } from 'entities/Box';
import { useAppSelector } from 'app/config/storeConfig/hooks';
import { getSelectedFloorId } from '../../model/selectors/getSelectedFloorId';
import { PickerBox } from '../../model/types';

interface HookApi {
  sizeCodeBoxes: PickerBox[];
  hideBoxFromPicker: (boxId: string) => void;
  returnBoxToPicker: (boxId: string | undefined) => void;
}

interface HookArgs {
  warehouseId: string | undefined;
  sizeCodeId: string | undefined;
}

export const usePickerBoxes = ({ warehouseId, sizeCodeId }: HookArgs): HookApi => {
  const [allBoxes, setAllBoxes] = useState<Box[]>([]);
  const [sizeCodeBoxes, setSizeCodeBoxes] = useState<PickerBox[]>([]);

  const selectedFloorId = useAppSelector(getSelectedFloorId);

  const { data } = useGetWarehouseBoxesQuery(
    { warehouseId, params: { floorId: selectedFloorId || undefined } },
    { skip: !warehouseId || !selectedFloorId },
  );

  useEffect(() => {
    if (data) {
      setAllBoxes(data);
    }
  }, [data, sizeCodeId]);

  useEffect(() => {
    if (allBoxes.length) {
      setSizeCodeBoxes(allBoxes.filter(({ sizeCode }) => sizeCode.sizeCodeId === sizeCodeId).map((box) => ({ ...box, isHidden: false })));
    }
  }, [allBoxes, sizeCodeId]);

  const hideBoxFromPicker = useCallback((boxId: string) => {
    setAllBoxes((prevState) => prevState.map((box) => (box.boxId === boxId ? { ...box, isHidden: true } : box)));
  }, []);

  const returnBoxToPicker = useCallback((boxId: string | undefined) => {
    setAllBoxes((prevState) => prevState.map((box) => (box.boxId === boxId ? { ...box, isHidden: false } : box)));
  }, []);

  return {
    sizeCodeBoxes,
    hideBoxFromPicker,
    returnBoxToPicker,
  };
};
