import { api } from 'app/config/apiConfig';
import { Box } from '../model/types';

interface Params {
  sizeCodeId?: string;
  floorId?: string;
}

export const getWarehouseBoxesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getWarehouseBoxes: build.query<Box[], { warehouseId: string | undefined; params?: Params }>({
      query: ({ warehouseId, params }) => ({
        url: `/warehouses/${warehouseId}/boxes`,
        params,
      }),
      providesTags: ['Boxes'],
    }),
  }),
});

export const { useGetWarehouseBoxesQuery } = getWarehouseBoxesApi;
