import { api } from 'app/config/apiConfig';
import { Pagination } from 'app/types/common';
import { Invoice, InvoiceStatus } from '../model/types';

interface Params {
  page?: number;
  limit?: number;
  orderBy?: string;
  userId?: string;
  isActive?: boolean;
  warehouseId?: string;
  contractId?: string;
  invoiceAmount?: number;
  invoiceEmail?: string;
  invoiceAddress?: string;
  issuedBy?: string | null;
  personalInvoices?: boolean;
  deposits?: boolean;
  search?: string;
  invoicingDateFrom?: string;
  invoicingDateTo?: string;
  creationDateFrom?: string;
  creationDateTo?: string;
  dueDateFrom?: string;
  dueDateTo?: string;
  invoiceNumberFrom?: string;
  invoiceNumberTo?: string;
  status?: InvoiceStatus;
  statusRefDate?: string;
}

export const getPaginatedInvoicesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPaginatedInvoices: build.query<Pagination<Invoice>, Params | void>({
      query: (args) => ({
        url: '/billing-documents/invoices',
        params: args ? { ...args } : undefined,
      }),
      providesTags: ['Invoices'],
    }),
  }),
});

export const { useGetPaginatedInvoicesQuery } = getPaginatedInvoicesApi;
