import { DepositPaymentStatus } from 'entities/Deposit';
import { TableFilterType } from 'features/TableFilter';
import { getFilterServerFormatDate } from 'shared/utils/helpers/getDateFormat';

interface DepositsListFilters {
  warehouseId?: string;
  creationDateFrom?: string;
  creationDateTo?: string;
  status?: DepositPaymentStatus;
  isApplicable?: boolean;
  statusRefDate?: string;
  startDateFrom?: string;
  startDateTo?: string;
}

export const transformDepositsListFilters = ({
  selectedWarehouses,
  creationDateFrom,
  creationDateTo,
  status,
  isApplicable,
  statusRefDate,
  startDateFrom,
  startDateTo,
}: TableFilterType): DepositsListFilters => {
  return {
    warehouseId: selectedWarehouses?.value || undefined,
    creationDateFrom: getFilterServerFormatDate(creationDateFrom?.value),
    creationDateTo: getFilterServerFormatDate(creationDateTo?.value),
    status: status?.value || undefined,
    isApplicable: isApplicable?.value === undefined ? undefined : isApplicable?.value === 'applicable',
    statusRefDate: getFilterServerFormatDate(statusRefDate?.value),
    startDateFrom: getFilterServerFormatDate(startDateFrom?.value),
    startDateTo: getFilterServerFormatDate(startDateTo?.value),
  };
};
