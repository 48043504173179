import { ColumnsType } from 'antd/es/table';
import React, { FC, memo, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ServerTable } from 'shared/ui/ServerTable';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { AppRoutes } from 'app/config/routerConfig/types';
import { ContractDetailsDrawer, contractDetailsDrawerActions } from 'widgets/ContractDetailsDrawer';
import { ReactComponent as ContractsTableIcon } from 'shared/assets/icons/ContractsTableIcon.svg';
import { ReactComponent as FiltersIcon } from 'shared/assets/icons/FiltersIcon.svg';
import { useAppDispatch } from 'app/config/storeConfig/hooks';
import { useGetCurrencySymbol } from 'app/appState';
import { SizeCodeType } from 'entities/SizeCode';
import { ExportEntity, ExportModal, getBoxesOccupancyColumnKeys, useOpenExportModal } from 'features/ExportModal';
import { ReactComponent as ExportIcon } from 'shared/assets/icons/ExportIcon.svg';
import { Paragraph } from 'shared/ui/Paragraph';
import { BoxOccupancyObject, useGetWarehousesBoxesOccupancyQuery } from 'entities/Warehouse';
import { useTableFilterContext } from 'features/TableFilter';
import dayjs from 'dayjs';
import { ServerTableActions } from 'shared/ui/ServerTable/types';
import { transformBoxesOccupancyFilters } from '../utils/transformBoxesOccupancyFilters';
import { BoxesOccupancyFilter } from './BoxesOccupancyFilter';
import { roundNumber } from 'shared/utils/helpers/roundNumber';
import { InvoiceFrequencyType } from 'entities/Invoice';
import { getClientDateFormat } from 'shared/utils/helpers/getDateFormat';

export const BoxesOccupancyTable: FC = memo(() => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const currencySymbol = useGetCurrencySymbol();

  const { openExportModal } = useOpenExportModal();

  const { t } = useAppTranslation(['contracts', 'common']);

  const { setFiltersOpened, appliedFilters, tags, clearAllFilters } = useTableFilterContext();

  const statusRefDate = useMemo(() => {
    const today = dayjs().startOf('day');

    return appliedFilters.statusRefDate?.value ? dayjs(appliedFilters.statusRefDate.value).startOf('day') : today;
  }, [appliedFilters.statusRefDate]);

  const goToUserContracts = useCallback(
    (userId: string): void => {
      navigate(`${AppRoutes.USERS}/${userId}/contracts`);
    },
    [navigate],
  );

  const openContractDetails = useCallback(
    (contractId: string): void => {
      dispatch(contractDetailsDrawerActions.setOpenContractDetails(contractId));
    },
    [dispatch],
  );

  const columns = useMemo<ColumnsType<BoxOccupancyObject>>(
    () => [
      {
        title: t('Box'),
        width: '7%',
        key: 'name',
        render: (_, record) => <div className="font-semibold">{record.boxName}</div>,
      },
      {
        title: t('Square'),
        width: '10%',
        key: 'square',
        render: (_, record) => (
          <div className="flex flex-col">
            <div className="text-sm">{record.warehouseName}</div>
            <div className="font-bold">
              {record.square}m<sup className="font-bold">{record.sizeCodeType === SizeCodeType.CUBIC_BOX ? 3 : 2}</sup>
            </div>
          </div>
        ),
      },
      {
        title: t('Customer'),
        key: 'user',
        width: '15%',
        render: (_, record) => (
          <div>
            <div className="flex gap-2 text-primary font-normal">
              {record.userId && (
                <div>
                  <div className="flex gap-2 text-primary font-normal">
                    {record.email}
                    <ContractsTableIcon
                      className="cursor-pointer"
                      onClick={() => {
                        goToUserContracts(record.userId);
                      }}
                    />
                  </div>
                  {record.contractId && (
                    <div className="text-primary font-normal">
                      {t('Contract')}{' '}
                      <span
                        className="text-accent underline cursor-pointer"
                        onClick={() => {
                          openContractDetails(record.contractId);
                        }}
                      >
                        {record.contractNumber}
                      </span>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        ),
      },
      {
        title: t('Box current price ({{statusRefDate}})', { statusRefDate: getClientDateFormat(statusRefDate) }),
        width: '10%',
        key: 'monthRate',
        render: (_, record) => (
          <div className="text-primary">
            {record.boxCurrentMonthRate && (
              <div>
                {record.boxCurrentMonthRate} {currencySymbol}
                <span className="text-sm text-primaryLight">/{t('Month')}</span>
              </div>
            )}
            {record.boxCurrentWeekRate && (
              <div>
                {record.boxCurrentWeekRate} {currencySymbol}
                <span className="text-sm text-primaryLight">/{t('Week')}</span>
              </div>
            )}
            {record.boxCurrentDailyRate && (
              <div>
                {record.boxCurrentDailyRate} {currencySymbol}
                <span className="text-sm text-primaryLight">/{t('Day')}</span>
              </div>
            )}
          </div>
        ),
      },
      {
        title: t('Contract current ({{statusRefDate}})', { statusRefDate: getClientDateFormat(statusRefDate) }),
        width: '10%',
        key: 'monthRate',
        render: (_, record) => (
          <div className="text-primary">
            {record.contractCurrentPrice && (
              <div>
                {record.contractCurrentPrice} {currencySymbol}
                <span className="text-sm text-primaryLight">/{t(record.rentType)}</span>
              </div>
            )}
          </div>
        ),
      },
      {
        title: t('Contract initial'),
        width: '10%',
        key: 'monthRate',
        render: (_, record) => (
          <div className="text-primary">
            {record.contractInitialPrice && (
              <div>
                {record.contractInitialPrice} {currencySymbol}
                <span className="text-sm text-primaryLight">/{t(t(record.rentType))}</span>
              </div>
            )}
          </div>
        ),
      },
      {
        title: t('Rent difference'),
        width: '10%',
        key: 'rentDiff',
        render: (_, record) => {
          const diffPercent = record.contractInitialPrice
            ? roundNumber(((record.contractCurrentPrice - record.contractInitialPrice) / record.contractInitialPrice) * 100)
            : 0;

          return (
            <div className="text-primary">
              <div className="text-primaryLight">{diffPercent} %</div>
            </div>
          );
        },
      },
      {
        title: t('Box / contract diff'),
        width: '10%',
        key: 'boxContractDiff',
        render: (_, record) => {
          const prices: Record<InvoiceFrequencyType, 'boxCurrentDailyRate' | 'boxCurrentWeekRate' | 'boxCurrentMonthRate'> = {
            [InvoiceFrequencyType.DAY]: 'boxCurrentDailyRate',
            [InvoiceFrequencyType.WEEK]: 'boxCurrentWeekRate',
            [InvoiceFrequencyType.MONTH]: 'boxCurrentMonthRate',
          };

          const diffPercent = record.contractInitialPrice
            ? roundNumber(((record[prices[record.rentType]] - record.contractCurrentPrice) / record.contractCurrentPrice) * 100)
            : 0;

          return (
            <div className="text-primary">
              <div className="text-primaryLight">{diffPercent} %</div>
            </div>
          );
        },
      },
      {
        title: t('Discounts'),
        key: 'discounts',
        width: '20%',
        render: (_, record) => (
          <Paragraph rows={2} className="text-primaryLight font-normal">
            {record.discounts.map((discount, index: number) => (
              <div key={Date.now() + index} className="text-primaryLight">
                {discount}
              </div>
            ))}
          </Paragraph>
        ),
      },
    ],
    [t, statusRefDate, goToUserContracts, openContractDetails, currencySymbol],
  );

  const tableActions: Array<ServerTableActions<BoxOccupancyObject>> = useMemo(
    () => [
      {
        name: t('Export', { ns: 'common' }),
        theme: 'secondary',
        icon: <ExportIcon />,
        iconPosition: 'prev',
        onClick: () => {
          openExportModal({
            filters: transformBoxesOccupancyFilters(appliedFilters),
            columns: getBoxesOccupancyColumnKeys(t),
            entity: ExportEntity.OCCUPANCY_BOX,
          });
        },
      },
      {
        name: t('Filters', { ns: 'common' }),
        icon: <FiltersIcon />,
        theme: 'secondary',
        iconPosition: 'prev',
        onClick: () => {
          setFiltersOpened(true);
        },
      },
    ],
    [appliedFilters, openExportModal, setFiltersOpened, t],
  );

  return (
    <div className="pt-4">
      <div className="font-semibold text-3xl mb-7">{t('Statistics')}</div>
      <ServerTable
        columns={columns}
        fetchData={useGetWarehousesBoxesOccupancyQuery}
        rowKey="boxId"
        tableActionsPosition="prev"
        tableActions={tableActions}
        tags={tags}
        onAllFiltersClear={clearAllFilters}
        defaultFilters={transformBoxesOccupancyFilters(appliedFilters)}
      />
      <ContractDetailsDrawer />
      <ExportModal />
      <BoxesOccupancyFilter />
    </div>
  );
});
