import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useAppDispatch } from 'app/config/storeConfig/hooks';
import { useGetWarehousesForBookingQuery, WarehouseForBooking } from 'entities/Warehouse';
import { Slider } from 'features/Slider';
import { FC, memo, useCallback, useMemo } from 'react';
import { roundNumber } from 'shared/utils/helpers/roundNumber';
import { ReactComponent as InfoIcon } from 'shared/assets/icons/InfoIcon.svg';
import { useGeolocation } from 'shared/utils/hooks/useGeolocation';
import { useScreenBreakpoints } from 'shared/utils/hooks/useScreenBreakpoints';
import { calculateDistance, warehouseMapActions } from 'widgets/SelectWarehouseMap';
import { ClippedWarehouseCard } from 'widgets/SelectWarehouseMap/ui/WarehouseCard/ClippedWarehouseCard';

interface NearestWarehousesListProps {
  selectedWarehouseId: string | undefined;
  onSelectWarehouse: (warehouse: WarehouseForBooking) => void;
}

export const NearestWarehousesList: FC<NearestWarehousesListProps> = memo((props) => {
  const { selectedWarehouseId, onSelectWarehouse } = props;

  const { t } = useAppTranslation('booking');

  const { isDesktop } = useScreenBreakpoints();
  const dispatch = useAppDispatch();
  const userCoords = useGeolocation();

  const { data } = useGetWarehousesForBookingQuery();

  const warehousesWithCoordinates = useMemo(
    () =>
      data
        ?.filter((warehouse) => warehouse.longitude && warehouse.latitude)
        .filter((warehouse) => selectedWarehouseId !== warehouse.warehouseId)
        .sort((a, b) => {
          if (userCoords) {
            const distanceA = calculateDistance(userCoords.lat, userCoords.lng, a.latitude, a.longitude);
            const distanceB = calculateDistance(userCoords.lat, userCoords.lng, b.latitude, b.longitude);

            return distanceA - distanceB;
          }

          return 0;
        }),
    [data, selectedWarehouseId, userCoords],
  );

  const handleViewBoxes = useCallback(
    (warehouse: WarehouseForBooking): void => {
      dispatch(warehouseMapActions.setSelectedCard(warehouse.warehouseId));
      onSelectWarehouse(warehouse);
    },
    [dispatch, onSelectWarehouse],
  );

  return (
    <div className="bg-secondaryLight rounded-lg">
      {userCoords ? (
        <div>
          <Slider
            slidesPerView={isDesktop ? 2 : 1}
            spaceBetween={15}
            slides={warehousesWithCoordinates?.map((warehouse) => {
              const distance = calculateDistance(userCoords.lat, userCoords.lng, warehouse.latitude, warehouse.longitude);

              return (
                <div key={warehouse.warehouseId} className="w-max-[500px] shrink-0 p-3">
                  <ClippedWarehouseCard
                    warehouse={warehouse}
                    isOutlined={true}
                    distance={roundNumber(distance)}
                    onSelectCard={() => {
                      handleViewBoxes(warehouse);
                    }}
                  />
                </div>
              );
            })}
          />
        </div>
      ) : (
        <div className="h-[60px] flex items-center justify-center">
          <InfoIcon width={30} height="100%" className="stroke-warn mr-2" />
          {t('Please enable geolocation access to get warehouses near you')}
        </div>
      )}
    </div>
  );
});
