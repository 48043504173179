import React, { FC, memo, useCallback } from 'react';
import { WarehouseForBooking } from 'entities/Warehouse';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { GeolocationCoords } from 'app/types/common';
import { ReactComponent as SearchIcon } from 'shared/assets/icons/SearchIcon.svg';
import { Input } from 'shared/ui/Input';
import { NoResults } from 'shared/ui/NoResults';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { getSearch } from '../model/selectors/getSearch';
import { warehouseMapActions } from '../model/slice/warehouseMapSlice';
import { WarehouseCard } from './WarehouseCard/WarehouseCard';
import { calculateDistance } from '../utils/helpers/calculateDistance';
import { useGeolocation } from 'shared/utils/hooks/useGeolocation';
import { roundNumber } from 'shared/utils/helpers/roundNumber';

interface WarehousesListProps {
  initialWarehousesCount: number;
  warehouses: WarehouseForBooking[] | undefined;
  outlinedWarehouseId: Nullable<string>;
  onSelectCard: (lat: number, lng: number, cardId: string) => void;
  onGetRoute: (coords: GeolocationCoords, warehouseId: string) => void;
  onClearRoute: () => void;
  onSelectWarehouse: (warehouse: WarehouseForBooking) => void;
}

export const WarehousesList: FC<WarehousesListProps> = memo((props) => {
  const { warehouses, initialWarehousesCount, outlinedWarehouseId, onSelectCard, onGetRoute, onClearRoute, onSelectWarehouse } = props;

  const { t } = useAppTranslation('booking');

  const dispatch = useAppDispatch();

  const userCoords = useGeolocation();

  const search = useAppSelector(getSearch);

  const showSearch = initialWarehousesCount > 3;

  const handleSearch = useCallback(
    (value: string | undefined): void => {
      dispatch(warehouseMapActions.changeSearch(value));
    },
    [dispatch],
  );

  return (
    <div className="space-y-3">
      <div className="text-2xl font-semibold desktop:text-3xl">{t('Find your nearest warehouse')}</div>
      {showSearch && (
        <Input
          className="mb-3 placeholder-red-500"
          placeholder={t('Enter location')}
          prefix={<SearchIcon className="stroke-accent" />}
          value={search}
          onChange={handleSearch}
        />
      )}
      {warehouses?.length ? (
        warehouses.map((warehouse) => {
          const isOutlined = outlinedWarehouseId === warehouse.warehouseId;

          const distance = userCoords
            ? roundNumber(calculateDistance(userCoords.lat, userCoords.lng, warehouse.latitude, warehouse.longitude))
            : null;

          const selectCard = (coords: GeolocationCoords): void => {
            onSelectCard(coords.lat, coords.lng, warehouse.warehouseId);
          };

          const setNextStep = (): void => {
            onSelectWarehouse(warehouse);
          };

          return (
            <WarehouseCard
              key={warehouse.warehouseId}
              warehouse={warehouse}
              distance={distance}
              isOutlined={isOutlined}
              onGetRoute={onGetRoute}
              onClearRoute={onClearRoute}
              onViewBoxes={setNextStep}
              onSelectCard={selectCard}
            />
          );
        })
      ) : (
        <NoResults />
      )}
    </div>
  );
});
