import React, { FC, memo } from 'react';
import { getWorkingScheduleString, Warehouse } from 'entities/Warehouse';
import WarehouseDefaultImage from 'shared/assets/images/WarehouseDefaultImage.png';
import { getLocalizedString } from 'shared/utils/helpers/JSONLocalization';

interface WarehouseCardProps {
  warehouse: Warehouse;
}

export const WarehouseCard: FC<WarehouseCardProps> = memo((props) => {
  const {
    warehouse: { name, address, workingSchedule, email, phone, city, zip, description, imageUrl },
  } = props;

  return (
    <div className="flex justify-between flex-1 space-x-8">
      <img className="w-[403px] h-[250px] object-cover rounded-xl" src={imageUrl || WarehouseDefaultImage} alt={`Warehouse ${name}`} />
      <div className="bg-white p-7 rounded-xl flex-1">
        <div>
          {address}, {zip}, {city.name}
        </div>
        {workingSchedule && <div>{getWorkingScheduleString(workingSchedule)}</div>}
        <div>{email}</div>
        <div>{phone}</div>
        <div>Squares</div>
        <div>{getLocalizedString(description)}</div>
      </div>
    </div>
  );
});
