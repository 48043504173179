import React, { FC, memo, useMemo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { BulletsTable } from 'shared/ui/BulletsTable';
// import { ReactComponent as MapIcon } from 'shared/assets/icons/MapIcon.svg';
import BoxInfoImage from 'shared/assets/images/BoxInfoImage.png';
import { Box, useGetBoxRentalPrice } from 'entities/Box';
import { roundNumber } from 'shared/utils/helpers/roundNumber';
import { getLocalizedString } from 'shared/utils/helpers/JSONLocalization';
import { InvoiceFrequencyType } from 'entities/Invoice';

interface BoxInfoStackProps {
  boxInfo: Nullable<Box>;
}

export const BoxInfoStack: FC<BoxInfoStackProps> = memo((props) => {
  const { boxInfo } = props;

  const { t } = useAppTranslation('booking');
  const rentalPrice = useGetBoxRentalPrice(boxInfo);
  const weekRate = useGetBoxRentalPrice(boxInfo, InvoiceFrequencyType.WEEK);

  const boxImage = boxInfo?.sizeCode.imageUrl || BoxInfoImage;

  const size = `${roundNumber(boxInfo?.height)}m (${t('H')}) x ${roundNumber(boxInfo?.width)}m (${t('W')}) x ${roundNumber(
    boxInfo?.length,
  )}m (${t('D')})`;
  const square = boxInfo ? `${boxInfo.sizeCode.square} m²` : undefined;
  const volume = boxInfo ? `${roundNumber(boxInfo.height * boxInfo.width * boxInfo.length)} m³` : undefined;

  const bullets = useMemo(() => {
    const baseBullets = [
      { label: t('Box number'), value: boxInfo?.name },
      { label: t('Square'), value: square },
      { label: t('Volume'), value: volume },
      { label: t('Box size'), value: size },
      { label: t('Floor'), value: getLocalizedString(boxInfo?.floor?.name) },
      { label: t('Rental price'), value: rentalPrice },
    ];

    if (weekRate) {
      baseBullets.push({ label: t('Regular price'), value: weekRate });
    }

    return baseBullets;
  }, [boxInfo?.floor?.name, boxInfo?.name, rentalPrice, size, square, t, volume, weekRate]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-col items-center">
        <img className="w-full" src={boxImage} alt="Box photo" />
        {/* <Button theme="clear" icon={<MapIcon className="stroke-accent" />} iconPosition="prev">
          {t('view on the warehouse map')}
        </Button> */}
        <div>{getLocalizedString(boxInfo?.description)}</div>
      </div>
      <div className="my-4">
        <BulletsTable rows={bullets} />
      </div>
    </div>
  );
});
