export enum DepositsListFilterKeys {
  selectedWarehouses = 'selectedWarehouses',
  creationDateFrom = 'creationDateFrom',
  creationDateTo = 'creationDateTo',
  status = 'status',
  isApplicable = 'isApplicable',
  statusRefDate = 'statusRefDate',
  startDateFrom = 'startDateFrom',
  startDateTo = 'startDateTo',
}

export enum ContractApplicabilityFilter {
  APPLICABLE = 'applicable',
  INAPPLICABLE = 'inapplicable',
}
