import { Contract } from 'entities/Contract';
import { DocumentType, InvoiceItem } from 'entities/Invoice';
import { Payment } from 'entities/Payment';
import { User } from 'entities/User';

export interface Deposit {
  depositId: string;
  user: User;
  invoiceItem: InvoiceItem;
  description?: string;
  isRefunded: boolean;
  contract: Contract;
  initialDepositAmount: number;
  depositAmount: number;
  documentNumber: string;
  documentType: DocumentType;
  charges: DepositCharge[];
  createdAt: string;
}

export interface DepositCharge {
  depositChargeId: string;
  description?: string;
  deposit: Deposit;
  amount: number;
  documentType: DocumentType;
  documentNumber: string;
  createdAt: string;
}

export interface DepositRefundNote {
  depositRefundNoteId: string;
  user: User;
  deposit: Deposit;
  payment: Payment;
  description?: string;
  issuedBy?: string;
  refundAmount: number;
  documentNumber: string;
  documentType: DocumentType;
}

export interface ChargeDepositParams {
  depositId: string;
  amount: number;
  description?: Nullable<string>;
}

export enum DepositPaymentStatus {
  NOT_PAID = 'Not paid',
  PAID = 'Paid',
  CANCELLED = 'Cancelled',
  REFUNDED = 'Refunded',
  WAITING_FOR_REFUND = 'Waiting for refund',
  NOT_REFUNDED = 'Not refunded',
}
