import { api } from 'app/config/apiConfig';
import { Warehouse } from '../model/types';

interface Params {
  workingSchedule?: boolean;
  holidays?: boolean;
  city?: boolean;
  floors?: boolean;
  internalEquipment?: boolean;
  vatRates?: boolean;
}

export const getWarehouseByIdApi = api.injectEndpoints({
  endpoints: (build) => ({
    getWarehouseById: build.query<Warehouse, { warehouseId: string | undefined; params?: Params }>({
      query: ({ warehouseId, params }) => ({
        url: `/warehouses/${warehouseId}`,
        params,
      }),
      providesTags: ['Warehouses'],
    }),
  }),
});

export const { useGetWarehouseByIdQuery } = getWarehouseByIdApi;
