import { KonvaEventObject } from 'konva/lib/Node';
import React, { FC, memo, ReactNode } from 'react';
import { Ellipse, Group, Line, Rect, Text } from 'react-konva';
import { roundNumber } from 'shared/utils/helpers/roundNumber';
import { useAppSelector } from 'app/config/storeConfig/hooks';
import { getGridCellSize } from '../model/selectors/getGridCellSize';
import { Shape, ShapeType, WarehouseMapTool } from '../model/types';

interface WarehouseMapShapesProps {
  activeTool: WarehouseMapTool;
  shapes: Shape[];
  onDragMove: (e: KonvaEventObject<MouseEvent>) => void;
  onDragEnd: (e: KonvaEventObject<MouseEvent>) => void;
  onTransformEnd: (e: KonvaEventObject<MouseEvent>) => void;
}

export const WarehouseMapShapes: FC<WarehouseMapShapesProps> = memo((props) => {
  const { shapes, activeTool, onDragMove, onDragEnd, onTransformEnd } = props;

  const gridCellSize = useAppSelector(getGridCellSize);

  const commonOptions = {
    draggable: activeTool === WarehouseMapTool.POINTER,
    onDragMove,
    onDragEnd,
    onTransformEnd,
  };

  const getShapes = (): Nullable<ReactNode> => {
    return shapes.map((shape) => {
      const deleteShape = (e: KonvaEventObject<MouseEvent>): void => {
        e.evt.preventDefault();
        shape.onDelete?.();
      };

      const fontSize = 'fontSize' in shape && shape.fontSize ? shape.fontSize : 12;

      const boxText =
        'label' in shape && 'width' in shape
          ? `${shape.label}\n${roundNumber((shape.width / gridCellSize) * (shape.height / gridCellSize))} m²`
          : '';

      const getPolygonSquare = (points: number[]): number => {
        const pointsCount = points.length / 2;
        let square = 0;

        for (let i = 0; i < pointsCount; i++) {
          const x1 = points[2 * i];
          const y1 = points[2 * i + 1];
          const x2 = points[2 * ((i + 1) % pointsCount)];
          const y2 = points[2 * ((i + 1) % pointsCount) + 1];

          square += (x1 / gridCellSize) * (y2 / gridCellSize) - (x2 / gridCellSize) * (y1 / gridCellSize);
        }

        return Math.abs(square) / 2;
      };

      const polygonBoxText =
        'label' in shape && 'points' in shape ? `${shape.label}\n${roundNumber(getPolygonSquare(shape.points))} m²` : '';

      const findPolygonCenter = (coords: number[]): [number, number] => {
        let sumX = 0;
        let sumY = 0;
        const pointsCount = coords.length / 2;

        for (let i = 0; i < coords.length; i += 2) {
          sumX += coords[i];
          sumY += coords[i + 1];
        }

        const centerX = sumX / pointsCount;
        const centerY = sumY / pointsCount;

        return [centerX, centerY];
      };

      switch (shape.type) {
        case ShapeType.RECTANGLE:
          return (
            <Group
              key={shape.id}
              name="shape"
              onContextMenu={deleteShape}
              id={shape.id}
              x={shape.x}
              y={shape.y}
              scaleX={shape.scaleX}
              scaleY={shape.scaleY}
              width={shape.width}
              height={shape.height}
              rotation={shape.rotation}
              {...commonOptions}
            >
              <Rect
                id={shape.id}
                width={shape.width}
                height={shape.height}
                fill={shape.fill}
                stroke={shape.stroke}
                strokeWidth={shape.strokeWidth}
                cornerRadius={shape.cornerRadius}
                strokeScaleEnabled={false}
              />
              <Text
                text={boxText}
                x={shape.width / 2}
                y={shape.height / 2 - fontSize / 2}
                offsetX={(boxText.length * (fontSize / 4)) / 2}
                offsetY={fontSize / 2}
                fontSize={fontSize}
                align="center"
                verticalAlign="middle"
              />
            </Group>
          );
        case ShapeType.CIRCLE:
          return (
            <Group
              key={shape.id}
              name="shape"
              onContextMenu={deleteShape}
              id={shape.id}
              x={shape.x}
              y={shape.y}
              scaleX={shape.scaleX}
              scaleY={shape.scaleY}
              width={shape.radiusX * 2}
              height={shape.radiusY * 2}
              rotation={shape.rotation}
              {...commonOptions}
            >
              <Ellipse
                key={shape.id}
                id={shape.id}
                fill={shape.fill}
                stroke={shape.stroke}
                radiusX={shape.radiusX}
                radiusY={shape.radiusY}
                strokeWidth={shape.strokeWidth}
                cornerRadius={shape.cornerRadius}
                strokeScaleEnabled={false}
                width={shape.radiusX * 2}
                height={shape.radiusY * 2}
              />
              <Text
                text={shape.label}
                x={shape.radiusX / fontSize / 2}
                y={shape.radiusY / fontSize / 2}
                fontSize={fontSize}
                offsetX={shape.label.length * (fontSize / 4)}
                offsetY={shape.radiusY / fontSize}
                align="center"
                verticalAlign="middle"
              />
            </Group>
          );
        case ShapeType.LINE:
          return <Line key={shape.id} name="shape" {...shape} {...commonOptions} x={0} y={0} onContextMenu={deleteShape} />;
        case ShapeType.TEXT:
          return (
            <Text
              key={shape.id}
              name="shape"
              {...shape}
              text={shape.label}
              strokeWidth={1}
              {...commonOptions}
              onContextMenu={deleteShape}
            />
          );
        case ShapeType.POLYGON:
          return (
            <Line
              key={shape.id}
              name="shape"
              {...shape}
              {...commonOptions}
              draggable={!shape.locked && activeTool === WarehouseMapTool.POINTER}
              x={0}
              y={0}
              closed
              onContextMenu={deleteShape}
            />
          );
        case ShapeType.POLYGON_BOX:
          return (
            <Group
              key={shape.id}
              name="shape"
              onContextMenu={deleteShape}
              id={shape.id}
              rotation={shape.rotation}
              {...commonOptions}
              draggable={!shape.locked && activeTool === WarehouseMapTool.POINTER}
            >
              <Line key={shape.id} name="shape" {...shape} {...commonOptions} x={0} y={0} closed />
              <Text
                text={polygonBoxText}
                x={findPolygonCenter(shape.points)[0]}
                y={findPolygonCenter(shape.points)[1]}
                offsetX={(polygonBoxText.length * (fontSize / 4)) / 2}
                offsetY={fontSize / 2}
                fontSize={fontSize}
                align="center"
                verticalAlign="middle"
              />
            </Group>
          );
        default:
          return null;
      }
    });
  };

  return <>{getShapes()}</>;
});
