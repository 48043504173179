import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { Box } from 'entities/Box';
import { WarehouseForBooking } from 'entities/Warehouse';
import React, { FC, memo, ReactNode, useCallback, useEffect } from 'react';
import { bookingHeaderActions, BookingSteps, getActiveStep, getCompletedSteps } from 'widgets/BookingHeader';
import { BookingInfoStack } from 'widgets/BookingInfoStack';
import { SelectBoxSizeForm } from 'widgets/SelectBoxSizeForm';
import { SelectWarehouseMap } from 'widgets/SelectWarehouseMap';
import { UserDetailsForm } from 'widgets/UserDetailsForm';
import { PaymentForm } from 'widgets/PaymentForm';
import { useScrollToElement } from 'shared/utils/hooks/useScrollToElement';
import { contractOptionsActions, getContractOptions } from 'entities/Contract';
import { boxSizeFilterActions } from 'features/BoxSizeFilter';
import { getBoxInfo } from '../model/selectors/getBoxInfo';
import { getWarehouseInfo } from '../model/selectors/getWarehouseInfo';
import { bookingPageActions } from '../model/slice/bookingPageSlice';
import { ContractInformationForm } from 'widgets/ContractInformationForm';
import { useSetBookingDataFromQuery } from '../utils/hooks/useSetBookingDataFromQuery';
import { GratitudeModal } from 'features/GratitudeModal';

export const BookingPage: FC = memo(() => {
  const dispatch = useAppDispatch();

  const bookingStep = useAppSelector(getActiveStep);
  const completedSteps = useAppSelector(getCompletedSteps);
  const warehouseInfo = useAppSelector(getWarehouseInfo);
  const boxInfo = useAppSelector(getBoxInfo);
  const contractOptions = useAppSelector(getContractOptions);

  const { scrollToTop } = useScrollToElement();

  useSetBookingDataFromQuery();

  useEffect(() => {
    scrollToTop();
  }, [bookingStep, scrollToTop]);

  useEffect(() => {
    return () => {
      dispatch(bookingPageActions.resetAllBookingInfo());
      dispatch(bookingHeaderActions.resetAllBookingData());
    };
  }, [dispatch]);

  const selectWarehouse = useCallback(
    (warehouse: WarehouseForBooking): void => {
      const isNewWarehouseSelected = warehouseInfo?.warehouseId !== warehouse.warehouseId;

      if (isNewWarehouseSelected) {
        dispatch(
          contractOptionsActions.setContractOptions({
            insuranceId: null,
            insuranceAmountWithVat: 0,
            insuranceAmountWithoutVat: 0,
            insuranceVatRateAmount: 0,
            coverageAmount: null,
          }),
        );
        dispatch(bookingHeaderActions.clearBookingProgressData());
        dispatch(bookingPageActions.clearInfoAfterWarehouseChange());
        dispatch(boxSizeFilterActions.resetFilter());
      }

      dispatch(bookingPageActions.setWarehouseInfo(warehouse));
      dispatch(bookingHeaderActions.changeActiveStep(BookingSteps.SELECT_BOX));
    },
    [dispatch, warehouseInfo?.warehouseId],
  );

  const selectBox = useCallback(
    (box: Box, sizeCodeId: string): void => {
      dispatch(bookingPageActions.setBoxInfo({ selectedBox: box, selectedSizeCodeId: sizeCodeId }));
    },
    [dispatch],
  );

  const bookBox = useCallback(
    (box: Box, sizeCodeId: string) => {
      dispatch(bookingPageActions.setBoxInfo({ selectedBox: box, selectedSizeCodeId: sizeCodeId }));
      dispatch(bookingHeaderActions.changeActiveStep(BookingSteps.CONTRACT_INFORMATION));
    },
    [dispatch],
  );

  const completeContractInfoAuthorized = useCallback((): void => {
    dispatch(bookingHeaderActions.changeActiveStep(BookingSteps.PAYMENT));
  }, [dispatch]);

  const completeContractInfoUnAuthorized = useCallback((): void => {
    dispatch(bookingHeaderActions.changeActiveStep(BookingSteps.YOUR_DETAILS));
  }, [dispatch]);

  const onRentOptionsChange = useCallback(() => {
    dispatch(bookingHeaderActions.changeActiveStep(BookingSteps.CONTRACT_INFORMATION));
  }, [dispatch]);

  const goToPaymentForm = useCallback(() => {
    dispatch(bookingHeaderActions.changeActiveStep(BookingSteps.PAYMENT));
  }, [dispatch]);

  const bookAndPay = useCallback((): void => {
    console.log('success booking');
  }, []);

  const renderStepScreen = useCallback((): Nullable<ReactNode> => {
    switch (bookingStep) {
      case BookingSteps.SELECT_LOCATION:
        return <SelectWarehouseMap onCompleteStep={selectWarehouse} />;
      case BookingSteps.SELECT_BOX:
        return (
          <SelectBoxSizeForm
            selectedBoxId={boxInfo?.boxId}
            selectedWarehouseId={warehouseInfo?.warehouseId}
            onSelectBox={selectBox}
            onSelectWarehouse={selectWarehouse}
            onCompleteStep={bookBox}
          />
        );
      case BookingSteps.CONTRACT_INFORMATION:
        return (
          <ContractInformationForm
            onCompleteStepAuthorized={completeContractInfoAuthorized}
            onCompleteStepUnAuthorized={completeContractInfoUnAuthorized}
            selectedWarehouseId={warehouseInfo?.warehouseId}
          />
        );
      case BookingSteps.YOUR_DETAILS:
        return (
          <UserDetailsForm
            warehouseInfo={warehouseInfo}
            boxInfo={boxInfo}
            contractInfo={contractOptions}
            onCompleteStep={goToPaymentForm}
            onRentOptionsChange={onRentOptionsChange}
          />
        );
      case BookingSteps.PAYMENT:
        return <PaymentForm boxInfo={boxInfo} warehouseInfo={warehouseInfo} contractInfo={contractOptions} onCompleteStep={bookAndPay} />;
      default:
        return null;
    }
  }, [
    bookingStep,
    selectWarehouse,
    boxInfo,
    warehouseInfo,
    selectBox,
    bookBox,
    completeContractInfoAuthorized,
    completeContractInfoUnAuthorized,
    onRentOptionsChange,
    contractOptions,
    goToPaymentForm,
    bookAndPay,
  ]);

  return (
    <div className="mt-9 flex flex-col justify-between space-y-6 desktop:space-x-3 desktop:space-y-0 desktop:flex-row">
      <BookingInfoStack
        className="desktop:w-[425px] desktop:h-max desktop:sticky desktop:top-[calc(theme(spacing.headerHeight)+34px)] desktop:shrink-0"
        bookingStep={bookingStep}
        completedSteps={completedSteps}
        warehouseInfo={warehouseInfo}
        boxInfo={boxInfo}
        contractInfo={contractOptions}
      />
      <div className="desktop:basis-3/4 desktop:flex-1">{renderStepScreen()}</div>
      <GratitudeModal />
    </div>
  );
});
