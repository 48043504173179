import { api } from 'app/config/apiConfig';
import { Floor } from '../model/types';

interface Args {
  shapesConfig: string;
}

export const saveMapConfigApi = api.injectEndpoints({
  endpoints: (build) => ({
    saveMapConfig: build.mutation<Floor, { warehouseId: string; floorId: string; args: Args }>({
      query: ({ warehouseId, floorId, args }) => ({
        url: `warehouses/${warehouseId}/floors/${floorId}/map-config`,
        method: 'PUT',
        body: args,
      }),
    }),
  }),
});

export const { useSaveMapConfigMutation } = saveMapConfigApi;
