import { MenuProps } from 'antd';
import { AppLogo, getGlobalSettings } from 'app/appState';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { AppRoutes } from 'app/config/routerConfig/types';
import { useAppSelector } from 'app/config/storeConfig/hooks';
import { getLoggedUserData, useRole, UserPermissions, UserRoles } from 'entities/User';
import { useGetAllWarehousesQuery } from 'entities/Warehouse';
import { Header, MenuItem } from 'features/Header';
import { HeaderAuthAction } from 'features/HeaderAuthAction';
import { LangSwitcher } from 'features/LangSwitcher';
import React, { FC, memo, ReactNode, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as PlusIcon } from 'shared/assets/icons/PlusIcon.svg';
import { generateKnowledgeBaseUrl } from 'shared/utils/helpers/generateKnowledgeBaseUrl';
import { useCheckPermission } from 'shared/utils/hooks/useCheckPermission';
import { useScreenBreakpoints } from 'shared/utils/hooks/useScreenBreakpoints';
import { CreateWarehouseModal, useCreateWarehouseModal } from 'widgets/CreateWarehouseModal';

export const MainHeader: FC = memo(() => {
  const { t } = useAppTranslation(['header', 'warehouses', 'common', 'city', 'franchise']);

  const { isDesktop } = useScreenBreakpoints();

  const { openModal } = useCreateWarehouseModal();

  const loggedUser = useAppSelector(getLoggedUserData);

  const isForbiddenCreateWarehouse = useCheckPermission(UserPermissions.CREATE_UNITS);

  const { isManagement } = useRole();

  const { data } = useGetAllWarehousesQuery(undefined, { skip: !isManagement });

  const globalSettings = useAppSelector(getGlobalSettings);

  const actions = useMemo<ReactNode[]>(
    () => [<LangSwitcher key="1" />, <HeaderAuthAction key="2" userEmail={loggedUser?.email} />],
    [loggedUser?.email],
  );

  const assignedWarehouses = useMemo(() => {
    return loggedUser?.warehouses?.length ? loggedUser.warehouses : data;
  }, [data, loggedUser?.warehouses]);

  const getWarehouseSubItems = useCallback(
    (warehouseId: string): MenuProps['items'] => {
      const warehouseSubItems: MenuProps['items'] = [
        { key: '4', label: <Link to="#">{t('Customers')}</Link> },
        { key: '5', label: <Link to="#">{t('Contracts')}</Link> },
        { key: '6', label: <Link to="#">{t('Boxes')}</Link> },
        { key: '7', label: <Link to="#">{t('Size codes')}</Link> },
        { key: '8', label: <Link to="#">{t('Reservations')}</Link> },
        { key: '9', label: <Link to="#">{t('Insurance')}</Link> },
        { key: '10', label: <Link to="#">{t('Discounts')}</Link> },
      ];

      // map warehouse sub items with unique key (warehouse key + sub item key) because sub items for each warehouse are the same
      return warehouseSubItems.map((item) => (item ? { ...item, key: String(item.key) + warehouseId } : item));
    },
    [t],
  );

  const getWarehouseItems = useCallback((): MenuProps['items'] => {
    const warehouseItems =
      assignedWarehouses?.map(({ warehouseId, name }) => ({
        key: warehouseId,
        label: <Link to={`${AppRoutes.WAREHOUSES}/${warehouseId}`}>{name}</Link>,
        children: getWarehouseSubItems(warehouseId),
      })) || [];

    const addButton = {
      key: 'Add new',
      label: (
        <div className="flex items-center">
          <PlusIcon className="[&>path]:stroke-accent mr-1.5" />
          <div onClick={openModal}>{t('Add new')}</div>
        </div>
      ),
    };

    return isManagement && !isForbiddenCreateWarehouse ? [...warehouseItems, addButton] : warehouseItems;
  }, [assignedWarehouses, openModal, t, isManagement, isForbiddenCreateWarehouse, getWarehouseSubItems]);

  const billingDocumentsItems: MenuProps['items'] = useMemo(
    () => [
      {
        key: '30',
        label: <Link to={AppRoutes.INVOICES}>{t('Invoices')}</Link>,
        allowedroles: [UserRoles.SUPERUSER, UserRoles.MANAGER, UserRoles.PARTNER],
      },
      {
        key: '31',
        label: <Link to={AppRoutes.CREDIT_NOTES}>{t('Credit Notes')}</Link>,
        allowedroles: [UserRoles.SUPERUSER, UserRoles.MANAGER, UserRoles.PARTNER],
      },
      {
        key: '32',
        label: <Link to={AppRoutes.INSURANCE}>{t('Insurance')}</Link>,
        allowedroles: [UserRoles.SUPERUSER, UserRoles.MANAGER, UserRoles.PARTNER],
      },
      {
        key: '33',
        label: <Link to={AppRoutes.DEPOSITS}>{t('Deposits')}</Link>,
        allowedroles: [UserRoles.SUPERUSER, UserRoles.MANAGER, UserRoles.PARTNER],
      },
    ],
    [t],
  );

  const statisticsItems: MenuProps['items'] = useMemo(
    () => [
      {
        key: '35',
        label: <Link to={AppRoutes.BOXES_OCCUPANCY}>{t('Boxes occupancy')}</Link>,
        allowedroles: [UserRoles.SUPERUSER, UserRoles.MANAGER, UserRoles.PARTNER],
      },
    ],
    [t],
  );

  const generalItems: MenuProps['items'] = useMemo(
    () => [
      {
        key: '11',
        label: <Link to={AppRoutes.CITIES}>{t('Cities')}</Link>,
        allowedroles: [UserRoles.SUPERUSER, UserRoles.MANAGER],
      },
      { key: '12', label: <Link to="#">{t('Discounts')}</Link> },
      { key: '13', label: <Link to="#">{t('Price update')}</Link> },
      { key: '14', label: <Link to={AppRoutes.INSURANCE_SETTINGS}>{t('Insurance settings')}</Link> },
      { key: '15', label: <Link to="#">{t('Renting stuff')}</Link> },
      { key: '16', label: <Link to="#">{t('History')}</Link> },
      {
        key: '17',
        label: <Link to={AppRoutes.TASKS}>{t('Tasks')}</Link>,
        allowedroles: [UserRoles.SUPERUSER, UserRoles.MANAGER],
      },
      {
        key: '18',
        label: <Link to={AppRoutes.LATE_CHARGE_SETTINGS}>{t('Late charge settings')}</Link>,
        allowedroles: [UserRoles.SUPERUSER, UserRoles.MANAGER],
      },
    ],
    [t],
  );

  const menuItems = useMemo<MenuItem[]>(
    () => [
      {
        key: '19',
        label: <Link to={AppRoutes.WAREHOUSES}>{t('Warehouses')}</Link>,
        children: getWarehouseItems(),
        className: 'flex items-center',
        allowedroles: [UserRoles.SUPERUSER, UserRoles.MANAGER, UserRoles.STAFF, UserRoles.PARTNER],
      },
      {
        key: '20',
        label: <Link to={AppRoutes.USERS}>{t('Users')}</Link>,
        allowedroles: [UserRoles.SUPERUSER, UserRoles.MANAGER],
      },
      {
        key: '21',
        label: <Link to={AppRoutes.CONTRACTS}>{t('Contracts')}</Link>,
        allowedroles: [UserRoles.SUPERUSER, UserRoles.MANAGER, UserRoles.PARTNER],
      },
      {
        key: '22',
        label: <Link to={AppRoutes.RESERVATIONS}>{t('Reservations')}</Link>,
        allowedroles: [UserRoles.SUPERUSER, UserRoles.MANAGER, UserRoles.PARTNER],
      },
      {
        key: '23',
        label: <Link to={AppRoutes.BOXES}>{t('Boxes')}</Link>,
        allowedroles: [UserRoles.SUPERUSER, UserRoles.MANAGER, UserRoles.STAFF, UserRoles.PARTNER],
      },
      {
        key: '24',
        label: <Link to={AppRoutes.INVOICES}>{t('Billing')}</Link>,
        children: billingDocumentsItems,
        allowedroles: [UserRoles.SUPERUSER, UserRoles.MANAGER, UserRoles.PARTNER],
      },
      {
        key: '25',
        label: <Link to={AppRoutes.NOTES}>{t('Notes')}</Link>,
        allowedroles: [UserRoles.SUPERUSER, UserRoles.MANAGER],
      },
      {
        key: '26',
        label: <Link to={AppRoutes.BOXES_OCCUPANCY}>{t('Statistics')}</Link>,
        children: statisticsItems,
        allowedroles: [UserRoles.SUPERUSER, UserRoles.MANAGER],
      },
      {
        key: '27',
        label: <Link to={AppRoutes.GENERAL}>{t('General')}</Link>,
        children: generalItems,
        allowedroles: [UserRoles.SUPERUSER, UserRoles.MANAGER],
      },
      { key: '28', label: <Link to={AppRoutes.BOOKING}>{t('Booking')}</Link> },
      {
        key: '29',
        label: <Link to={AppRoutes.GLOBAL_SETTINGS}>{t('Global settings')}</Link>,
        allowedroles: [UserRoles.SUPERUSER],
      },
    ],
    [t, getWarehouseItems, billingDocumentsItems, statisticsItems, generalItems],
  );

  if (globalSettings?.partnerKnowledgeBaseUrl) {
    const partnerAdditionalMenuItem = {
      key: '34',
      label: <Link to={generateKnowledgeBaseUrl(globalSettings.partnerKnowledgeBaseUrl)}>{t('Knowledge base')}</Link>,
      className: '!text-warn animate-pulse',
      allowedroles: [UserRoles.PARTNER],
    };

    menuItems.push(partnerAdditionalMenuItem);
  }

  return (
    <div className="sticky top-0 z-20 max-h-headerHeight">
      <Header logo={<AppLogo width={120} />} actions={actions} menuItems={isDesktop ? menuItems : undefined} />
      <CreateWarehouseModal />
    </div>
  );
});
