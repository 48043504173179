import Konva from 'konva';

export const getRelativeCursorPosition = (node: Nullable<Konva.Stage>): Nullable<{ x: number; y: number }> => {
  if (!node) return null;

  const transform = node.getAbsoluteTransform().copy();
  transform.invert();
  const cursorPosition = node.getStage().getPointerPosition();

  if (!cursorPosition) return null;

  return transform.point(cursorPosition);
};
