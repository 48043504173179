import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { Floor } from 'entities/Floor';
import { useGetSizeCodesByWarehouseIdQuery } from 'entities/SizeCode';
import { KonvaEventObject } from 'konva/lib/Node';
import React, { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { Layer, Rect, Stage, Transformer } from 'react-konva';
import { roundNumber } from 'shared/utils/helpers/roundNumber';
import { getGridCellSize } from '../model/selectors/getGridCellSize';
import { getShapes } from '../model/selectors/getShapes';
import { warehouseMapConstructorActions } from '../model/slice/warehouseMapConstructorSlice';
import { Shape, ShapeType, WarehouseMapTool } from '../model/types';
import { useCanvasDimensions } from '../utils/hooks/useCanvasDimensions';
import { useCanvasGrid } from '../utils/hooks/useCanvasGrid';
import { useCanvasScale } from '../utils/hooks/useCanvasScale';
import { useFloorSchemaImage } from '../utils/hooks/useFloorSchemaImage';
import { useMouseArea } from '../utils/hooks/useMouseArea';
import { usePickerBoxes } from '../utils/hooks/usePickerBoxes';
import { useTool } from '../utils/hooks/useTool';
import { useTransform } from '../utils/hooks/useTransform';
import { useWatchShapeChanges } from '../utils/hooks/useWatchShapeChanges';
import { ConstructorBackground } from './ConstructorBackground';
import { FloorSwitch } from './FloorSwitch';
import { ParametersBar } from './ParametersBar';
import { ToolBar } from './ToolBar';
import { WarehouseMapShapes } from './WarehouseMapShapes';

interface WarehouseMapConstructorProps {
  floors: Floor[] | undefined;
  selectedFloor: Floor;
  warehouseId: string | undefined;
  shapesConfig?: Nullable<string>;
  refetchFloors: () => void;
}

export const WarehouseMapConstructor: FC<WarehouseMapConstructorProps> = memo((props) => {
  const { floors, selectedFloor, warehouseId, shapesConfig, refetchFloors } = props;

  const dispatch = useAppDispatch();

  const shapes = useAppSelector(getShapes);
  const gridCellSize = useAppSelector(getGridCellSize);

  const { stageParentRef, canvasDimensions } = useCanvasDimensions();
  const { activeTool, onChangeTool } = useTool();
  const { canvasPosition, canvasScale, onMouseWheel } = useCanvasScale();
  const { transformerRef, stageRef, layerRef, resizeByGrid } = useTransform({ shapes });
  const { gridRef, onDragMove } = useCanvasGrid(canvasDimensions, canvasScale, stageRef);

  const { image, loadImageSrc } = useFloorSchemaImage();

  const filteredShapesByFloor = useMemo(
    () => shapes.filter((shape) => shape.floorId === selectedFloor.floorId),
    [selectedFloor.floorId, shapes],
  );

  const floorSchemaImage = floors?.find((floor) => floor.floorId === selectedFloor.floorId)?.floorSchemaBackgroundUrl;

  const { returnBoxToPicker } = usePickerBoxes({ warehouseId, sizeCodeId: undefined });

  const { data } = useGetSizeCodesByWarehouseIdQuery(warehouseId, { skip: !warehouseId });

  useEffect(() => {
    if (floorSchemaImage) {
      loadImageSrc(floorSchemaImage);
    }
  }, [floorSchemaImage, loadImageSrc]);

  useEffect(() => {
    const parsedShapes: Shape[] = shapesConfig ? JSON.parse(shapesConfig) : [];

    const shapesWithDelete = parsedShapes.map((shape) => ({
      ...shape,
      onDelete: () => {
        dispatch(warehouseMapConstructorActions.deleteShape());
        returnBoxToPicker(shape.id);
      },
    }));

    dispatch(warehouseMapConstructorActions.setInitialShapes(shapesWithDelete));
  }, [dispatch, returnBoxToPicker, shapesConfig]);

  const selectedShape = useMemo(() => {
    return shapes.find(({ selected }) => selected);
  }, [shapes]);

  const unselectShapes = useCallback((): void => {
    dispatch(warehouseMapConstructorActions.unselectShapes());
  }, [dispatch]);

  const { onMouseDown, onMouseUp, onMouseMove, onMouseLeave, previewLayerRef, selectedArea } = useMouseArea({
    activeTool,
    transformerRef,
    floorId: selectedFloor.floorId,
    availableSizeCodes: data,
  });

  const updateShapeCoords = useCallback(
    (e: KonvaEventObject<MouseEvent>): void => {
      if (!('id' in e.target.attrs)) return;

      const shapeId = e.target.attrs?.id as string;

      const newPoints =
        'points' in e.target.attrs
          ? e.target.attrs.points.map((point: number, index: number) => (index % 2 === 0 ? point + e.target.x() : point + e.target.y()))
          : undefined;

      const attrs =
        'points' in e.target.attrs
          ? {
              x: 0,
              y: 0,
              points: newPoints,
            }
          : {
              x: Math.round(e.target.x() / gridCellSize) * gridCellSize,
              y: Math.round(e.target.y() / gridCellSize) * gridCellSize,
            };

      e.target.setAttrs(attrs);

      dispatch(
        warehouseMapConstructorActions.updateShapeParams({
          id: shapeId,
          params: attrs,
        }),
      );
    },
    [dispatch, gridCellSize],
  );

  const updateShapeParams = useCallback(
    (e: KonvaEventObject<MouseEvent>): void => {
      if (!('id' in e.target.attrs)) return;

      const shapeId = e.target.attrs?.id as string;
      const shapeType = e.target.attrs?.type;

      const multiplierX = roundNumber((e.target.scaleX() * 100 - 100) / 100);
      const multiplierY = roundNumber((e.target.scaleY() * 100 - 100) / 100);
      const width = e.target.width() / gridCellSize;
      const height = e.target.height() / gridCellSize;

      e.target.setAttrs({
        x: shapeType === ShapeType.POLYGON ? 0 : roundNumber(e.target.x(), 0),
        y: shapeType === ShapeType.POLYGON ? 0 : roundNumber(e.target.y(), 0),
        width: (width + roundNumber(width * multiplierX, 0)) * gridCellSize,
        height: (height + roundNumber(height * multiplierY, 0)) * gridCellSize,
        scaleX: 1,
        scaleY: 1,
        rotation: e.target.rotation(),
      });

      dispatch(
        warehouseMapConstructorActions.updateShapeParams({
          id: shapeId,
          params: {
            x: shapeType === ShapeType.POLYGON ? 0 : roundNumber(e.target.x(), 0),
            y: shapeType === ShapeType.POLYGON ? 0 : roundNumber(e.target.y(), 0),
            width: (width + roundNumber(width * multiplierX, 0)) * gridCellSize,
            height: (height + roundNumber(height * multiplierY, 0)) * gridCellSize,
            scaleX: 1,
            scaleY: 1,
            rotation: e.target.rotation(),
          },
        }),
      );
    },
    [dispatch, gridCellSize],
  );

  useEffect(unselectShapes, [activeTool, unselectShapes]);

  const shapesChanges = useWatchShapeChanges(shapesConfig);

  const isShapesChanged = Object.values(shapesChanges).some((value) => Boolean(value.length));

  const isResizableShape = selectedShape?.type === ShapeType.RECTANGLE || selectedShape?.type === ShapeType.CIRCLE;

  return (
    <div className="w-full h-[calc(100vh-200px)] bg-canvas-background bg-repeat rounded-lg relative" ref={stageParentRef}>
      <FloorSwitch floors={floors} isShapesChanged={isShapesChanged} warehouseId={warehouseId} />
      <ToolBar activeTool={activeTool} onChange={onChangeTool} />
      <ParametersBar
        selectedShape={selectedShape}
        floorId={selectedFloor.floorId}
        warehouseId={warehouseId}
        stage={stageRef.current}
        floorShapes={filteredShapesByFloor}
        refetchFloors={refetchFloors}
        isShapesChanged={isShapesChanged}
        shapesChanges={shapesChanges}
        availableSizeCodes={data}
      />
      <Stage
        ref={stageRef}
        className={`mt-5 ${activeTool === WarehouseMapTool.GRAB ? 'cursor-grab' : 'cursor-default'}`}
        x={canvasPosition.x}
        y={canvasPosition.y}
        scale={{ x: canvasScale, y: canvasScale }}
        width={canvasDimensions.width}
        height={canvasDimensions.height}
        draggable={activeTool === WarehouseMapTool.GRAB}
        onWheel={onMouseWheel}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        onMouseLeave={onMouseLeave}
      >
        <Layer ref={layerRef}>
          <ConstructorBackground image={image} />
          <WarehouseMapShapes
            shapes={filteredShapesByFloor}
            activeTool={activeTool}
            onDragMove={onDragMove}
            onDragEnd={updateShapeCoords}
            onTransformEnd={updateShapeParams}
          />
        </Layer>
        <Layer>
          {/* Selected area layer */}
          <Rect {...selectedArea} opacity={0.3} fill="blue" stroke="black" strokeWidth={1} listening={false} />
          {!selectedShape?.locked && (
            <Transformer ref={transformerRef} resizeEnabled={isResizableShape} flipEnabled={false} anchorDragBoundFunc={resizeByGrid} />
          )}
        </Layer>
        <Layer ref={previewLayerRef}>{/* Shape preview layer */}</Layer>
        <Layer ref={gridRef}>{/* Grid layer */}</Layer>
      </Stage>
    </div>
  );
});
