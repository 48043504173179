export enum ContractApplicabilityFilter {
  APPLICABLE = 'applicable',
  INAPPLICABLE = 'inapplicable',
}

export enum BoxesOccupancyFilterKeys {
  selectedWarehouses = 'selectedWarehouses',
  isApplicable = 'isApplicable',
  statusRefDate = 'statusRefDate',
  ignoreUnstartedContracts = 'ignoreUnstartedContracts',
  ignoreClosedContracts = 'ignoreClosedContracts',
}
