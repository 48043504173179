export enum CreditNotesListFilterKeys {
  selectedWarehouses = 'selectedWarehouses',
  creditNoteType = 'creditNoteType',
  creditedAmount = 'creditedAmount',
  creationDateFrom = 'creationDateFrom',
  creationDateTo = 'creationDateTo',
  isApplicable = 'isApplicable',
}

export enum ContractApplicabilityFilter {
  APPLICABLE = 'applicable',
  INAPPLICABLE = 'inapplicable',
}
