import { ColumnsType } from 'antd/es/table';
import React, { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ServerTableActions, ServerTableRowActions } from 'shared/ui/ServerTable/types';
import { ReactComponent as FiltersIcon } from 'shared/assets/icons/FiltersIcon.svg';
import { ServerTable } from 'shared/ui/ServerTable';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { AppRoutes } from 'app/config/routerConfig/types';
import { getClientDateFormat } from 'shared/utils/helpers/getDateFormat';
import { ContractDetailsDrawer, contractDetailsDrawerActions } from 'widgets/ContractDetailsDrawer';
import { useAppDispatch } from 'app/config/storeConfig/hooks';
import {
  Invoice,
  generateInvoicePdfLink,
  useGetPaginatedInvoicesQuery,
  generateInvoicesPdfLink,
  calculateInvoiceStatus,
  calculateInvoicePercents,
  InvoiceStatus,
} from 'entities/Invoice';
import { UnitType } from 'entities/Contract';
import classNames from 'classnames';
import { ExportEntity, ExportModal, getInvoiceColumnKeys, useOpenExportModal } from 'features/ExportModal';
import { useTableFilterContext } from 'features/TableFilter';
import { ReactComponent as ExportIcon } from 'shared/assets/icons/ExportIcon.svg';
import { useGetCurrencySymbol } from 'app/appState';
import { transformInvoicesListFilters } from '../utils/transformInvoicesListFilters';
import { InvoicesListFilter } from './InvoicesListFilter';
import { getUserName } from 'entities/User';
import dayjs from 'dayjs';
import { Progress } from 'antd';
import { roundNumber } from 'shared/utils/helpers/roundNumber';

export const InvoicesTable: FC = memo(() => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const currencySymbol = useGetCurrencySymbol();

  const { openExportModal } = useOpenExportModal();

  const { t } = useAppTranslation(['contracts', 'common']);

  const { setFiltersOpened, appliedFilters, tags, clearAllFilters } = useTableFilterContext();

  const statusRefDate = useMemo(() => {
    const today = dayjs().startOf('day');

    return appliedFilters.statusRefDate?.value ? dayjs(appliedFilters.statusRefDate.value).startOf('day') : today;
  }, [appliedFilters.statusRefDate]);

  const goToUserContracts = useCallback(
    (userId: string): void => {
      navigate(`${AppRoutes.USERS}/${userId}/contracts`);
    },
    [navigate],
  );

  const openContractDetails = useCallback(
    (contractId: string): void => {
      dispatch(contractDetailsDrawerActions.setOpenContractDetails(contractId));
    },
    [dispatch],
  );

  const rowActions: Array<ServerTableRowActions<Invoice>> = useMemo(
    () => [
      {
        name: 'downloadInvoice',
        theme: 'primary',
        label: t('Download', { ns: 'common' }),
        onClick: (selectedInvoice) => {
          const invoiceLink = generateInvoicePdfLink(selectedInvoice.invoiceId);

          window.open(invoiceLink);
        },
      },
    ],
    [t],
  );

  const columns = useMemo<ColumnsType<Invoice>>(
    () => [
      {
        title: t('Customer'),
        width: '15%',
        key: 'user',
        render: (_, record) => (
          <div
            className="text-accent font-semibold underline cursor-pointer"
            onClick={() => {
              goToUserContracts(record.user.userId);
            }}
          >
            {getUserName(record.user)}
          </div>
        ),
      },
      {
        title: t('Invoice'),
        key: 'documentNumber',
        width: '15%',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (_, record) => (
          <div className="text-primary font-normal">
            {t('Invoice')} #{record.documentNumber} <br />
            {record.contract && !record.contract.isApplicable && `(${t('Not applicable')})`}
          </div>
        ),
      },
      {
        title: t('Unit type'),
        width: '15%',
        key: 'unitType',
        render: (_, record) => {
          const boxInvoiceItem = record.invoiceItems.find((invoiceItem) => invoiceItem.contractItem?.box);
          const box = boxInvoiceItem?.contractItem?.box;

          const depositInvoiceItem = record.invoiceItems.find(
            (invoiceItem) => invoiceItem.contractItem?.contractUnitType === UnitType.DEPOSIT,
          );

          if (box) {
            return <div className="text-primary font-normal">{box.name}</div>;
          }

          if (!box && depositInvoiceItem) {
            return <div className="text-primary font-normal">{depositInvoiceItem.contractItem?.description}</div>;
          }

          if (!record.contract?.contractId) {
            return <div className="text-primary font-normal">{t('Personal Invoice', { ns: 'common' })}</div>;
          }

          if (record.relatedInvoiceId) {
            return <div className="text-primary font-normal">{t('Late charge')}</div>;
          }

          return <div className="text-primary font-normal">{t('Other', { ns: 'common' })}</div>;
        },
      },

      {
        title: t('Contract number'),
        width: '10%',
        key: 'contract',
        render: (_, record) => (
          <div
            className="text-accent underline cursor-pointer"
            onClick={() => {
              if (record?.contract?.contractId) {
                openContractDetails(record.contract.contractId);
              }
            }}
          >
            #{record.contract?.contractNumber}
          </div>
        ),
      },
      {
        title: t('Warehouse'),
        key: 'warehouse',
        width: '10%',
        render: (_, record) => <div className="text-primary font-normal">{record.warehouse?.name}</div>,
      },
      {
        title: t('Amount'),
        key: 'totalAmount',
        width: '10%',
        render: (_, record) => {
          return (
            <div className="text-primary font-normal">
              {record.amount} {currencySymbol}
            </div>
          );
        },
      },
      {
        title: t('Status ({{statusRefDate}})', { statusRefDate: getClientDateFormat(statusRefDate) }),
        key: 'status',
        width: '25%',
        render: (_, record) => {
          const result = calculateInvoiceStatus(record, statusRefDate);
          const percents = calculateInvoicePercents(record.amount, result.paidAmount, result.creditedAmount);
          const debt = roundNumber(record.amount - result.paidAmount - result.creditedAmount);

          return (
            <div className="flex flex-col items-center">
              <div className="text-primary font-normal">
                {result.status} {debt !== 0 ? `(${t('Debt')}: ${debt} ${currencySymbol})` : ''}
              </div>
              <Progress
                size={['100%', 12]}
                percent={percents.credited + percents.paid}
                success={{ percent: percents.paid }}
                strokeColor="var(--warn)"
                trailColor={result.status === InvoiceStatus.OVERDUE ? 'var(--error)' : 'var(--primaryLight)'}
                showInfo={false}
              />
              <div className="w-full flex flex-row justify-around">
                {!!result.paidAmount && (
                  <span className="text-primary font-normal">
                    {t('Paid')}: {result.paidAmount} {currencySymbol}
                  </span>
                )}
                {!!result.creditedAmount && (
                  <span className="text-primary font-normal">
                    {t('Credited')}: {result.creditedAmount} {currencySymbol}
                  </span>
                )}
              </div>
            </div>
          );
        },
      },
      {
        title: t('Due date'),
        key: 'dueDate',
        width: '10%',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (_, record) => <div className="text-primary font-normal">{getClientDateFormat(record.dueDate)}</div>,
      },
      {
        title: t('Created date'),
        key: 'createdAt',
        width: '10%',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (_, record) => <div className="text-primary font-normal">{getClientDateFormat(record.createdAt)}</div>,
      },
    ],
    [t, statusRefDate, goToUserContracts, openContractDetails, currencySymbol],
  );

  const tableActions: Array<ServerTableActions<Invoice>> = useMemo(
    () => [
      {
        name: t('Export', { ns: 'common' }),
        theme: 'secondary',
        icon: <ExportIcon />,
        iconPosition: 'prev',
        onClick: () => {
          openExportModal({
            filters: transformInvoicesListFilters(appliedFilters),
            columns: getInvoiceColumnKeys(t),
            entity: ExportEntity.INVOICE,
          });
        },
      },
      {
        name: t('Download PDF', { ns: 'common' }),
        theme: 'secondary',
        onClick: () => {
          const invoiceLink = generateInvoicesPdfLink(transformInvoicesListFilters(appliedFilters));

          window.open(invoiceLink);
        },
      },
      {
        name: t('Filters', { ns: 'common' }),
        icon: <FiltersIcon />,
        theme: 'secondary',
        iconPosition: 'prev',
        onClick: () => {
          setFiltersOpened(true);
        },
      },
    ],
    [appliedFilters, openExportModal, setFiltersOpened, t],
  );

  // TO DO: add Invoice payment status column with color batces (as there are 7 Invoice statuses)
  const highlightRow = (record: Invoice): string => {
    return classNames({
      'bg-secondary': record.contract && !record.contract.isApplicable,
    });
  };

  return (
    <div className="pt-4">
      <div className="font-semibold text-3xl mb-7">{t('Invoices')}</div>
      <ServerTable
        columns={columns}
        rowActions={rowActions}
        fetchData={useGetPaginatedInvoicesQuery}
        rowKey="invoiceId"
        search
        tableActionsPosition="prev"
        rowClassName={highlightRow}
        searchPlaceholder={t('Enter customer name or invoice | contract number')}
        tableActions={tableActions}
        tags={tags}
        onAllFiltersClear={clearAllFilters}
        defaultFilters={transformInvoicesListFilters(appliedFilters)}
      />
      <ContractDetailsDrawer />
      <ExportModal />
      <InvoicesListFilter />
    </div>
  );
});
