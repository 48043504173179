import React, { FC, memo, useCallback } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { TableFilter, useTableFilterContext } from 'features/TableFilter';
import { WarehouseSelect } from './WarehouseSelect';
import { InvoicesInsuranceListFilterKeys } from '../../model/types';
import { RangeDatePicker } from 'shared/ui/RangeDatePicker/RangeDatePicker';
import { CLIENT_DATE_FORMAT } from 'shared/utils/constants';
import { InvoiceStatusSelect } from './InvoiceStatusSelect';
import { ApplicabilitySelect } from './ApplicabilitySelect';

export const InsuranceListFilter: FC = memo(() => {
  const { t } = useAppTranslation(['contracts', 'common']);

  const { filters, changeFilters, clearCurrentFilters } = useTableFilterContext<InvoicesInsuranceListFilterKeys>();

  const selectWarehouse = useCallback(
    (value: string[]) => {
      changeFilters({
        key: InvoicesInsuranceListFilterKeys.selectedWarehouses,
        label: `${t('Selected')}: ${value.length}`,
        value,
        title: t('Warehouse'),
      });
    },
    [changeFilters, t],
  );

  const changeCreationDateFrom = useCallback(
    (value: Nullable<Dayjs>) => {
      const clientFormatDateFrom = dayjs(value).startOf('day').format(CLIENT_DATE_FORMAT);

      changeFilters({
        key: InvoicesInsuranceListFilterKeys.creationDateFrom,
        label: t('From {{creationDateFrom}}', { creationDateFrom: clientFormatDateFrom }),
        value,
        title: t('Creation date'),
      });
    },
    [changeFilters, t],
  );

  const changeCreationDateTo = useCallback(
    (value: Nullable<Dayjs>) => {
      const clientFormatDateTo = dayjs(value).startOf('day').format(CLIENT_DATE_FORMAT);

      changeFilters({
        key: InvoicesInsuranceListFilterKeys.creationDateTo,
        label: t('To {{creationDateTo}}', { creationDateTo: clientFormatDateTo }),
        value,
        title: t('Creation date'),
      });
    },
    [changeFilters, t],
  );

  const changeInvoiceStatus = useCallback(
    (value: string, label: string) => {
      changeFilters({ key: InvoicesInsuranceListFilterKeys.status, label, value, title: t('Status') });
    },
    [changeFilters, t],
  );

  const changeContractApplicability = useCallback(
    (value: string, label: string) => {
      changeFilters({ key: InvoicesInsuranceListFilterKeys.isApplicable, label, value, title: t('Applicability') });
    },
    [changeFilters, t],
  );

  return (
    <TableFilter>
      <div className="space-y-2">
        <WarehouseSelect values={filters.selectedWarehouses?.value} onChange={selectWarehouse} onClear={clearCurrentFilters} />

        <RangeDatePicker
          placeholder={[t('From'), t('To')]}
          label={t('Creation range')}
          onChangeFrom={changeCreationDateFrom}
          onChangeTo={changeCreationDateTo}
          values={[
            filters.creationDateFrom?.value && dayjs(filters.creationDateFrom?.value),
            filters.creationDateTo?.value && dayjs(filters.creationDateTo?.value),
          ]}
        />

        <div className="flex space-x-3 items-center">
          <InvoiceStatusSelect value={filters.status?.value} onChange={changeInvoiceStatus} onClear={clearCurrentFilters} />

          <ApplicabilitySelect value={filters.isApplicable?.value} onChange={changeContractApplicability} onClear={clearCurrentFilters} />
        </div>
      </div>
    </TableFilter>
  );
});
