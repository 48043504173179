import { Divider } from 'antd';
import React, { FC, memo } from 'react';
import { AccountTypeSetting } from 'entities/RentOption';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { getLocalizedString } from 'shared/utils/helpers/JSONLocalization';
import { PromotionEventTheme } from 'features/SpecialPromotion';

interface PromotionSlideProps {
  label: string;
  accountTypeSetting: AccountTypeSetting;
  description?: string;
  eventTheme?: PromotionEventTheme;
}

interface EventThemeParams {
  border: string;
  background: string;
  labelColor: string;
  descriptionColor: string;
  dividerColor: string;
  badgeText: string;
  badgeBackground: string;
}

export const PromotionSlide: FC<PromotionSlideProps> = memo((props) => {
  const { label, description, accountTypeSetting, eventTheme = 'default' } = props;

  const { t } = useAppTranslation('booking');

  const accountTypesMap: Record<AccountTypeSetting, string> = {
    [AccountTypeSetting.ALL]: t('For business and personal use'),
    [AccountTypeSetting.PERSONAL]: t('For personal use'),
    [AccountTypeSetting.BUSINESS]: t('For business'),
  };

  const eventThemeMap: Record<PromotionEventTheme, EventThemeParams> = {
    default: {
      border: 'border-accent',
      background: 'bg-none',
      labelColor: 'text-accent',
      descriptionColor: 'text-primaryLight',
      dividerColor: 'border-secondaryAccent',
      badgeBackground: 'bg-accentLight',
      badgeText: 'text-accent',
    },
    blackFriday: {
      border: 'border-primary',
      background: 'bg-gradient-to-r from-[#636363] to-[#252425]',
      labelColor: 'text-white',
      descriptionColor: 'text-white',
      dividerColor: 'border-accentLight',
      badgeBackground: 'bg-primary',
      badgeText: 'text-white',
    },
    christmas: {
      border: 'border-error',
      background: 'bg-gradient-to-r from-[#FF5657] to-[#B51111]',
      labelColor: 'text-white',
      descriptionColor: 'text-white',
      dividerColor: 'border-accentLight',
      badgeBackground: 'bg-white',
      badgeText: 'text-error',
    },
    newYear: {
      border: 'border-warn',
      background: 'bg-gradient-to-r from-[#F2C94C] to-[#F2994A]',
      labelColor: 'text-white',
      descriptionColor: 'text-white',
      dividerColor: 'border-accentLight',
      badgeBackground: 'bg-white',
      badgeText: 'text-warn',
    },
    cleverFox: {
      border: 'border-warn',
      background: 'bg-gradient-to-r from-[#F2962A] to-[#FA7B34]',
      labelColor: 'text-white',
      descriptionColor: 'text-white',
      dividerColor: 'border-accentLight',
      badgeBackground: 'bg-white',
      badgeText: 'text-warn',
    },
    valentinesDay: {
      border: 'border-[#CC147C]',
      background: 'bg-gradient-to-r from-[#CC147C] to-[#F10956]',
      labelColor: 'text-white',
      descriptionColor: 'text-white',
      dividerColor: 'border-accentLight',
      badgeBackground: 'bg-white',
      badgeText: 'text-[#CC147C]',
    },
  };

  return (
    <div
      className={`flex justify-between space-x-3.5 border ${eventThemeMap[eventTheme].border} ${eventThemeMap[eventTheme].background} rounded-lg p-4`}
    >
      <div className="flex flex-col flex-1">
        <div className={`font-semibold text-lg ${eventThemeMap[eventTheme].labelColor}`}>{getLocalizedString(label)}</div>
        <div className={`text-sm ${eventThemeMap[eventTheme].descriptionColor}`}>{getLocalizedString(description)}</div>
        <div className="max-w-[40%]">
          <Divider className={`my-3.5 ${eventThemeMap[eventTheme].dividerColor}`} />
        </div>
        <div
          className={`font-normal text-xs rounded-2xl ${eventThemeMap[eventTheme].badgeBackground} ${eventThemeMap[eventTheme].badgeText} w-fit px-3 py-1`}
        >
          {accountTypesMap[accountTypeSetting]}
        </div>
      </div>
    </div>
  );
});
