import { KonvaEventObject } from 'konva/lib/Node';
import { useCallback, useState } from 'react';

interface HookApi {
  onMouseWheel: (e: KonvaEventObject<WheelEvent>) => void;
  canvasPosition: { x: number; y: number };
  canvasScale: number;
}

const SCALE_LIMIT = { min: 0.1, max: 1 };
const SCALE_SPEED = 1.1;

const getLimitedScale = (currScale: number, min: number, max: number): number => Math.max(min, Math.min(max, currScale));

export const useCanvasScale = (): HookApi => {
  const [canvasPosition, setCanvasPosition] = useState({ x: 0, y: 0 });
  const [canvasScale, setCanvasScale] = useState(1);

  const onMouseWheel = useCallback((e: KonvaEventObject<WheelEvent>) => {
    e.evt.preventDefault();

    const stage = e.target.getStage();

    if (!stage) return;

    const oldScale = stage.scaleX();
    const cursorPosition = stage.getPointerPosition();

    if (!cursorPosition) return;

    const mousePointTo = {
      x: (cursorPosition.x - stage.x()) / oldScale,
      y: (cursorPosition.y - stage.y()) / oldScale,
    };

    const newScale = e.evt.deltaY < 0 ? oldScale * SCALE_SPEED : oldScale / SCALE_SPEED;

    const finalScale = getLimitedScale(newScale, SCALE_LIMIT.min, SCALE_LIMIT.max);

    setCanvasScale(finalScale);
    setCanvasPosition({
      x: cursorPosition.x - mousePointTo.x * finalScale,
      y: cursorPosition.y - mousePointTo.y * finalScale,
    });
  }, []);

  return {
    canvasPosition,
    canvasScale,
    onMouseWheel,
  };
};
