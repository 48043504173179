import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { showNotification } from 'app/providers/NotificationsProvider';
import { AddUserNoteParams, useAddUserNoteMutation } from 'entities/User';
import { FC, memo, useCallback, useState } from 'react';
import { Button } from 'shared/ui/Button';
import { Drawer } from 'shared/ui/Drawer';
import { Paragraph } from 'shared/ui/Paragraph';
import { TextArea } from 'shared/ui/TextArea';
import { getUserNoteDrawerState } from '../model/selectors/getUserNoteDrawerState';
import { userNoteDrawerActions } from '../model/slice/userNoteDrawerSlice';

export const UserNoteDrawer: FC = memo(() => {
  const dispatch = useAppDispatch();
  const { t } = useAppTranslation(['user', 'common']);

  const { user, isNote, isDebtCollectionNote } = useAppSelector(getUserNoteDrawerState);

  const [newNote, setNewNote] = useState<Nullable<string>>(null);

  const [addNote, { isLoading }] = useAddUserNoteMutation();

  const closeUserNoteDrawer = useCallback((): void => {
    dispatch(userNoteDrawerActions.setOpenUserNoteDrawer({ isDebtCollectionNote: false, user: null, isNote: false }));
  }, [dispatch]);

  const handleChangeNote = useCallback((note: string) => {
    setNewNote(note);
  }, []);

  const handleAddUserNote = useCallback(async (): Promise<void> => {
    try {
      if (user && newNote) {
        const newNoteData: AddUserNoteParams = {};

        if (isDebtCollectionNote) {
          newNoteData.debtCollectionNote = newNote;
        }

        if (isNote) {
          newNoteData.note = newNote;
        }

        await addNote({ userId: user.userId, params: newNoteData }).unwrap();
        showNotification('info', t('Success'), t('user note added successfully'));

        closeUserNoteDrawer();

        setNewNote(null);
      }
    } catch (error) {
      showNotification('error', t('Error'), t('Error when adding user note contract'));
      console.log('error', error);
    }
  }, [user, newNote, isDebtCollectionNote, isNote, addNote, t, closeUserNoteDrawer]);

  const paragraphs = isDebtCollectionNote ? user?.debtCollectionNote?.split('\n') : user?.note?.split('\n');

  const paragraphElements = paragraphs?.map((paragraph: string, index: number) => (
    <p key={index} className="font-normal !mb-1">
      {paragraph}
    </p>
  ));

  return (
    <Drawer width={450} open={Boolean(user)} onClose={closeUserNoteDrawer}>
      <div className="flex flex-col px-10 py-4">
        <div className="flex justify-between items-center pt-4 pb-10">
          <div className="font-semibold text-3xl">{t(isDebtCollectionNote ? 'Debt Collection Notes' : 'Notes')}</div>
          <div className="text-primaryLight font-normal cursor-pointer" onClick={closeUserNoteDrawer}>
            {t('Close')}
          </div>
        </div>

        <div className="flex flex-col gap-8">
          <div>
            <div className="mb-5">
              <div className="mb-2">{t('Note')}</div>
              <TextArea placeholder={t('Add your note...')} rows={4} bordered value={newNote} onChange={handleChangeNote} />
            </div>
            <Button onClick={handleAddUserNote} isLoading={isLoading} isDisabled={!user || !newNote}>
              {t('Add Note')}
            </Button>
          </div>

          <div>
            <div className="mb-2">{t('Note history')}:</div>
            <Paragraph expandable={false} className="whitespace-normal">
              {paragraphElements}
            </Paragraph>
          </div>
        </div>
      </div>
    </Drawer>
  );
});
