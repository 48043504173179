import React, { FC, memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'app/config/routerConfig/types';
import { useGetRentOptionsQuery } from 'entities/RentOption';
import { useSpecialPromotionModal } from 'features/SpecialPromotion';
import { Button } from 'shared/ui/Button';
import { ReactComponent as ValentinesPromotionIcon } from 'shared/assets/icons/ValentinesPromotionIcon.svg';
import { ReactComponent as ValentinesPromotionMobileIcon } from 'shared/assets/icons/ValentinesPromotionMobileIcon.svg';
import ValentinesPromotionHeart from 'shared/assets/images/ValentinesPromotionHeart.png';
import ValentinesPromotionBackground from 'shared/assets/images/ValentinesPromotionBackground.png';
import { useScreenBreakpoints } from 'shared/utils/hooks/useScreenBreakpoints';

export const ValentinesDayPromotionContent: FC = memo(() => {
  const navigate = useNavigate();

  const { data } = useGetRentOptionsQuery({ isSpecialPromotion: true });
  const { closeModal } = useSpecialPromotionModal();

  const { isDesktop } = useScreenBreakpoints();

  const goToBooking = useCallback(() => {
    navigate(data?.length ? `${AppRoutes.BOOKING}?rentOptionId=${data[0].rentOptionId}` : AppRoutes.BOOKING);
    closeModal();
  }, [closeModal, data, navigate]);

  const mobileView = (): JSX.Element => {
    return (
      <div className="relative flex flex-col items-center desktop:w-[1000px] my-15 rounded-xl p-6">
        <div className="text-[32px] font-greatVibes text-[#DD4742] mb-4 mt-10">Happy Valentine’s Day</div>
        <ValentinesPromotionMobileIcon className="shrink-0 mb-4" />
        <div className="font-lora text-[16px] text-[#DD4742] mb-1 text-center">Herzenssache: Mehr Platz für Sie</div>
        <div className="text-[#DE6F7F] text-center font-lora text-[12px] [&>*]:text-[12px] mb-3.5">
          <span className="text-[#DD4742]">Jede Lagerbox nur 14 € für den ersten Monat!</span>
          <br />
          Am Valentinstag geht es um die Menschen, die <br /> wir lieben.
          <br />
          Wir kümmern uns um den Platz, damit Sie mehr <br /> Raum für das haben, was wirklich zählt. <br /> Vom
          <span className="text-[#DD4742]"> 13. bis 17. Februar</span> zahlen Sie für den ersten <br /> Monat Ihrer Lagerbox nur{' '}
          <span className="text-[#DD4742]">14 €.</span>
        </div>
        <Button containerClassName="w-fit m-auto" className="!bg-[#DD4742]" onClick={goToBooking}>
          Jetzt Platz sichern und entspannt leben!
        </Button>
      </div>
    );
  };

  const desktopView = (): JSX.Element => {
    return (
      <div className="relative flex flex-col items-center w-[380px] h-[753px] desktop:w-[1000px] my-15 rounded-xl p-6">
        <div className="relative w-full h-full">
          <ValentinesPromotionIcon className="absolute top-1 left-[calc(33%-10px)] -translate-x-1/2 z-10" />
          <ValentinesPromotionIcon className="absolute top-1 left-[calc(66%+20px)] -translate-x-1/2 z-10" />
          <img className="absolute top-0 left-1/2 -translate-x-1/2 h-full" src={ValentinesPromotionHeart} alt="Heart" />
          <img className="absolute top-40 left-1/2 -translate-x-1/2" src={ValentinesPromotionBackground} alt="Hearts" />
          <div className="absolute top-[350px] w-3/4 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
            <div className="text-[56px] font-greatVibes text-[#DD4742] mb-7">Happy Valentine’s Day</div>
            <div className="font-lora text-[30px] text-[#DD4742] mb-2.5">Herzenssache: Mehr Platz für Sie</div>
            <div className="text-[#DE6F7F] font-lora text-[24px] [&>*]:text-[24px] mb-7">
              <span className="text-[#DD4742]">Jede Lagerbox nur 14 € für den ersten Monat!</span>
              <br />
              Am Valentinstag geht es um die Menschen, die wir lieben.
              <br />
              Wir kümmern uns um den Platz, damit Sie mehr Raum für <br /> das haben, was wirklich zählt. <br /> Vom
              <span className="text-[#DD4742]"> 13. bis 17. Februar</span> zahlen Sie für den ersten Monat <br /> Ihrer Lagerbox nur{' '}
              <span className="text-[#DD4742]">14 €.</span>
            </div>
            <Button containerClassName="w-fit m-auto" className="!bg-[#DD4742]" onClick={goToBooking}>
              Jetzt Platz sichern und entspannt leben!
            </Button>
          </div>
        </div>
      </div>
    );
  };

  return isDesktop ? desktopView() : mobileView();
});
