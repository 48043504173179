import { ColorPicker, Divider } from 'antd';
import { Color } from 'antd/lib/color-picker';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { Box, CreateBoxParams, useCreateBoxesMutation, useUpdateBoxesMutation, UpdateBoxesParams } from 'entities/Box';
import { useSaveMapConfigMutation } from 'entities/Floor';
import { SizeCode } from 'entities/SizeCode';
import { Stage } from 'konva/lib/Stage';
import React, { FC, memo, useCallback } from 'react';
import { ReactComponent as AngleIcon } from 'shared/assets/icons/AngleIcon.svg';
import { ReactComponent as DeleteIcon } from 'shared/assets/icons/DeleteIcon.svg';
import { ReactComponent as LockIcon } from 'shared/assets/icons/LockIcon.svg';
import { ReactComponent as StrokeWidthIcon } from 'shared/assets/icons/StrokeWidthIcon.svg';
import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';
import { roundNumber } from 'shared/utils/helpers/roundNumber';
import { getGridCellSize } from '../../model/selectors/getGridCellSize';
import { warehouseMapConstructorActions } from '../../model/slice/warehouseMapConstructorSlice';
import { Shape, ShapeType } from '../../model/types';
import { ShapeChangesResult } from '../../utils/helpers/checkShapesChanges';
import { getNearestSizeCode } from '../../utils/helpers/getNearestSizeCode';
import { BoxPicker } from './BoxPicker';
import { SizeCodeSelect } from './SizeCodeSelect';

interface ParametersBarProps {
  selectedShape: Shape | undefined;
  floorId: string;
  warehouseId: string | undefined;
  stage: Nullable<Stage>;
  floorShapes: Shape[];
  isShapesChanged: boolean;
  shapesChanges: ShapeChangesResult;
  availableSizeCodes: SizeCode[] | undefined;
  refetchFloors: () => void;
}

export const ParametersBar: FC<ParametersBarProps> = memo((props) => {
  const { selectedShape, floorId, warehouseId, stage, floorShapes, refetchFloors, availableSizeCodes, shapesChanges, isShapesChanged } =
    props;

  const { t } = useAppTranslation('warehouse-management');
  const dispatch = useAppDispatch();

  const gridCellSize = useAppSelector(getGridCellSize);

  const [saveMapConfigService, { isLoading }] = useSaveMapConfigMutation();
  const [createBoxesService] = useCreateBoxesMutation();
  const [updateBoxesService] = useUpdateBoxesMutation();

  const newBoxShapes = shapesChanges.newElements.filter((shape) => shape.type === ShapeType.RECTANGLE);
  const newPolygonBoxShapes = shapesChanges.newElements.filter((shape) => shape.type === ShapeType.POLYGON_BOX);
  const updatedBoxShapes = shapesChanges.updatedElements.filter((shape) => shape.type === ShapeType.RECTANGLE);
  const updatedPolygonBoxShapes = shapesChanges.updatedElements.filter((shape) => shape.type === ShapeType.POLYGON_BOX);

  const saveMapConfig = useCallback(
    async (boxes?: Box[]) => {
      if (warehouseId && stage) {
        const shapesWithCreatedBoxIds = floorShapes.map((shape) => {
          if ('label' in shape) {
            const targetShape = boxes?.find((createdBox) => createdBox.name === shape.label);

            if (targetShape) {
              return {
                ...shape,
                id: targetShape.boxId,
              };
            }

            return shape;
          }

          return shape;
        });

        const shapesConfig = JSON.stringify(shapesWithCreatedBoxIds);
        await saveMapConfigService({ floorId, warehouseId, args: { shapesConfig } }).unwrap();
        refetchFloors();
      }
    },
    [floorId, floorShapes, refetchFloors, saveMapConfigService, stage, warehouseId],
  );

  const createBoxes = useCallback(async (): Promise<Box[]> => {
    const newBoxes: CreateBoxParams[] = newBoxShapes.reduce((acc: CreateBoxParams[], curr) => {
      if (curr.type === ShapeType.RECTANGLE && curr.sizeCodeId && warehouseId) {
        const nearestSizeCode = getNearestSizeCode({ shapeWidth: curr.width, shapeLength: curr.height, availableSizeCodes, gridCellSize });

        acc.push({
          warehouseId,
          sizeCodeId: curr.sizeCodeId,
          floorId: curr.floorId,
          name: curr.label,
          length: curr.height / gridCellSize,
          width: curr.width / gridCellSize,
          monthRate: nearestSizeCode?.monthRate,
          dailyRate: nearestSizeCode?.dailyRate,
          weekRate: nearestSizeCode?.weekRate,
        });

        return acc;
      }

      return [];
    }, []);
    const newPolygonBoxes: CreateBoxParams[] = newPolygonBoxShapes.reduce((acc: CreateBoxParams[], curr) => {
      if (curr.type === ShapeType.POLYGON_BOX && curr.sizeCodeId && warehouseId) {
        const findPolygonDimensions = (points: number[]): { maxX: number; maxY: number } => {
          let maxX = -Infinity;
          let maxY = -Infinity;

          for (let i = 0; i < points.length; i += 2) {
            const x = points[i];
            const y = points[i + 1];

            if (x > maxX) {
              maxX = x;
            }
            if (y > maxY) {
              maxY = y;
            }
          }

          return { maxX, maxY };
        };

        const { maxX, maxY } = findPolygonDimensions(curr.points);

        const nearestSizeCode = getNearestSizeCode({ shapeWidth: maxX, shapeLength: maxY, availableSizeCodes, gridCellSize });

        acc.push({
          warehouseId,
          sizeCodeId: curr.sizeCodeId,
          floorId: curr.floorId,
          name: curr.label,
          length: maxY / gridCellSize,
          width: maxX / gridCellSize,
          monthRate: nearestSizeCode?.monthRate,
          dailyRate: nearestSizeCode?.dailyRate,
          weekRate: nearestSizeCode?.weekRate,
        });

        return acc;
      }

      return [];
    }, []);

    return await createBoxesService([...newBoxes, ...newPolygonBoxes]).unwrap();
  }, [availableSizeCodes, createBoxesService, gridCellSize, newBoxShapes, newPolygonBoxShapes, warehouseId]);

  const updateBoxes = useCallback(async (): Promise<Box[]> => {
    const updatedBoxes: Array<Partial<Box>> = updatedBoxShapes.reduce((acc: UpdateBoxesParams[], curr) => {
      if (curr.type === ShapeType.RECTANGLE && warehouseId && curr.sizeCodeId) {
        acc.push({
          id: curr.id,
          warehouseId,
          sizeCodeId: curr.sizeCodeId,
          name: curr.label,
          length: curr.height / gridCellSize,
          width: curr.width / gridCellSize,
        });

        return acc;
      }

      return [];
    }, []);
    const updatedPolygonBoxes: Array<Partial<Box>> = updatedPolygonBoxShapes.reduce((acc: UpdateBoxesParams[], curr) => {
      if (curr.type === ShapeType.POLYGON_BOX && warehouseId && curr.sizeCodeId) {
        const findPolygonDimensions = (points: number[]): { maxX: number; maxY: number } => {
          let maxX = -Infinity;
          let maxY = -Infinity;

          for (let i = 0; i < points.length; i += 2) {
            const x = points[i];
            const y = points[i + 1];

            if (x > maxX) {
              maxX = x;
            }
            if (y > maxY) {
              maxY = y;
            }
          }

          return { maxX, maxY };
        };

        const { maxX, maxY } = findPolygonDimensions(curr.points);

        acc.push({
          id: curr.id,
          warehouseId,
          sizeCodeId: curr.sizeCodeId,
          name: curr.label,
          length: maxY / gridCellSize,
          width: maxX / gridCellSize,
        });

        return acc;
      }

      return [];
    }, []);

    return await updateBoxesService([...updatedBoxes, ...updatedPolygonBoxes]).unwrap();
  }, [gridCellSize, updateBoxesService, updatedBoxShapes, updatedPolygonBoxShapes, warehouseId]);

  const onSaveConfig = useCallback(async (): Promise<void> => {
    const needToCreateBoxes = Boolean(newBoxShapes.length || newPolygonBoxShapes.length);
    const needToUpdateBoxes = Boolean(shapesChanges.updatedElements.length);

    if (needToCreateBoxes) {
      const createdBoxes = await createBoxes();
      await saveMapConfig(createdBoxes);
    }

    if (needToUpdateBoxes) {
      const updatedBoxes = await updateBoxes();
      await saveMapConfig(updatedBoxes);
    }

    if (!needToUpdateBoxes && !needToCreateBoxes) {
      await saveMapConfig();
    }
  }, [createBoxes, newBoxShapes.length, newPolygonBoxShapes.length, saveMapConfig, shapesChanges.updatedElements.length, updateBoxes]);

  const label = selectedShape && 'label' in selectedShape ? selectedShape.label : undefined;
  const rotation = selectedShape ? roundNumber(selectedShape.rotation, 0).toString() : undefined;
  const x = selectedShape ? roundNumber(selectedShape.x, 0).toString() : undefined;
  const y = selectedShape ? roundNumber(selectedShape.y, 0).toString() : undefined;
  const width =
    selectedShape && 'width' in selectedShape
      ? (roundNumber(selectedShape.width * selectedShape.scaleX, 0) / gridCellSize).toString()
      : undefined;
  const height =
    selectedShape && 'height' in selectedShape
      ? (roundNumber(selectedShape.height * selectedShape.scaleY, 0) / gridCellSize).toString()
      : undefined;
  const radiusX =
    selectedShape && 'radiusX' in selectedShape
      ? (roundNumber(selectedShape.radiusX * selectedShape.scaleX, 0) / gridCellSize).toString()
      : undefined;
  const radiusY =
    selectedShape && 'radiusY' in selectedShape
      ? (roundNumber(selectedShape.radiusY * selectedShape.scaleY, 0) / gridCellSize).toString()
      : undefined;
  const color = selectedShape && 'stroke' in selectedShape ? selectedShape.stroke : undefined;
  const strokeWidth = selectedShape && 'strokeWidth' in selectedShape ? roundNumber(selectedShape.strokeWidth, 0).toString() : undefined;
  const sizeCodeId = selectedShape ? selectedShape.sizeCodeId : undefined;
  const fontSize = selectedShape && 'fontSize' in selectedShape ? selectedShape.fontSize?.toString() : undefined;

  const changeLabel = useCallback(
    (value: string): void => {
      dispatch(warehouseMapConstructorActions.changeShapeLabel(value));
    },
    [dispatch],
  );

  const changeRotation = useCallback(
    (value: string): void => {
      dispatch(warehouseMapConstructorActions.changeShapeRotation(Number(value)));
    },
    [dispatch],
  );

  const changeX = useCallback(
    (value: string): void => {
      dispatch(warehouseMapConstructorActions.changeShapeX(Number(value)));
    },
    [dispatch],
  );

  const changeY = useCallback(
    (value: string): void => {
      dispatch(warehouseMapConstructorActions.changeShapeY(Number(value)));
    },
    [dispatch],
  );

  const changeWidth = useCallback(
    (value: string): void => {
      dispatch(warehouseMapConstructorActions.changeShapeWidth(Number(value) * gridCellSize));
    },
    [dispatch, gridCellSize],
  );

  const changeHeight = useCallback(
    (value: string): void => {
      dispatch(warehouseMapConstructorActions.changeShapeHeight(Number(value) * gridCellSize));
    },
    [dispatch, gridCellSize],
  );

  const changeRadiusX = useCallback(
    (value: string): void => {
      dispatch(warehouseMapConstructorActions.changeShapeRadiusX(Number(value) * gridCellSize));
    },
    [dispatch, gridCellSize],
  );

  const changeRadiusY = useCallback(
    (value: string): void => {
      dispatch(warehouseMapConstructorActions.changeShapeRadiusY(Number(value) * gridCellSize));
    },
    [dispatch, gridCellSize],
  );

  const changeStrokeWidth = useCallback(
    (value: string): void => {
      dispatch(warehouseMapConstructorActions.changeShapeStrokeWidth(Number(value)));
    },
    [dispatch],
  );

  const changeStrokeColor = useCallback(
    (value: Color): void => {
      dispatch(warehouseMapConstructorActions.changeShapeStrokeColor(value.toRgbString()));
    },
    [dispatch],
  );

  const changeSizeCode = useCallback(
    (value: string): void => {
      dispatch(warehouseMapConstructorActions.changeSizeCode(value));
    },
    [dispatch],
  );

  const changeFontSize = useCallback(
    (value: string): void => {
      dispatch(warehouseMapConstructorActions.changeFontSize(Number(value)));
    },
    [dispatch],
  );

  const deleteShape = useCallback((): void => {
    selectedShape?.onDelete?.();
  }, [selectedShape]);

  const lockShape = useCallback((): void => {
    if (selectedShape) {
      dispatch(warehouseMapConstructorActions.toggleLockShape(selectedShape.id));
    }
  }, [dispatch, selectedShape]);

  return (
    <div className="absolute z-10 top-4 bottom-4 right-4 bg-white p-7 rounded-xl max-w-[384px] overflow-y-auto">
      <div className="mb-3.5">{t('Parameters')}</div>
      <div className="space-y-2.5">
        <div className="flex items-center space-x-2.5 [&>div]:flex-1">
          <Input size="extraSmall" bordered prefix={t('Label')} value={label} onChange={changeLabel} />
          <Input size="extraSmall" bordered prefix={<AngleIcon />} value={rotation} onChange={changeRotation} />
        </div>
        <div className="flex items-center space-x-2.5">
          <Input size="extraSmall" bordered prefix={t('X')} value={x} onChange={changeX} />
          <Input size="extraSmall" bordered prefix={t('Y')} value={y} onChange={changeY} />
        </div>
        <div className="flex items-center space-x-2.5">
          {!radiusX && !radiusY ? (
            <>
              <Input size="extraSmall" bordered prefix={t('W')} value={width} disabled={!width} onChange={changeWidth} />
              <Input size="extraSmall" bordered prefix={t('H')} value={height} disabled={!height} onChange={changeHeight} />
            </>
          ) : (
            <>
              <Input size="extraSmall" bordered prefix={t('Radius X')} value={radiusX} onChange={changeRadiusX} />
              <Input size="extraSmall" bordered prefix={t('Radius Y')} value={radiusY} onChange={changeRadiusY} />
            </>
          )}
        </div>
        {selectedShape?.type === ShapeType.RECTANGLE && (
          <SizeCodeSelect warehouseId={warehouseId} value={sizeCodeId} onChange={changeSizeCode} />
        )}
        {selectedShape?.type === ShapeType.TEXT && (
          <Input size="extraSmall" bordered prefix={t('Font size')} value={fontSize} onChange={changeFontSize} />
        )}
        {selectedShape && (
          <div className="flex items-center space-x-3 !mt-8">
            <DeleteIcon className="cursor-pointer" onClick={deleteShape} />
            <LockIcon className={`${selectedShape.locked ? 'stroke-error' : 'stroke-primary'} cursor-pointer`} onClick={lockShape} />
          </div>
        )}
      </div>
      <Divider className="border-secondaryAccent" />
      <div className="mb-3.5">{t('Stroke')}</div>
      <div className="flex items-center space-x-2.5">
        <ColorPicker
          className="shrink-0 h-[28px] [&>.ant-color-picker-color-block]:w-[20px] [&>.ant-color-picker-color-block]:h-[20px]"
          defaultValue="black"
          showText={(color) => (
            <div>
              {color.toHexString().toUpperCase()}
              <span className="text-secondaryLight inline-block mx-3.5">|</span>
              {roundNumber(color.toRgb().a * 100, 0)}%
            </div>
          )}
          value={color}
          onChange={changeStrokeColor}
        />
        <Input size="extraSmall" bordered prefix={<StrokeWidthIcon />} value={strokeWidth} onChange={changeStrokeWidth} />
      </div>
      <Divider className="border-secondaryAccent" />
      <div className="mb-3.5">{t('Elements')}</div>
      <BoxPicker floorId={floorId} />
      <Button containerClassName="mt-3" onClick={onSaveConfig} isLoading={isLoading} isDisabled={!isShapesChanged}>
        {t('Save')}
      </Button>
    </div>
  );
});
