import { MutableRefObject, useEffect, useRef, useState } from 'react';

interface HookApi {
  stageParentRef: MutableRefObject<Nullable<HTMLDivElement>>;
  canvasDimensions: { width: number; height: number };
}

export const useCanvasDimensions = (): HookApi => {
  const stageParentRef = useRef<Nullable<HTMLDivElement>>(null);

  const [canvasDimensions, setCanvasDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const containerWidth = stageParentRef.current?.offsetWidth || 0;
    const containerHeight = stageParentRef.current?.offsetHeight || 0;

    setCanvasDimensions({ width: containerWidth, height: containerHeight });
  }, []);

  return {
    stageParentRef,
    canvasDimensions,
  };
};
