import { roundNumber } from 'shared/utils/helpers/roundNumber';

interface InvoicePercents {
  paid: number;
  credited: number;
}

export const calculateInvoicePercents = (amount: number, paid: number, credited: number): InvoicePercents => {
  return {
    paid: roundNumber((paid / amount) * 100),
    credited: roundNumber((credited / amount) * 100),
  };
};
