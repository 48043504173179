import React, { FC, memo, useCallback, useMemo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { LeadsListFilterKeys } from 'pages/UsersPage/model/types';
import { LeadStatus } from 'entities/Lead';
import { MultipleSelect } from 'shared/ui/MultipleSelect';

interface InvoiceTypeSelectProps {
  value: string[];
  onChange: (value: string[]) => void;
  onClear: (filterKey: string) => void;
}

export const LeadStatusSelect: FC<InvoiceTypeSelectProps> = memo((props) => {
  const { value, onChange, onClear } = props;

  const { t } = useAppTranslation(['contracts', 'user']);

  const leadStatusesSelectOptions = useMemo<Array<{ label: string; value: LeadStatus }>>(() => {
    return [
      { label: t('Active requests', { ns: 'user' }), value: LeadStatus.ACTIVE },
      { label: t('Reservation', { ns: 'user' }), value: LeadStatus.RESERVATION },
      { label: t('Expired reservation', { ns: 'user' }), value: LeadStatus.EXPIRED_RESERVATION },
      { label: t('Unpaid contract', { ns: 'user' }), value: LeadStatus.NOT_PAID },
      { label: t('Paid contract', { ns: 'user' }), value: LeadStatus.PAID },
      { label: t('Closed contract', { ns: 'user' }), value: LeadStatus.END },
      { label: t('Left', { ns: 'user' }), value: LeadStatus.LEFT },
    ];
  }, [t]);

  const clearFilter = useCallback(() => {
    onClear(LeadsListFilterKeys.leadStatuses);
  }, [onClear]);

  const handleChange = useCallback(
    (value: string[]): void => {
      value.length ? onChange(value) : clearFilter();
    },
    [onChange, clearFilter],
  );

  return (
    <MultipleSelect
      containerClassName="flex-1"
      className="w-full"
      label={t('Lead status', { ns: 'user' })}
      placeholder={t('All')}
      bordered
      allowClear
      options={leadStatusesSelectOptions}
      value={value}
      onChange={handleChange}
      onClear={clearFilter}
    />
  );
});
