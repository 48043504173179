import { SizeCode } from 'entities/SizeCode';
import { roundNumber } from 'shared/utils/helpers/roundNumber';

interface Args {
  shapeWidth: number;
  shapeLength: number;
  availableSizeCodes: SizeCode[] | undefined;
  gridCellSize: number;
}

export const getNearestSizeCode = ({ shapeWidth, shapeLength, availableSizeCodes, gridCellSize }: Args): SizeCode | undefined => {
  const shapeSquare = roundNumber((shapeWidth / gridCellSize) * (shapeLength / gridCellSize));

  const closestSize = availableSizeCodes?.reduce((prev, curr) => {
    return Math.abs(curr.square - shapeSquare) < Math.abs(prev.square - shapeSquare) ? curr : prev;
  });

  return closestSize;
};
