import { api } from 'app/config/apiConfig';
import { User } from '../model/types';
import { USERS_PATH } from '../utils/constants';

interface Params {
  warehouses?: boolean;
  contracts?: boolean;
  reservations?: boolean;
  userDebt?: boolean;
}

export const getUserByIdApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUserById: builder.query<User, { userId: Nullable<string> | undefined; params?: Params }>({
      query: ({ userId, params }) => ({
        url: `${USERS_PATH}/${userId}`,
        params,
      }),
      providesTags: ['Users'],
    }),
  }),
});

export const { useGetUserByIdQuery } = getUserByIdApi;
