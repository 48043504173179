import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isShapeInSelection } from '../../utils/helpers/isShapeInSelection';
import { PickedBoxParams, SelectionBox, Shape, UpdateShapeParamsPayload, WarehouseMapConstructorSchema, WarehouseMapTool } from '../types';

const initialState: WarehouseMapConstructorSchema = {
  selectedFloorId: null,
  activeTool: WarehouseMapTool.POINTER,
  shapes: [],
  pickedBoxParams: null,
  pickerBoxes: [],
  gridCellSize: 20,
};

const warehouseMapConstructorSlice = createSlice({
  name: 'warehouseMapConstructor',
  initialState,
  reducers: {
    changeFloor: (state: WarehouseMapConstructorSchema, action: PayloadAction<string>) => {
      state.selectedFloorId = action.payload;
    },
    changeTool: (state: WarehouseMapConstructorSchema, action: PayloadAction<WarehouseMapTool>) => {
      state.activeTool = action.payload;
    },
    setInitialShapes: (state: WarehouseMapConstructorSchema, action: PayloadAction<Shape[]>) => {
      state.shapes = action.payload;
    },
    appendShape: (state: WarehouseMapConstructorSchema, action: PayloadAction<Shape | Shape[]>) => {
      if (Array.isArray(action.payload)) {
        state.shapes = [
          ...state.shapes.map((shape) => ({ ...shape, selected: false })),
          ...action.payload.map((shape) => ({ ...shape, selected: true })),
        ];
      } else {
        state.shapes = [...state.shapes.map((shape) => ({ ...shape, selected: false })), { ...action.payload, selected: true }];
      }
    },
    selectShape: (state: WarehouseMapConstructorSchema, action: PayloadAction<string>) => {
      state.shapes = state.shapes.map((shape) => ({ ...shape, selected: shape.id === action.payload }));
    },
    unselectShapes: (state: WarehouseMapConstructorSchema) => {
      state.shapes = state.shapes.map((shape) => ({ ...shape, selected: false }));
    },
    selectShapesInArea: (state: WarehouseMapConstructorSchema, action: PayloadAction<SelectionBox>) => {
      state.shapes = state.shapes.map((shape) => ({ ...shape, selected: !shape.locked && isShapeInSelection(shape, action.payload) }));
    },
    deleteShape: (state: WarehouseMapConstructorSchema) => {
      state.shapes = state.shapes.filter((shape) => !shape.selected);
    },
    toggleLockShape: (state: WarehouseMapConstructorSchema, action: PayloadAction<string>) => {
      const shapeToReplace = state.shapes.find((shape) => shape.id === action.payload);

      if (shapeToReplace) {
        // move shape to array start for moving it to the back of canvas
        state.shapes = !shapeToReplace.locked
          ? [shapeToReplace, ...state.shapes.filter((shape) => shape.id !== action.payload)]
          : [...state.shapes.filter((shape) => shape.id !== action.payload), shapeToReplace];
        state.shapes = state.shapes.map((shape) => (shape.id === action.payload ? { ...shape, locked: !shape.locked } : shape));
      }
    },
    updateShapeParams: (
      state: WarehouseMapConstructorSchema,
      action: PayloadAction<{ id: string; params: Partial<UpdateShapeParamsPayload> }>,
    ) => {
      state.shapes = state.shapes.map((shape) => (shape.id === action.payload.id ? { ...shape, ...action.payload.params } : shape));
    },
    changeShapeLabel: (state: WarehouseMapConstructorSchema, action: PayloadAction<string>) => {
      state.shapes = state.shapes.map((shape) => (shape.selected ? { ...shape, label: action.payload } : shape));
    },
    changeShapeRotation: (state: WarehouseMapConstructorSchema, action: PayloadAction<number>) => {
      state.shapes = state.shapes.map((shape) => (shape.selected ? { ...shape, rotation: action.payload } : shape));
    },
    changeShapeX: (state: WarehouseMapConstructorSchema, action: PayloadAction<number>) => {
      state.shapes = state.shapes.map((shape) => (shape.selected ? { ...shape, x: action.payload } : shape));
    },
    changeShapeY: (state: WarehouseMapConstructorSchema, action: PayloadAction<number>) => {
      state.shapes = state.shapes.map((shape) => (shape.selected ? { ...shape, y: action.payload } : shape));
    },
    changeShapeWidth: (state: WarehouseMapConstructorSchema, action: PayloadAction<number>) => {
      state.shapes = state.shapes.map((shape) => (shape.selected ? { ...shape, width: action.payload } : shape));
    },
    changeShapeHeight: (state: WarehouseMapConstructorSchema, action: PayloadAction<number>) => {
      state.shapes = state.shapes.map((shape) => (shape.selected ? { ...shape, height: action.payload } : shape));
    },
    changeShapeRadiusX: (state: WarehouseMapConstructorSchema, action: PayloadAction<number>) => {
      state.shapes = state.shapes.map((shape) => (shape.selected ? { ...shape, radiusX: action.payload } : shape));
    },
    changeShapeRadiusY: (state: WarehouseMapConstructorSchema, action: PayloadAction<number>) => {
      state.shapes = state.shapes.map((shape) => (shape.selected ? { ...shape, radiusY: action.payload } : shape));
    },
    changeShapeStrokeColor: (state: WarehouseMapConstructorSchema, action: PayloadAction<string>) => {
      state.shapes = state.shapes.map((shape) => (shape.selected ? { ...shape, stroke: action.payload } : shape));
    },
    changeShapeStrokeWidth: (state: WarehouseMapConstructorSchema, action: PayloadAction<number>) => {
      state.shapes = state.shapes.map((shape) => (shape.selected ? { ...shape, strokeWidth: action.payload } : shape));
    },
    changeSizeCode: (state: WarehouseMapConstructorSchema, action: PayloadAction<string>) => {
      state.shapes = state.shapes.map((shape) => (shape.selected ? { ...shape, sizeCodeId: action.payload } : shape));
    },
    changeFontSize: (state: WarehouseMapConstructorSchema, action: PayloadAction<number>) => {
      state.shapes = state.shapes.map((shape) => (shape.selected ? { ...shape, fontSize: action.payload } : shape));
    },
    setPickedBoxParams: (state: WarehouseMapConstructorSchema, action: PayloadAction<PickedBoxParams>) => {
      state.pickedBoxParams = action.payload;
    },
    changeGridCellSize: (state: WarehouseMapConstructorSchema, action: PayloadAction<number>) => {
      state.gridCellSize = action.payload;
    },
  },
});

export const { actions: warehouseMapConstructorActions, reducer: warehouseMapConstructorReducer } = warehouseMapConstructorSlice;
