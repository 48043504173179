import React, { FC, memo } from 'react';
import { UsersPageContent } from './UsersPageContent';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { TableFilterContextProvider } from 'features/TableFilter';

export const UsersPage: FC = memo(() => {
  const { t } = useAppTranslation('user');

  return (
    <div className="pt-4">
      <div className="font-semibold text-3xl mb-7">{t('Users')}</div>
      <TableFilterContextProvider>
        <UsersPageContent />
      </TableFilterContextProvider>
    </div>
  );
});
