import React, { FC, memo, useCallback } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { TableFilter, useTableFilterContext } from 'features/TableFilter';
import { WarehouseSelect } from './WarehouseSelect';
import { LeadStatusSelect } from './LeadStatusSelect';
import { RangeDatePicker } from 'shared/ui/RangeDatePicker/RangeDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { CLIENT_DATE_FORMAT } from 'shared/utils/constants';
import { LeadsListFilterKeys } from 'pages/UsersPage/model/types';
import { RangeInput } from 'shared/ui/RangeInput/RangeInput';
import { LeadFirstContactSelect } from './LeadFirstContactSelect';
import { ApplicabilitySelect } from './ApplicabilitySelect';

export const LeadsListFilter: FC = memo(() => {
  const { t } = useAppTranslation(['contracts', 'user']);

  const { filters, changeFilters, clearCurrentFilters } = useTableFilterContext<LeadsListFilterKeys>();

  const selectWarehouse = useCallback(
    (value: string, label: string) => {
      changeFilters({ key: LeadsListFilterKeys.selectedWarehouseId, label, value, title: t('Warehouse') });
    },
    [changeFilters, t],
  );

  const changeCreationDateFrom = useCallback(
    (value: Nullable<Dayjs>) => {
      const clientFormatDateFrom = dayjs(value).startOf('day').format(CLIENT_DATE_FORMAT);

      changeFilters({
        key: LeadsListFilterKeys.creationDateFrom,
        label: t('From {{creationDateFrom}}', { creationDateFrom: clientFormatDateFrom }),
        value,
        title: t('Creation date'),
      });
    },
    [changeFilters, t],
  );

  const changeCreationDateTo = useCallback(
    (value: Nullable<Dayjs>) => {
      const clientFormatDateTo = dayjs(value).startOf('day').format(CLIENT_DATE_FORMAT);

      changeFilters({
        key: LeadsListFilterKeys.creationDateTo,
        label: t('To {{creationDateTo}}', { creationDateTo: clientFormatDateTo }),
        value,
        title: t('Creation date'),
      });
    },
    [changeFilters, t],
  );

  const changeLeadStatus = useCallback(
    (value: string[]) => {
      changeFilters({
        key: LeadsListFilterKeys.leadStatuses,
        label: `${t('Selected')}: ${value.length}`,
        value,
        title: t('Lead status', { ns: 'user' }),
      });
    },
    [changeFilters, t],
  );

  const changeSquareFrom = useCallback(
    (value: Nullable<number>) => {
      changeFilters({
        key: LeadsListFilterKeys.squareFrom,
        label: t('From {{squareFrom}} m²', { squareFrom: value }),
        value,
        title: t('Square'),
      });
    },
    [changeFilters, t],
  );

  const changeSquareTo = useCallback(
    (value: Nullable<number>) => {
      changeFilters({ key: LeadsListFilterKeys.squareTo, label: t('To {{squareTo}} m²', { squareTo: value }), value, title: t('Square') });
    },
    [changeFilters, t],
  );

  const changeLeadFirstContact = useCallback(
    (value: string) => {
      changeFilters({
        key: LeadsListFilterKeys.firstContact,
        label: t(value),
        value,
        title: t('First contact'),
      });
    },
    [changeFilters, t],
  );

  const changeContractApplicability = useCallback(
    (value: string, label: string) => {
      changeFilters({ key: LeadsListFilterKeys.isApplicable, label, value, title: t('Applicability') });
    },
    [changeFilters, t],
  );

  return (
    <TableFilter>
      <div className="space-y-2">
        <WarehouseSelect value={filters.selectedWarehouseId?.value} onChange={selectWarehouse} onClear={clearCurrentFilters} />
        <RangeDatePicker
          placeholder={[t('From'), t('To')]}
          label={t('Creation range')}
          onChangeFrom={changeCreationDateFrom}
          onChangeTo={changeCreationDateTo}
          values={[
            filters.creationDateFrom?.value && dayjs(filters.creationDateFrom?.value),
            filters.creationDateTo?.value && dayjs(filters.creationDateTo?.value),
          ]}
        />
        <RangeInput
          values={[filters.squareFrom?.value, filters.squareTo?.value]}
          placeholder={[t('From'), t('To')]}
          label={t('Square')}
          type="number"
          onChangeFrom={changeSquareFrom}
          onChangeTo={changeSquareTo}
        />
        <LeadStatusSelect value={filters.leadStatuses?.value} onChange={changeLeadStatus} onClear={clearCurrentFilters} />
        <LeadFirstContactSelect value={filters.firstContact?.value} onChange={changeLeadFirstContact} onClear={clearCurrentFilters} />
        <ApplicabilitySelect value={filters.isApplicable?.value} onChange={changeContractApplicability} onClear={clearCurrentFilters} />
      </div>
    </TableFilter>
  );
});
