import React, { FC, memo, useCallback } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { LeadsListFilterKeys } from 'pages/UsersPage/model/types';
import { LeadFirstContact } from 'entities/Lead';

import { Select } from 'shared/ui/Select';
import { DefaultOptionType } from 'antd/es/select';

interface LeadFirstContactSelectProps {
  value: string;
  onChange: (value: string, label: string) => void;
  onClear: (filterKey: string) => void;
}

export const LeadFirstContactSelect: FC<LeadFirstContactSelectProps> = memo((props) => {
  const { value, onChange, onClear } = props;

  const { t } = useAppTranslation(['contracts', 'common']);

  const firstContactOptions = Object.values(LeadFirstContact).map((option) => ({
    label: t(option, { ns: 'common' }),
    value: option,
  }));

  const handleChange = useCallback(
    (value: string, option?: DefaultOptionType | DefaultOptionType[]): void => {
      if (!Array.isArray(option) && typeof option?.label === 'string') {
        onChange(value, option.label);
      }
    },
    [onChange],
  );

  const clearFilter = useCallback(() => {
    onClear(LeadsListFilterKeys.firstContact);
  }, [onClear]);

  return (
    <Select
      className="w-full"
      label={t('First contact')}
      placeholder={t('All')}
      value={value}
      bordered
      allowClear
      options={firstContactOptions}
      onChange={handleChange}
      onClear={clearFilter}
    />
  );
});
